<script>
  import BaseFormField from './base-form-field.vue'
  import BaseFormFieldLabel from './base-form-field-label.vue'

  export default {
    components: {
      BaseFormField,
      BaseFormFieldLabel,
    },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-form-field>
    <template :slot="'label'">
      <base-form-field-label>
        <!-- @slot Slot container for containing label text. -->
        <slot name="label"></slot>
      </base-form-field-label>
    </template>
    <template :slot="'input'">
      <!-- @slot Slot container for containing content text. -->
      <slot name="text"></slot>
    </template>
  </base-form-field>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
</style>
