<script>
  import BaseContainerCollapsable from './base-container-collapsable.vue'

  export default {
    components: { BaseContainerCollapsable },
    props: {
      /** Toggled on to collapse and hide content of this container. */
      collapsed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-container-collapsable :collapsed="collapsed">
    <!-- @slot Slot container for content that will show when expanded. -->
    <slot />
  </base-container-collapsable>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
</style>
