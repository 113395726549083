<script>
  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="[$style['page-banner'], $style['page-banner--top-seperator']]">
    <div :class="$style['page-banner__content']">
      <!-- @slot Slot container for banner content. -->
      <slot />
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .page-banner {
    max-width: 100%;
    overflow: hidden;
    background-color: @color-defaults__banner;
    min-height: calc(9 ~'*' @spacing--4);
    text-align: center;
    position: relative;

    font-family: @font-family;
    font-size: @font-size__body--1;
    font-weight: @font-weight--regular;
    color: @color-defaults__text--body;

    @media only screen and (max-width: @max-width--small) {
      min-height: calc(5 ~'*' @spacing--4 ~'-' @spacing--5 ~'-' @spacing--5);
    }

    &--top-seperator {
      &:before {
        content: ' ';
        display: block;
        background-color: @color-defaults__border;
        height: 1px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
  }

  .page-banner__content {
    .grid-row__content();
  }
</style>
