const _generateActionChain = function() {
  const actionChain = {
    _successAction: undefined,
    _failureAction: undefined,
    _finalAction: undefined,
  }

  const _finallyFunction = function(always) {
    actionChain._finalAction = always
  }

  const _failureActionChain = {
    finally: _finallyFunction,
  }

  const _failureFunction = function(errorback) {
    actionChain._failureAction = errorback

    return _failureActionChain
  }

  const _thenActionChain = {
    failure: _failureFunction,
    finally: _finallyFunction,
  }

  const _thenFunction = function(callback) {
    actionChain._successAction = callback

    return _thenActionChain
  }

  actionChain.then = _thenFunction
  actionChain.failure = _failureFunction
  actionChain.finally = _finallyFunction

  return actionChain
}

export default {
  generateActionChain: _generateActionChain,
}
