import axios from 'axios'
import responseUtility from './_ResponseUtility.js'

const _postFile = function(file) {
  let successAction, failureAction, finalAction
  const actionChain = responseUtility.generateActionChain(successAction, failureAction, finalAction)

  var formData = new FormData()
  formData.append('file', file)

  axios({
    method: 'post',
    url: '/ws/public/files/v1/temporary',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
    .then((response) => {
      if (actionChain._successAction) {
        actionChain._successAction({
          parsed: response.data,
          raw: response.data,
          status: response.status,
        })
      }
    })
    .catch((error) => {
      if (actionChain._failureAction) {
        let parsed = error.response ? error.response.data : undefined

        if (parsed !== undefined) {
          try {
            parsed = JSON.parse(parsed)
          } catch (error) {
            console.error(error)
          }
        }

        actionChain._failureAction({
          parsed: parsed,
          raw: error.response ? error.response.data : undefined,
          status: error.response ? error.response.status : 500,
        })
      }
    })
    .finally(function() {
      if (actionChain._finalAction) {
        actionChain._finalAction()
      }
    })

  return actionChain
}

export default {
  postFile: _postFile,
}
