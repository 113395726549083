const _areFieldsValid = (fields) => {
  let isValid = true

  for (const key in fields) {
    const field = fields[key]
    if (!field.isValid) {
      isValid = false
      field.showError = true
    }
  }

  return isValid
}

const _areFieldsBusy = (fields) => {
  let isBusy = false

  for (const key in fields) {
    const field = fields[key]
    if (field.isBusy) {
      isBusy = true
    }
  }

  return isBusy
}

const _buildFieldValue = (value) => {
  var fieldValue = {
    inputValue: value || '',
    isValid: true,
    isBusy: false,
    error: '',
    showError: false,
  }
  return fieldValue
}

const _getValueFromField = (field) => {
  return field.inputValue
}

const _buildFieldMapFromValueMap = (valueMap) => {
  const fieldMap = {}

  for (const key in valueMap) {
    fieldMap[key] = _buildFieldValue(valueMap[key])
  }

  return fieldMap
}

const _buildValueMapFromFieldMap = (fieldMap) => {
  const valueMap = {}

  for (const key in fieldMap) {
    valueMap[key] = _getValueFromField(fieldMap[key])
  }

  return valueMap
}

export default {
  areFieldsValid: _areFieldsValid,
  areFieldsBusy: _areFieldsBusy,
  buildFieldValue: _buildFieldValue,
  getValueFromField: _getValueFromField,
  buildFieldMapFromValueMap: _buildFieldMapFromValueMap,
  buildValueMapFromFieldMap: _buildValueMapFromFieldMap,
}
