<script>
  import validators from './validators/validators.js'
  import BaseTextError from './base-text-error.vue'

  export default {
    components: {
      BaseTextError,
    },
    // Disable default inherit of attributes so we control where it gets bound
    inheritAttrs: false,
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** v-model value property. */
      value: {
        type: Object,
        default() {
          return {
            inputValue: '',
            isValid: true,
            error: '',
            showError: false,
          }
        },
      },
      /** Set to true if value entry is required. */
      required: {
        type: Boolean,
        default: false,
      },
      /** Name value for input group items. */
      name: {
        type: String,
        default: null,
      },
      /** Place holder text for when the input is void of a value. */
      placeholder: {
        type: String,
        default: 'Select option',
        validator(providedPlaceholder) {
          return typeof providedPlaceholder === 'string' && providedPlaceholder.length > 0
        },
      },
      /** Label for type of prefill that should happen on this field. */
      autocomplete: {
        type: String,
        default: 'none',
      },
      /** List of objects containing a value and a text property to define select options. */
      options: {
        type: Array,
        required: true,
        default: () => [],
        validator(providedOptions) {
          let valid = true
          if (Array.isArray(providedOptions)) {
            for (let i = 0; i < providedOptions.length; i++) {
              const option = providedOptions[i]
              const stringOption = typeof option === 'string'
              const objectOption = option && typeof option.value === 'string' && typeof option.text === 'string'
              valid = !valid ? false : stringOption || objectOption
            }
          } else {
            valid = false
          }
          return valid
        },
      },
    },
    data() {
      return {
        localInputValue: this.getValueForValue(this.value.inputValue),
        localShowError: !!this.value.showError,
        displayValue: this.getValueForValue(this.value.inputValue),
        defaultName: Math.floor(Math.random() * Date.now()) + '',
      }
    },
    computed: {
      nativeInputValue: {
        get() {
          return this.displayValue
        },
        set(value) {
          this.displayValue = this.getValueForValue(value)
          this.localInputValue = this.getValueForValue(value)
          this.emitUpdatedModel()
        },
      },
      hasBeenInteractedWith: {
        get() {
          return this.localShowError
        },
        set(value) {
          this.localShowError = value
          this.emitUpdatedModel()
        },
      },
      error() {
        return this.required ? validators.validateRequired(this.localInputValue) : ''
      },
      displayError() {
        return this.localShowError ? this.error : ''
      },
      nativeInputName() {
        return this.name ? this.name : this.defaultName
      },
      parsedOptions() {
        return this.options.map((option) => (typeof option === 'string' ? { value: option, text: option } : option))
      },
      placeholderSelected() {
        return this.nativeInputValue === '' && this.placeholder
      },
    },
    watch: {
      value: {
        deep: true,
        handler() {
          if (this.localInputValue !== this.value.inputValue) {
            this.nativeInputValue = this.value.inputValue
          }

          if (this.localShowError !== this.value.showError && this.value.showError !== undefined) {
            this.hasBeenInteractedWith = this.value.showError
          }
        },
      },
      error: {
        handler() {
          this.emitUpdatedModel()
        },
      },
    },
    mounted() {
      this.nativeInputValue = this.value.inputValue
    },
    methods: {
      emitUpdatedModel() {
        /**
         * Event to trigger model syncing.
         * @type {Event}
         */
        this.$emit('update', {
          inputValue: this.localInputValue,
          isValid: this.error.length === 0,
          error: this.error,
          showError: this.localShowError,
        })
      },
      getValueForValue(value) {
        const match = this.parsedOptions ? this.parsedOptions.find((option) => option.value === value) : undefined
        return match ? match.value : ''
      },
    },
  }
</script>

<template>
  <div :class="$style.select__wrapper">
    <select
      :class="[$style.select, { [$style['select--error']]: displayError }, { [$style['select--placeholder']]: placeholderSelected }]"
      :value="nativeInputValue"
      :autocomplete="autocomplete"
      :name="nativeInputName"
      v-bind="{
        ...$attrs, //Expand out attributes incase we want to override anything
      }"
      v-on="$listeners"
      @change="(e) => (nativeInputValue = e.target.value)"
      @blur="hasBeenInteractedWith = true"
    >
      <option v-if="placeholder" :value="''" :disabled="required">
        {{ placeholder }}
      </option>
      <option v-for="option in parsedOptions" :key="option.value" :value="option.value">
        {{ option.text }}
      </option>
    </select>

    <i :class="'acuity-icon acuity-icon-chevron-down ' + $style.select__arrow"></i>

    <base-text-error v-if="displayError">
      {{ displayError }}
    </base-text-error>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .select__wrapper {
    position: relative;
    padding: 0px;
    margin: 0px;
    width: 100%;
    box-sizing: border-box;
  }

  .select {
    .input-browser-overrides();

    width: 100%;
    box-sizing: border-box;
    height: @form-item__height;
    border: @default-border__width solid @color-defaults__border;
    border-radius: @default-border__radius;

    padding: 0px calc(@form-item__font--size ~'+' @spacing--3) 0px @spacing--3;
    margin: 0px;

    color: @color-defaults__text--body;
    background: @form-item__background-color;
    background-color: @form-item__background-color;

    font-size: @form-item__font--size;
    font-weight: @form-item__font--weight;
    font-family: @form-item__font--family;

    &:focus {
      border-color: @color-defaults__action;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__action, 40%);
    }

    option {
      color: @color-defaults__text--body;
      &:disabled {
        color: @color-defaults__text--placeholder;
      }
    }
  }

  .select--error {
    border-color: @color-defaults__error;
    &:focus {
      border-color: @color-defaults__error;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__error, 40%);
    }
  }

  .select--placeholder {
    color: @color-defaults__text--placeholder;
  }

  .select__arrow {
    position: absolute;
    pointer-events: none;
    right: calc(@spacing--3 ~'/' 2);
    top: calc(50% + 1px);
    transform: translate(0%, -50%);
    font-size: @form-item__font--size;
    height: @form-item__font--size;
    width: @form-item__font--size;
    line-height: @form-item__font--size;
    color: @color-defaults__action--icon;
  }
</style>
