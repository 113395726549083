<script>
  export default {
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="$style.field">
    <div :class="$style.field__label">
      <!-- @slot Slot container for containing a base-form-field-label component. -->
      <slot name="label"></slot>
    </div>
    <div :class="$style.field__input">
      <!-- @slot Slot container for containing an input-* component. -->
      <slot name="input"></slot>
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .field {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding-left: @spacing--2;
    padding-right: @spacing--2;
  }

  .field__label {
    padding-top: @spacing--4;
  }

  .field__input {
    padding-top: @spacing--1;
  }
</style>
