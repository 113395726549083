<script>
  import BaseButtonMenuAction from './base-button-menu-action.vue'

  /* TODO: Write unit tests */

  export default {
    components: {
      BaseButtonMenuAction,
    },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {
      emitClick: function(event) {
        /**
         * Passthrough click event.
         * @type {Event}
         */
        this.$emit('click')
      },
    },
  }
</script>

<template>
  <base-button-menu-action @click="emitClick">
    <!-- @slot Slot container for button text. -->
    <slot />
  </base-button-menu-action>
</template>

<style lang="less" module></style>
