<script>
  import BaseTextError from './base-text-error.vue'
  import BaseInputText from './base-input-text.vue'

  export default {
    components: {
      BaseTextError,
      BaseInputText,
    },
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** v-model value property. */
      value: {
        type: Object,
        default() {
          return {
            inputValue: '',
            isValid: true,
            error: '',
            showError: false,
          }
        },
      },
    },
    data() {
      return {
        displayError: '',
      }
    },
    computed: {},
    methods: {
      valueUpdated(newValue) {
        this.displayError = newValue.showError ? newValue.error : ''
      },
    },
  }
</script>

<template>
  <div>
    <base-input-text
      :class="[$style.input, { [$style['input--error']]: displayError }]"
      v-bind="{
        ...$attrs, // Expand out attributes incase we want to override anything
        value: value,
      }"
      v-on="$listeners"
      @update="valueUpdated"
    ></base-input-text>

    <base-text-error v-if="displayError">
      {{ displayError }}
    </base-text-error>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .input {
    width: 100%;
    box-sizing: border-box;
    height: @form-item__height;
    border: @default-border__width solid @color-defaults__border;
    border-radius: @default-border__radius;

    padding: 0px @spacing--3;
    margin: 0px;

    color: @color-defaults__text--body;
    background: @form-item__background-color;
    background-color: @form-item__background-color;

    font-size: @form-item__font--size;
    font-weight: @form-item__font--weight;
    font-family: @form-item__font--family;

    &:focus {
      border-color: @color-defaults__action;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__action, 40%);
    }
  }

  .input--error {
    border-color: @color-defaults__error;
    &:focus {
      border-color: @color-defaults__error;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__error, 40%);
    }
  }
</style>
