<script>
  import BaseInputSelect from './base-input-select.vue'

  export default {
    components: { BaseInputSelect },
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div>
    <base-input-select
      v-bind="{
        ...$attrs, //Expand out attributes incase we want to override anything
      }"
      v-on="$listeners"
    ></base-input-select>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
</style>
