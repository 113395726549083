<script>
  import validators from './validators/validators.js'
  import BaseInputTextbox from './base-input-textbox.vue'

  export default {
    components: { BaseInputTextbox },
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** Function that accepts model.inputValue and returns a string of length zero if valid, or an error message string if invalid. */
      validator: {
        type: Function,
        default: () => '',
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      validateName(value) {
        const error = validators.validateName(value)
        return error.length > 0 ? error : this.validator(value)
      },
    },
  }
</script>

<template>
  <div>
    <base-input-textbox
      v-bind="{
        ...$attrs, //Expand out attributes incase we want to override anything
        placeholder: 'First',
        type: 'text',
        inputmode: 'text',
        autocomplete: 'given-name',
        validator: validateName,
      }"
      v-on="$listeners"
    ></base-input-textbox>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
</style>
