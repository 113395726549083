<script>
  export default {
    components: {},
    props: {
      /** Displays the overlay. */
      visible: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div v-show="visible" :class="$style['loading-overlay']">
    <div :class="$style['loading-overlay__content']">
      <img :class="$style['loading-overlay__gif']" src="https://www.acuity.com/js/common/ui/templates/AcuityLoadHeart.gif" />
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .loading-overlay {
    background-color: rgba(
      @color-defaults__underlay--loading-rgb,
      @color-defaults__underlay--loading-rgb,
      @color-defaults__underlay--loading-rgb,
      @color-defaults__underlay--loading-a
    );

    z-index: @default-z-index__loading;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }

  .loading-overlay__content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 275px;
    height: 275px;
    padding: @spacing--4;
    box-sizing: border-box;
  }

  .loading-overlay__gif {
    max-width: 100%;
    max-height: 100%;
  }
</style>
