<script>
  import Vue from 'vue'
  import BaseModal from './base-modal.vue'
  import ButtonAction from './button-action.vue'
  import ButtonActionBack from './button-action-back.vue'

  export default {
    components: {
      BaseModal,
      ButtonAction,
      ButtonActionBack,
    },
    props: {
      /** Displays the modal. */
      visible: {
        required: false,
        type: Boolean,
        default: false,
      },
      /** Modal title value. */
      title: {
        required: false,
        type: String,
        default: null,
      },
      /** Primary action statement. */
      buttonText: {
        required: false,
        type: String,
        default: 'OK',
      },
      /** Displays a cancel button. */
      displayCancelButton: {
        required: false,
        type: Boolean,
        default: false,
      },
      /** Disabled close events from underlay and removes 'x' button. */
      mustSelectAction: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        modalHeight: 0,
        headerHeight: 0,
        footerHeight: 0,
      }
    },
    computed: {
      maxContentHeight: function() {
        let newMaxContentHeight = this.modalHeight - this.headerHeight - this.footerHeight
        newMaxContentHeight = newMaxContentHeight < 300 ? newMaxContentHeight + 'px !important;' : newMaxContentHeight + 'px;'
        return newMaxContentHeight
      },
      contentStyleComputed() {
        return {
          'max-height': this.newMaxContentHeight,
        }
      },
    },
    watch: {
      visible: function(visible) {
        if (visible) {
          window.addEventListener('scroll', this.updateHeightData, { capture: false, passive: true })
          window.addEventListener('resize', this.updateHeightData, { capture: false, passive: true })
          Vue.nextTick(this.updateHeightData, this)
        } else {
          window.removeEventListener('scroll', this.updateHeightData, { capture: false, passive: true })
          window.removeEventListener('resize', this.updateHeightData, { capture: false, passive: true })
        }
      },
    },
    destroy() {
      window.removeEventListener('scroll', this.updateHeightData)
      window.removeEventListener('resize', this.updateHeightData)
    },
    methods: {
      actionButtonClicked: function() {
        /**
         * Primary action click event.
         * @type {Event}
         */
        this.$emit('buttonClick')
        this.emitClose()
      },
      cancelButtonClicked: function() {
        /**
         * Cancel action click event.
         * @type {Event}
         */
        this.$emit('cancelButtonClick')
        this.emitClose()
      },
      emitClose: function() {
        /**
         * Close attempt event.
         * @type {Event}
         */
        this.$emit('close')
      },
      updateHeightData: function() {
        if (this.visible) {
          this.modalHeight = window.innerHeight - 75
          this.headerHeight = this.$refs.modalHeader ? parseInt(this.$refs.modalHeader.getBoundingClientRect().height) : 0
          this.footerHeight = this.$refs.modalFooter ? parseInt(this.$refs.modalFooter.getBoundingClientRect().height) : 0
        }
      },
    },
  }
</script>

<template>
  <base-modal
    v-bind="{
      visible: visible,
      disableSystemClose: mustSelectAction,
    }"
    @close="emitClose"
  >
    <div ref="modal" :class="$style['modal']">
      <div v-if="title" ref="modalHeader" :class="$style['modal__title']">
        {{ title }}
      </div>
      <div :class="$style['modal__content']" :style="contentStyleComputed">
        <!-- @slot Main content container. -->
        <slot name="content" />
      </div>
      <div ref="modalFooter" :class="$style['modal__footer']">
        <div :class="$style['modal__grid-row']">
          <div :class="[$style['modal__footer--action'], { [$style['modal__footer--action--full-width']]: !displayCancelButton }]">
            <button-action @click="actionButtonClicked">{{ buttonText }}</button-action>
          </div>
          <div v-if="displayCancelButton" :class="$style['modal__footer--negative-action']">
            <button-action-back @click="cancelButtonClicked">Cancel</button-action-back>
          </div>
        </div>
        <div :class="$style['modal__grid-row']">
          <div :class="$style['modal__footer--additional-actions']">
            <!-- @slot Slot container for filling with button-action-secondary components. -->
            <slot name="additionalActions" />
          </div>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .modal {
    @media only screen and (min-width: @min-width--medium) {
      min-width: 350px;
      max-width: 850px;
    }
  }

  .modal__grid-row {
    .grid-row();
  }

  .modal__title {
    text-align: center;
    font-weight: @font-weight--bold;
    font-style: bold;
    font-size: @font-size__heading--3;
    @media only screen and (max-width: @max-width--small) {
      font-size: @font-size__heading--4;
    }

    padding-top: @spacing--2;
    padding-bottom: @spacing--4;
    @media only screen and (max-width: @max-width--small) {
      padding-bottom: @spacing--2;
    }
  }

  .modal__content {
    text-align: center;
    font-weight: @font-weight--regular;
    font-size: @font-size__body;

    overflow-y: auto;
    overflow-x: hidden;

    @media only screen and (min-width: @min-width--medium) {
      max-height: 300px !important;
    }
  }

  .modal__footer {
    .grid-row();

    text-align: center;
  }

  .modal__footer--negative-action {
    .grid-row__column--6();
    .grid-row__pull--6();

    padding-left: @spacing--2;
    padding-right: @spacing--2;
    text-align: right;

    @media only screen and (min-width: @min-width--medium) {
      padding-top: @spacing--4;
      padding-right: calc(@spacing--2 ~'/' 2);
    }

    @media only screen and (max-width: @max-width--small) {
      padding-top: @spacing--2;
      button {
        width: 100%;
      }
    }
  }

  .modal__footer--action {
    .grid-row__column--6();
    .grid-row__push--6();

    padding-top: @spacing--4;
    padding-right: @spacing--2;
    padding-left: @spacing--2;
    @media only screen and (min-width: @min-width--medium) {
      padding-left: calc(@spacing--2 ~'/' 2);
    }

    text-align: left;

    @media only screen and (max-width: @max-width--small) {
      button {
        width: 100%;
      }
    }

    &--full-width {
      .grid-row__column--12();
      .grid-row__push--0();

      text-align: center;

      @media only screen and (min-width: @min-width--medium) {
        padding-right: 0px;
        padding-left: 0px;
      }
    }
  }

  .modal__footer--additional-actions {
    text-align: center;

    button {
      margin: @spacing--2 auto 0px auto !important;
      display: block;
    }
  }
</style>
