<script>
  import ButtonLink from './button-link.vue'

  export default {
    components: {
      ButtonLink,
    },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {
      emitClick: function(event) {
        /**
         * Passthrough click event.
         * @type {Event}
         */
        this.$emit('click')
      },
    },
  }
</script>

<template>
  <button-link @click="emitClick">
    <!-- @slot Slot container for button text. -->
    <slot />
  </button-link>
</template>

<style lang="less" module></style>
