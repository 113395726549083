<script>
  import validators from './validators/validators.js'
  import BaseTextError from './base-text-error.vue'

  export default {
    components: {
      BaseTextError,
    },
    // Disable default inherit of attributes so we control where it gets bound
    inheritAttrs: false,
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** v-model value property. */
      value: {
        type: Object,
        default() {
          return {
            inputValue: '',
            isValid: true,
            error: '',
            showError: false,
          }
        },
      },
      /** Set to true if value entry is required. */
      required: {
        type: Boolean,
        default: false,
      },
      /** Name value for input group items. */
      name: {
        type: String,
        default: null,
      },
      /** List of objects containing a value and a text property to define select options. */
      options: {
        type: Array,
        required: true,
        default: () => [],
        validator(providedOptions) {
          let valid = true
          if (Array.isArray(providedOptions)) {
            for (let i = 0; i < providedOptions.length; i++) {
              const option = providedOptions[i]
              const stringOption = typeof option === 'string'
              const objectOption = option && typeof option.value === 'string' && typeof option.text === 'string'
              valid = !valid ? false : stringOption || objectOption
            }
          } else {
            valid = false
          }
          return valid
        },
      },
    },
    data() {
      return {
        localInputValue: '',
        localShowError: !!this.value.showError,
        displayValue: '',
        defaultName: Math.floor(Math.random() * Date.now()) + '',
        nativeInputFocused: false,
      }
    },
    computed: {
      nativeInputValue: {
        get() {
          return this.displayValue
        },
        set(value) {
          this.displayValue = this.getValueForValue(value)
          this.localInputValue = this.getValueForValue(value)
          this.emitUpdatedModel()
        },
      },
      hasBeenInteractedWith: {
        get() {
          return this.localShowError
        },
        set(value) {
          this.localShowError = value
          this.emitUpdatedModel()
        },
      },
      error() {
        return this.required ? validators.validateRequired(this.localInputValue) : ''
      },
      displayError() {
        return this.localShowError ? this.error : ''
      },
      nativeInputName() {
        return this.name ? this.name : this.defaultName
      },
      parsedOptions() {
        return this.options.map((option) => (typeof option === 'string' ? { value: option, text: option } : option))
      },
      isFocused() {
        return this.nativeInputFocused
      },
    },
    watch: {
      value: {
        deep: true,
        handler() {
          if (this.localInputValue !== this.value.inputValue) {
            this.nativeInputValue = this.value.inputValue
          }

          if (this.localShowError !== this.value.showError && this.value.showError !== undefined) {
            this.hasBeenInteractedWith = this.value.showError
          }
        },
      },
      error: {
        handler() {
          this.emitUpdatedModel()
        },
      },
    },
    mounted() {
      this.nativeInputValue = this.value.inputValue
    },
    methods: {
      emitUpdatedModel() {
        /**
         * Event to trigger model syncing.
         * @type {Event}
         */
        this.$emit('update', {
          inputValue: this.localInputValue,
          isValid: this.error.length === 0,
          error: this.error,
          showError: this.localShowError,
        })
      },
      getValueForValue(value) {
        const match = this.parsedOptions ? this.parsedOptions.find((option) => option.value === value) : undefined
        return match ? match.value : ''
      },
      focused() {
        this.nativeInputFocused = true
      },
      blurred() {
        this.nativeInputFocused = false
        setTimeout(
          function() {
            if (!this.nativeInputFocused) {
              this.hasBeenInteractedWith = true
            }
          }.bind(this),
          0
        )
      },
      revertFocusToLabel(value) {
        document.getElementById(this.nativeInputName + '__' + value + '__ac__segmented__control__label').focus()
      },
    },
  }
</script>

<template>
  <div>
    <div :class="[$style.control, { [$style['control--focus']]: isFocused }, { [$style['control--error']]: displayError }]">
      <div v-for="option in parsedOptions" :key="option.value" :class="$style.control__segment">
        <input
          :id="nativeInputName + '__' + option.value + '__ac__segmented__control'"
          v-model="nativeInputValue"
          :value="option.value"
          :type="'radio'"
          :name="nativeInputName"
          :tabindex="-1"
          :class="$style.control__segment__input"
          v-bind="{
            ...$attrs, //Expand out attributes incase we want to override anything
          }"
          v-on="$listeners"
          @focus="revertFocusToLabel(option.value)"
        />
        <label
          :id="nativeInputName + '__' + option.value + '__ac__segmented__control__label'"
          :for="nativeInputName + '__' + option.value + '__ac__segmented__control'"
          :tabindex="0"
          :class="$style.control__radio__label"
          @keydown.space.prevent="focused"
          @keydown.enter.prevent="focused"
          @keyup.space.prevent="nativeInputValue = option.value"
          @keyup.enter.prevent="nativeInputValue = option.value"
          @blur="blurred"
          @focus="focused"
        >
          <span :class="$style.control__segment__label" :tabindex="-1">{{ option.text }}</span>
        </label>
      </div>
    </div>

    <base-text-error v-if="displayError">
      {{ displayError }}
    </base-text-error>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .control {
    .flex();

    width: 100%;
    box-sizing: border-box;
    height: @form-item__height;
    border: @default-border__width solid @color-defaults__border;
    border-radius: @default-border__radius;
    overflow: hidden;

    padding: 0px;
    margin: 0px;
  }

  .control--focus {
    border-color: @color-defaults__action;
    box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__action, 40%);
  }

  .control--error {
    border-color: @color-defaults__error;
    .control--focus {
      border-color: @color-defaults__error;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__error, 40%);
    }
  }

  .control__segment {
    width: 100%;
    flex-grow: 1;
    position: relative;

    border-left: calc(@default-border__width / 2) solid @color-defaults__border;
    border-right: calc(@default-border__width / 2) solid @color-defaults__border;
    &:first-of-type {
      border-left: none;
    }
    &:last-of-type {
      border-right: none;
    }
  }

  .control__segment__input {
    .input-browser-overrides();

    position: absolute;
    top: 0px;
    left: -9999px;

    + label {
      .prevent-highlighting();
      position: absolute;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: @form-item__background-color;
      border-radius: 0px;
      border: 0px solid transparent;

      font-size: @form-item__font--size;
      font-weight: @form-item__font--weight;
      font-family: @form-item__font--family;
      color: @color-defaults__action;
    }

    &:not(:checked) {
      + label {
        &:focus {
          background-color: darken(@form-item__background-color, @color-alteration-step-size--2);
        }
      }
    }

    &:checked {
      + label {
        background-color: @color-defaults__selected;
        color: @color-defaults__text--light;
      }
    }

    &:hover {
      + label {
        cursor: pointer;
      }

      &:not(:checked) {
        + label {
          background-color: darken(@form-item__background-color, @color-alteration-step-size--2);
        }
      }
    }

    &:active {
      + label {
        cursor: pointer;
      }

      &:not(:checked) {
        + label {
          background-color: darken(@form-item__background-color, @color-alteration-step-size--2 + @color-alteration-step-size--2);
        }
      }
    }
  }

  .control__segment__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
  }
</style>
