<script>
  import validators from './validators/validators.js'
  import BaseTextError from './base-text-error.vue'

  export default {
    components: {
      BaseTextError,
    },
    // Disable default inherit of attributes so we control where it gets bound
    inheritAttrs: false,
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** v-model value property. */
      value: {
        type: Object,
        default() {
          return {
            inputValue: '',
            isValid: true,
            error: '',
            showError: false,
          }
        },
      },
      /** Set to true if value entry is required. */
      required: {
        type: Boolean,
        default: false,
      },
      /** Name value for input group items. */
      name: {
        type: String,
        default: null,
      },
      /** Place holder text for when the input is void of a value. */
      placeholder: {
        type: String,
        default: '',
      },
      /** Max number of characters this input will accept. */
      maxLength: {
        type: Number,
        default: 2000,
      },
      /** Function that accepts model.inputValue and returns a string of length zero if valid, or an error message string if invalid. */
      validator: {
        type: Function,
        default: () => '',
      },
    },
    data() {
      return {
        localInputValue: this.sanitizeValue(this.value.inputValue),
        localShowError: !!this.value.showError,
        displayValue: this.sanitizeValue(this.value.inputValue),
        defaultName: Math.floor(Math.random() * Date.now()) + '',
      }
    },
    computed: {
      nativeInputValue: {
        get() {
          return this.displayValue
        },
        set(value) {
          this.displayValue = this.stripInvalidChars(value)
          this.localInputValue = this.sanitizeValue(value)
          this.emitUpdatedModel()
        },
      },
      hasBeenInteractedWith: {
        get() {
          return this.localShowError
        },
        set(value) {
          this.localShowError = value
          this.emitUpdatedModel()
        },
      },
      error() {
        const error = this.required ? validators.validateRequired(this.localInputValue) : ''
        return error.length > 0 ? error : this.validator(this.localInputValue)
      },
      displayError() {
        return this.localShowError ? this.error : ''
      },
      nativeInputName() {
        return this.name ? this.name : this.defaultName
      },
    },
    watch: {
      value: {
        deep: true,
        handler() {
          if (this.localInputValue !== this.value.inputValue) {
            this.nativeInputValue = this.value.inputValue
          }

          if (this.localShowError !== this.value.showError && this.value.showError !== undefined) {
            this.hasBeenInteractedWith = this.value.showError
          }
        },
      },
      error: {
        handler() {
          this.emitUpdatedModel()
        },
      },
    },
    mounted() {
      this.emitUpdatedModel()
    },
    methods: {
      emitUpdatedModel() {
        /**
         * Event to trigger model syncing.
         * @type {Event}
         */
        this.$emit('update', {
          inputValue: this.localInputValue,
          isValid: this.error.length === 0,
          error: this.error,
          showError: this.localShowError,
        })
      },
      stripInvalidChars(value) {
        if (value.length > this.maxLength) {
          value = value.substring(0, this.maxLength)
        }

        return value
      },
      sanitizeValue(value) {
        value = typeof value === 'string' ? value.trim() : ''
        return this.stripInvalidChars(value)
      },
    },
  }
</script>

<template>
  <div>
    <textarea
      :type="'text'"
      :class="[$style.textarea, { [$style['textarea--error']]: displayError }]"
      :value="nativeInputValue"
      :placeholder="placeholder"
      :autocomplete="'none'"
      :inputmode="'text'"
      :name="nativeInputName"
      v-bind="{
        ...$attrs, //Expand out attributes incase we want to override anything
      }"
      v-on="$listeners"
      @input="(e) => (nativeInputValue = e.target.value)"
      @blur="hasBeenInteractedWith = true"
    />

    <base-text-error v-if="displayError">
      {{ displayError }}
    </base-text-error>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .textarea {
    .input-browser-overrides();

    width: 100%;
    box-sizing: border-box;
    height: calc(@form-item__height ~'*' 4);
    border: @default-border__width solid @color-defaults__border;
    border-radius: @default-border__radius;

    padding: @spacing--2 @spacing--3;
    margin: 0px;

    color: @color-defaults__text--body;
    background: @form-item__background-color;
    background-color: @form-item__background-color;

    font-size: @form-item__font--size;
    font-weight: @form-item__font--weight;
    font-family: @form-item__font--family;

    &:focus {
      border-color: @color-defaults__action;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__action, 40%);
    }
  }

  .textarea--error {
    border-color: @color-defaults__error;
    &:focus {
      border-color: @color-defaults__error;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__error, 40%);
    }
  }
</style>
