<script>
  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="$style.tile">
    <!-- @slot Slot container for tile contents. -->
    <slot />
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .tile {
    display: block;
    box-sizing: border-box;
    background-color: @color-defaults__section--2;
    border-radius: @default-border__radius;

    @media only screen and (min-width: @min-width--medium) {
      width: 250px;
      height: 250px;
      max-width: 250px;
      max-height: 250px;
    }

    @media only screen and (max-width: @max-width--small) {
      width: inherit;
      height: inherit;
      max-width: inherit;
      max-height: inherit;
    }
  }
</style>
