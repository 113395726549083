<script>
  export default {
    components: {},
    props: {
      /** Font color (0-4). */
      color: {
        required: false,
        type: Number,
        default: 0,
      },
    },
    data() {
      return {}
    },
    computed: {
      buttonClassObject: function() {
        return {
          [this.$style['link-button']]: true,
          [this.$style['link-button--action']]: this.color === 0,
          [this.$style['link-button--text']]: this.color === 1,
          [this.$style['link-button--heading']]: this.color === 2,
          [this.$style['link-button--subheading']]: this.color === 3,
          [this.$style['link-button--error']]: this.color === 4,
        }
      },
    },
    methods: {
      emitClick: function(event) {
        /**
         * Passthrough click event.
         * @type {Event}
         */
        this.$emit('click')
      },
    },
  }
</script>

<template>
  <button type="button" :class="buttonClassObject" @click="emitClick">
    <!-- @slot Slot for containing link text. -->
    <slot />
  </button>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .link-button {
    .prevent-highlighting();
    display: inline-block;
    text-decoration: none;
    padding: 0px;
    margin: 0px;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;

    color: @color-defaults__text--link;
    &:hover {
      color: lighten(@color-defaults__text--link, @color-alteration-step-size--2);
      text-decoration: underline;
      cursor: pointer;
    }

    &:focus {
      color: lighten(@color-defaults__text--link, @color-alteration-step-size--2);
      text-decoration: underline;
      cursor: pointer;
    }

    &:active {
      color: darken(@color-defaults__text--link, @color-alteration-step-size--2);
      text-decoration: underline;
    }

    &--text {
      color: @color-defaults__text--body;
      &:hover {
        color: lighten(@color-defaults__text--body, @color-alteration-step-size--2);
      }

      &:focus {
        color: lighten(@color-defaults__text--body, @color-alteration-step-size--2);
      }

      &:active {
        color: darken(@color-defaults__text--body, @color-alteration-step-size--2);
      }
    }

    &--heading {
      color: @color-defaults__text--heading;
      &:hover {
        color: lighten(@color-defaults__text--heading, @color-alteration-step-size--2);
      }

      &:focus {
        color: lighten(@color-defaults__text--heading, @color-alteration-step-size--2);
      }

      &:active {
        color: darken(@color-defaults__text--heading, @color-alteration-step-size--2);
      }
    }

    &--subheading {
      color: @color-defaults__text--subheading;
      &:hover {
        color: lighten(@color-defaults__text--subheading, @color-alteration-step-size--2);
      }

      &:focus {
        color: lighten(@color-defaults__text--subheading, @color-alteration-step-size--2);
      }

      &:active {
        color: darken(@color-defaults__text--subheading, @color-alteration-step-size--2);
      }
    }

    &--error {
      color: @color-defaults__text--error;
      &:hover {
        color: lighten(@color-defaults__text--error, @color-alteration-step-size--2);
      }

      &:focus {
        color: lighten(@color-defaults__text--error, @color-alteration-step-size--2);
      }

      &:active {
        color: darken(@color-defaults__text--error, @color-alteration-step-size--2);
      }
    }
  }
</style>
