<script>
  export default {
    components: {},
    props: {
      /** Hex code for the company's primary brand color. */
      color: {
        required: true,
        type: String,
        default: null,
      },
      /** Company URL. */
      href: {
        required: true,
        type: String,
        default: null,
      },
    },
    data() {
      return {}
    },
    computed: {
      cssColorProperties: function() {
        return {
          '--primary-brand-color-hovered': '#' + this.alterHexColor(this.color, 10),
          '--primary-brand-color-active': '#' + this.alterHexColor(this.color, -10),
        }
      },
    },
    methods: {
      alterHexColor: function(color, percent) {
        const num = parseInt(color, 16)
        const amt = Math.round(2.55 * percent)
        const R = (num >> 16) + amt
        const B = ((num >> 8) & 0x00ff) + amt
        const G = (num & 0x0000ff) + amt

        return (0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 + (G < 255 ? (G < 1 ? 0 : G) : 255))
          .toString(16)
          .slice(1)
      },
    },
  }
</script>

<template>
  <a :href="href" target="_blank">
    <button type="button" :class="$style['icon-button']" :style="cssColorProperties" tabindex="-1">
      <!-- @slot Slot container for action text. -->
      <slot />
    </button>
  </a>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .icon-button {
    .prevent-highlighting();
    display: inline-block;
    text-decoration: none;
    padding: 0px;
    margin: 0px;
    border: none;
    background-color: transparent;
    outline: none;
    font-size: @font-size__body--1;
    font-style: bold;
    font-weight: @font-weight--bold;

    color: @color-defaults__action--icon;
    &:hover {
      color: var(--primary-brand-color-hovered);
      cursor: pointer;
    }

    &:focus {
      color: var(--primary-brand-color-hovered);
      cursor: pointer;
    }

    &:active {
      color: var(--primary-brand-color-active);
    }
  }
</style>
