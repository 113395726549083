<script>
  export default {
    components: {},
    props: {
      /** Form heading title text. */
      title: {
        required: true,
        type: String,
        default: null,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="$style['form-heading']">
    <div :class="$style['form-heading__content']">
      <div :class="$style['form-heading__title']">
        {{ title }}
      </div>
      <div :class="$style['form-heading__description']">
        <!-- @slot Slot container for contain description text about the following form. -->
        <slot />
      </div>
      <hr :class="$style['form-heading__separator']" />
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .form-heading {
    .grid-row();
  }

  .form-heading__content {
    padding-left: @spacing--2;
    padding-right: @spacing--2;
    padding-top: @spacing--4;
    .grid-row__column--10();
  }

  .form-heading__title {
    color: @color-defaults__text--heading;
    font-size: @font-size__heading--3;
    font-weight: @font-weight--bold;
    font-style: bold;

    padding-bottom: @spacing--1;
  }

  .form-heading__description {
    color: @color-defaults__text--body;
    font-size: @font-size__body--2;
    font-weight: @font-weight--regular;
  }

  .form-heading__separator {
    border: none;
    background: @color-defaults__border;
    height: 2px;
    margin: @spacing--2 0px 0px 0px;
  }
</style>
