<script>
  import BaseButtonAction from './base-button-action.vue'

  export default {
    components: {
      BaseButtonAction,
    },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {
      emitClick: function(event) {
        /**
         * Passthrough click event.
         * @type {Event}
         */
        this.$emit('click')
      },
    },
  }
</script>

<template>
  <base-button-action
    v-bind="{
      background: 2,
    }"
    @click="emitClick"
  >
    <!-- @slot Slot container for button text. -->
    <slot />
  </base-button-action>
</template>

<style lang="less" module></style>
