<script>
  import BasePageSection from './base-page-section.vue'

  export default {
    components: {
      BasePageSection,
    },
    props: {
      /** Background color indicator. 0 (primary). 1 (secondary). */
      background: {
        required: false,
        type: Number,
        default: 0,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-page-section
    v-bind="{
      background: background,
    }"
  >
    <div :class="$style['page-section']">
      <div :class="$style['page-section__content']">
        <!-- @slot Slot container section content. -->
        <slot />
      </div>
    </div>
  </base-page-section>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .page-section {
    .grid-row__column--12();
  }

  .page-section__content {
    padding-top: @spacing--1;
    padding-bottom: calc(1.5 ~'*' @spacing--4);

    @media only screen and (min-width: @min-width--medium) {
      padding-top: @spacing--4;
      padding-bottom: @spacing--4;
    }
  }
</style>
