import Vue from 'vue'
import * as components from './components'
import * as validators from './components/validators/validators.js'
import * as formUtils from './components/utils/formUtils.js'
import * as stateUtils from './components/utils/stateUtils.js'

const install = (Vue, options = {}) => {
  for (const key in components) {
    const _key = options.prefix ? options.prefix + key : key
    Vue.component(_key, components[key])
  }
}

install(Vue)

const ACValidators = validators.default

Vue.prototype.$ACValidators = ACValidators

const ACFormUtils = formUtils.default

Vue.prototype.$ACFormUtils = ACFormUtils

const ACStateUtils = stateUtils.default

Vue.prototype.$ACStateUtils = ACStateUtils

export { install, ACStateUtils, ACFormUtils, ACValidators }
