<script>
  import BaseInputSelect from './base-input-select.vue'
  import stateUtils from './utils/stateUtils.js'

  export default {
    components: { BaseInputSelect },
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** Flag that will hide states where Acuity does not do business. */
      hideStatesWithoutCoverage: {
        type: Boolean,
        default: false,
      },
      /** Flag that will hide states where Acuity does not do personal business. */
      hideStatesWithoutPersonalCoverage: {
        type: Boolean,
        default: false,
      },
      /** Flag that will hide states where Acuity does not do commercial business. */
      hideStatesWithoutCommercialCoverage: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {
      stateOptions() {
        if (this.hideStatesWithoutCoverage) {
          return stateUtils.states
            .filter((state) => state.commercial || state.personal)
            .map(function(state) {
              return { value: state.code, text: state.name }
            })
        } else if (this.hideStatesWithoutPersonalCoverage) {
          return stateUtils.states
            .filter((state) => state.personal)
            .map(function(state) {
              return { value: state.code, text: state.name }
            })
        } else if (this.hideStatesWithoutCommercialCoverage) {
          return stateUtils.states
            .filter((state) => state.commercial)
            .map(function(state) {
              return { value: state.code, text: state.name }
            })
        } else {
          return stateUtils.states.map(function(state) {
            return { value: state.code, text: state.name }
          })
        }
      },
    },
    methods: {},
  }
</script>

<template>
  <div>
    <base-input-select
      v-bind="{
        ...$attrs, //Expand out attributes incase we want to override anything
        placeholder: 'Select state',
        autocomplete: 'address-level1',
        options: stateOptions,
      }"
      v-on="$listeners"
    ></base-input-select>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
</style>
