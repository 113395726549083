<script>
  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="$style.notification">
    <!-- @slot Slot container for notification contents. -->
    <slot />
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .notification {
    display: block;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    margin: @spacing--3 auto 0px auto;
    padding: @spacing--2;
    max-width: calc(100% - @spacing--2 - @spacing--2);
    width: 500px;

    border-radius: @default-border__radius;
    box-shadow: 0 3px 6px darken(@color-defaults__border--dark, 20%);
    background-color: @color-defaults__section--2;
  }
</style>
