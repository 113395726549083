var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5, _obj$6;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
    _vm.$style.button,
    ( _obj = {}, _obj[_vm.$style['button--conversion']] = _vm.background === 1, _obj ),
    ( _obj$1 = {}, _obj$1[_vm.$style['button--back']] = _vm.background === 2, _obj$1 ),
    ( _obj$2 = {}, _obj$2[_vm.$style['button--busy']] = _vm.busy === true, _obj$2 ) ],attrs:{"type":"button"},on:{"click":_vm.emitClick}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.busy),expression:"!busy"}]},[_vm._t("default")],2),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.busy),expression:"busy"}]},[_c('div',{class:_vm.$style.button__status__container},[_c('div',{class:[
          _vm.$style.button__status__spinner,
          ( _obj$3 = {}, _obj$3[_vm.$style['button__status__spinner--conversion']] = _vm.background === 1, _obj$3 ),
          ( _obj$4 = {}, _obj$4[_vm.$style['button__status__spinner--back']] = _vm.background === 2, _obj$4 ) ]}),_c('div',{class:[
          _vm.$style.button__status__ring,
          ( _obj$5 = {}, _obj$5[_vm.$style['button__status__ring--conversion']] = _vm.background === 1, _obj$5 ),
          ( _obj$6 = {}, _obj$6[_vm.$style['button__status__ring--back']] = _vm.background === 2, _obj$6 ) ]})])])])}
var staticRenderFns = []

export { render, staticRenderFns }