<script>
  import BasePageBanner from './base-page-banner.vue'

  export default {
    components: {
      BasePageBanner,
    },
    props: {
      /** Title of the webpage. */
      title: {
        required: true,
        type: String,
        default: '',
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-page-banner>
    <div :class="$style['page-banner']">
      <div v-if="title">
        <div :class="$style['page-banner__title']">
          {{ title }}
        </div>
      </div>

      <div :class="$style['page-banner__contents']">
        <!-- @slot Slot container desctiption text regarding the webpage. -->
        <slot />
      </div>
    </div>
  </base-page-banner>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .page-banner {
    padding-top: @spacing--5;
    padding-bottom: @spacing--5;
    padding-left: @spacing--2;
    padding-right: @spacing--2;

    @media only screen and (max-width: @max-width--small) {
      padding-top: @spacing--3;
      padding-bottom: @spacing--3;
    }
  }

  .page-banner__title {
    font-size: @font-size__heading--1;
    font-weight: @font-weight--bold;
    font-style: bold;
    color: @color-defaults__text--heading;
    padding-bottom: @spacing--4;

    @media only screen and (max-width: @max-width--small) {
      font-size: @font-size__heading--2;
    }
  }

  .page-banner__contents {
    @media only screen and (min-width: @min-width--medium) {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
</style>
