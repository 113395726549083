<script>
  import BaseContainerMenuActions from './base-container-menu-actions.vue'
  import ButtonIcon from './button-icon.vue'

  /* TODO: Write unit tests */

  export default {
    components: { BaseContainerMenuActions, ButtonIcon },
    props: {},
    data() {
      return {
        open: false,
        rootNodeBottom: 0,
        rootNodeRight: 0,
        bodyTop: 0,
        bodyRight: 0,
      }
    },
    computed: {
      menuComputedStyleProperties() {
        var newRight = +this.bodyRight - +this.rootNodeRight
        return {
          right: +newRight + 'px',
          top: +this.rootNodeBottom + 'px',
        }
      },
    },
    watch: {
      open(newValue) {
        // Watch or stop watching scrolling position and document clicks.
        if (newValue === true) {
          window.addEventListener('scroll', this.updateFloatPosition, { capture: false, passive: true })
          window.addEventListener('resize', this.updateFloatPosition, { capture: false, passive: true })
          this.updateFloatPosition()
        } else {
          window.removeEventListener('scroll', this.updateFloatPosition, { capture: false, passive: true })
          window.removeEventListener('resize', this.updateFloatPosition, { capture: false, passive: true })
        }
      },
    },
    destroyed() {
      window.removeEventListener('scroll', this.updateFloatPosition, { capture: false, passive: true })
      window.removeEventListener('resize', this.updateFloatPosition, { capture: false, passive: true })
    },
    methods: {
      toggleOpenState() {
        this.open = !this.open
      },
      updateFloatPosition() {
        var rootNode = this.$refs.rootNode
        var rootNodeRect = rootNode.getBoundingClientRect()
        var bodyRect = document.getElementsByTagName('HTML')[0].getBoundingClientRect()

        this.rootNodeBottom = parseInt(rootNodeRect.bottom) + 5
        this.rootNodeRight = parseInt(rootNodeRect.right)
        this.bodyTop = parseInt(bodyRect.top)
        this.bodyRight = parseInt(bodyRect.right)
      },
      captureClick() {
        this.open = false
      },
      captureHover() {},
    },
  }
</script>

<template>
  <div ref="rootNode" :class="$style['menu-button']">
    <button-icon @click="toggleOpenState">
      <i class="acuity-icon acuity-icon-quick-actions-menu"></i>
    </button-icon>

    <div v-if="open" :class="$style['menu-underlay']" @click.capture="captureClick" @hover.capture="captureHover"> </div>

    <div v-show="open" :class="$style['menu-container']" :style="menuComputedStyleProperties" @click.capture="captureClick">
      <base-container-menu-actions>
        <!-- @slot Slot container for holding button-menu-action components. -->
        <slot />
      </base-container-menu-actions>
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .menu-button {
    display: inline-block;
    position: relative;
  }

  .menu-container {
    display: block;
    position: fixed;
    top: 0px;
    right: 0px;
    z-index: @default-z-index__menu;
  }

  .menu-underlay {
    display: block;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: @default-z-index__menu;

    width: 100%;
    height: 100%;

    background-color: transparent;
    background: transparent;

    cursor: default;
  }
</style>
