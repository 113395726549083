<script>
  import BaseContainerCollapsable from './base-container-collapsable.vue'
  import ButtonLink from './button-link.vue'
  import ButtonIcon from './button-icon.vue'

  export default {
    components: {
      BaseContainerCollapsable,
      ButtonLink,
      ButtonIcon,
    },
    props: {
      /** Font color (0-3). */
      color: {
        required: false,
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        expanded: false,
      }
    },
    computed: {
      contentExpanded: function() {
        return !this.expanded
      },
      iconButtonColor: function() {
        return this.color === 0 ? 3 : this.color > 3 ? 3 : this.color
      },
    },
    methods: {
      titleButtonClicked: function() {
        this.expanded = !this.expanded
      },
      indicatorButtonClicked: function() {
        this.expanded = !this.expanded
      },
    },
  }
</script>

<template>
  <div>
    <div :class="$style['expandable__title-container']">
      <button-link
        :class="$style.expandable__title"
        v-bind="{
          color: color,
        }"
        @click="titleButtonClicked"
      >
        <!-- @slot Slot container for content that will show when not yet expanded. -->
        <slot name="title" />
      </button-link>
      <button-icon
        :class="$style['expandable__indicator-container']"
        v-bind="{
          color: iconButtonColor,
        }"
        @click="indicatorButtonClicked"
      >
        <span :class="$style.expandable__indicator">
          <i :class="['acuity-icon acuity-icon-minus', { [$style.rotate]: expanded }]"></i>
          <i :class="['acuity-icon acuity-icon-minus', { [$style.rotate]: expanded }]"></i>
        </span>
      </button-icon>
    </div>
    <base-container-collapsable
      v-bind="{
        collapsed: contentExpanded,
      }"
    >
      <div :class="$style.expandable__content">
        <!-- @slot Slot container for content that will show when expanded. -->
        <slot name="content" />
      </div>
    </base-container-collapsable>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  @indicator__size: 25px;

  .expandable__title-container {
    .grid-row();
    .grid-row--vertical-align-center();
    position: relative;
    min-height: @indicator__size;
  }

  .expandable__title {
    .prevent-highlighting();

    display: inline-block;
    box-sizing: border-box;
    width: calc(100% ~'-' @indicator__size);
    margin: 0px;
    padding: @spacing--4 @spacing--2 0px @spacing--2;
    text-align: left;
  }

  .expandable__indicator-container {
    display: inline-block;
    margin: 0px;
    padding: @spacing--4 @spacing--2 0px 0px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0%, -50%);
  }

  .expandable__indicator {
    .prevent-highlighting();
    position: relative;
    display: block;
    width: @indicator__size;
    height: @indicator__size;
    max-width: @indicator__size;
    max-height: @indicator__size;
    font-size: @font-size__heading--3;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.25s ease;
    }

    i:first-of-type {
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    i:first-of-type.rotate {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    i:last-of-type.rotate {
      transform: translate(-50%, -50%) rotate(90deg);
      opacity: 0;
    }
  }

  .expandable__content {
    padding-top: @spacing--2;
    padding-left: @spacing--2;
  }
</style>
