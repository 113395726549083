<script>
  import BaseButtonIconLogo from './base-button-icon-logo.vue'

  export default {
    components: {
      BaseButtonIconLogo,
    },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-button-icon-logo :color="'007ab5'" :href="'https://www.linkedin.com/company/acuity-insurance/'">
    <i class="acuity-icon acuity-icon-linkedin-square"></i>
  </base-button-icon-logo>
</template>

<style lang="less" module></style>
