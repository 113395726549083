<script>
  import validators from './validators/validators.js'
  import BaseInputTextbox from './base-input-textbox.vue'
  import ButtonIcon from './button-icon.vue'

  export default {
    components: {
      BaseInputTextbox,
      ButtonIcon,
    },
    // Disable default inherit of attributes so we control where it gets bound
    inheritAttrs: false,
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** v-model value property. */
      value: {
        type: Object,
        default() {
          return {
            inputValue: '',
            isValid: true,
            error: '',
            showError: false,
          }
        },
      },
      /** Set to true if value entry is required. */
      required: {
        type: Boolean,
        default: false,
      },
      /** Setting a max date will disable future dates and consider them invalid. */
      maxDate: {
        type: Date,
        default: () => new Date('9999-12-31T12:00:00'),
      },
      /** Setting a min date will disable past dates and consider them invalid. */
      minDate: {
        type: Date,
        default: () => new Date('1925-01-01T12:00:00'),
      },
    },
    data() {
      return {
        localInputValue: this.value.inputValue ? this.value.inputValue : '',
        textboxValue: {
          inputValue: this.convertLocalInputValueToTextboxInputValue(this.value.inputValue),
          isValid: true,
          error: '',
          showError: !!this.value.showError,
        },
        calendarOpen: false,
        calendarSelectedDate: null,
        calendarFocusedDate: new Date(),
        monthTransition: 'ac-calendar-slide-left',
        rootNodeTop: 0,
        rootNodeRight: 0,
        bodyTop: 0,
        bodyRight: 0,
      }
    },
    computed: {
      minDateDay() {
        return this.minDate.getDate()
      },
      maxDateDay() {
        return this.maxDate.getDate()
      },
      minDateMonth() {
        return this.minDate.getMonth() + 1
      },
      maxDateMonth() {
        return this.maxDate.getMonth() + 1
      },
      minDateYear() {
        return this.minDate.getFullYear()
      },
      maxDateYear() {
        return this.maxDate.getFullYear()
      },
      disableNextMonth() {
        return (
          this.calendarFocusedDate &&
          this.calendarFocusedDate.getFullYear() === this.maxDateYear &&
          this.calendarFocusedDate.getMonth() + 1 === this.maxDateMonth
        )
      },
      disablePreviousMonth() {
        return (
          this.calendarFocusedDate &&
          this.calendarFocusedDate.getFullYear() === this.minDateYear &&
          this.calendarFocusedDate.getMonth() + 1 === this.minDateMonth
        )
      },
      placeCalendarAboveInput() {
        return this.rootNodeTop > (window.innerHeight / 2 || document.documentElement.clientHeight / 2)
      },
      calendarComputedStyleProperties() {
        var newRight = +this.bodyRight - +this.rootNodeRight
        return {
          right: +newRight + 'px',
          top: +this.rootNodeTop + 'px',
        }
      },
      focusedYearDisplay() {
        return this.calendarFocusedDate.getFullYear()
      },
      focusedMonthDisplay() {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][
          this.calendarFocusedDate.getMonth()
        ]
      },
      focusedDays() {
        this.calendarFocusedDate.setDate(1)
        const focusedDays = []

        const today = new Date()
        const currentMonth = new Date(this.calendarFocusedDate.getFullYear(), this.calendarFocusedDate.getMonth() + 1, 0)
        const dayCountOfCurrentMonth = currentMonth.getDate()
        const previousMonth = new Date(this.calendarFocusedDate.getFullYear(), this.calendarFocusedDate.getMonth(), 0)
        const dayCountOfPreviousMonth = previousMonth.getDate()
        const nextMonth = new Date(this.calendarFocusedDate.getFullYear(), this.calendarFocusedDate.getMonth() + 2, 0)
        const weekDay = this.calendarFocusedDate.getDay()

        for (let index = 0; index < 42; index++) {
          let isCurrentMonth = false
          let isToday = false
          let displayText = index
          let selected = false
          let disabled = false
          let monthDifference = -1

          if (index < weekDay) {
            displayText = dayCountOfPreviousMonth - (weekDay - index) + 1
            monthDifference = -1
            if (
              this.calendarSelectedDate &&
              displayText === this.calendarSelectedDate.getDate() &&
              previousMonth.getFullYear() === this.calendarSelectedDate.getFullYear() &&
              previousMonth.getMonth() === this.calendarSelectedDate.getMonth()
            ) {
              selected = true
            }
            if (this.disablePreviousMonth) {
              disabled = true
            } else if (
              nextMonth.getFullYear() === this.minDateYear &&
              nextMonth.getMonth() + 1 === this.minDateMonth &&
              parseInt(displayText) < this.minDateDay
            ) {
              disabled = true
            }
          } else if (index >= dayCountOfCurrentMonth + weekDay) {
            displayText = index + 1 - weekDay - dayCountOfCurrentMonth
            monthDifference = 1
            if (
              this.calendarSelectedDate &&
              displayText === this.calendarSelectedDate.getDate() &&
              nextMonth.getFullYear() === this.calendarSelectedDate.getFullYear() &&
              nextMonth.getMonth() === this.calendarSelectedDate.getMonth()
            ) {
              selected = true
            }
            if (this.disableNextMonth) {
              disabled = true
            } else if (
              nextMonth.getFullYear() === this.maxDateYear &&
              nextMonth.getMonth() + 1 === this.maxDateMonth &&
              parseInt(displayText) > this.maxDateDay
            ) {
              disabled = true
            }
          } else {
            displayText = index + 1 - weekDay
            isCurrentMonth = true
            monthDifference = 0
            if (
              this.calendarSelectedDate &&
              displayText === this.calendarSelectedDate.getDate() &&
              currentMonth.getFullYear() === this.calendarSelectedDate.getFullYear() &&
              currentMonth.getMonth() === this.calendarSelectedDate.getMonth()
            ) {
              selected = true
            }
            if (
              currentMonth.getFullYear() === this.minDateYear &&
              currentMonth.getMonth() + 1 === this.minDateMonth &&
              parseInt(displayText) < this.minDateDay
            ) {
              disabled = true
            }
            if (
              currentMonth.getFullYear() === this.maxDateYear &&
              currentMonth.getMonth() + 1 === this.maxDateMonth &&
              parseInt(displayText) > this.maxDateDay
            ) {
              disabled = true
            }
            if (
              currentMonth.getFullYear() === today.getFullYear() &&
              currentMonth.getMonth() === today.getMonth() &&
              parseInt(displayText) === today.getDate()
            ) {
              isToday = true
            }
          }

          focusedDays.push({
            isCurrentMonth: isCurrentMonth,
            isToday: isToday,
            text: displayText,
            isSelected: selected,
            key: index,
            date: displayText,
            monthDifference: monthDifference,
            isDisabled: disabled,
          })
        }

        return focusedDays
      },
    },
    watch: {
      value: {
        deep: true,
        handler(newValue) {
          const newTextboxInputValue = this.convertLocalInputValueToTextboxInputValue(newValue.inputValue)
          if (this.textboxValue.inputValue !== newTextboxInputValue) {
            this.textboxValue.inputValue = newTextboxInputValue
          }

          // Pass down showError value.
          if (newValue.showError !== this.textboxValue.showError && newValue.showError !== undefined) {
            this.textboxValue.showError = newValue.showError
          }
        },
      },
      calendarOpen(newValue) {
        // When calendar opens or closes, focus on selected date or today.
        const newFocusDate = new Date()
        newFocusDate.setDate(1)

        if (this.calendarSelectedDate) {
          newFocusDate.setFullYear(this.calendarSelectedDate.getFullYear())
          newFocusDate.setMonth(this.calendarSelectedDate.getMonth())
        }

        this.calendarFocusedDate = newFocusDate

        // Watch or stop watching scrolling position and document clicks.
        if (newValue === true) {
          window.addEventListener('scroll', this.updateScrollPosition, { capture: false, passive: true })
          window.addEventListener('click', this.clickedWindow)
          this.updateScrollPosition()
        } else {
          window.removeEventListener('scroll', this.updateScrollPosition, { capture: false, passive: true })
          window.removeEventListener('click', this.clickedWindow)
        }
      },
    },
    mounted() {
      document.getElementsByTagName('BODY')[0].appendChild(this.$refs.calendarUnderlay)
    },
    beforeDestroy() {
      document.getElementsByTagName('BODY')[0].removeChild(this.$refs.calendarUnderlay)
    },
    methods: {
      emitUpdatedModel() {
        /**
         * Event to trigger model syncing.
         * @type {Event}
         */
        this.$emit('update', {
          inputValue: this.localInputValue,
          isValid: this.textboxValue.isValid,
          error: this.textboxValue.error,
          showError: this.textboxValue.showError,
        })
      },
      convertLocalInputValueToTextboxInputValue(localInputValue) {
        // YYYY-MM-DD, convert to MMDDYYYY
        localInputValue = localInputValue + ''
        const localInputValueArray = localInputValue.split('-')
        let textboxInputValue = ''

        // If MM data available add it to make MM.
        if (localInputValueArray.length > 1) {
          textboxInputValue += localInputValueArray[1]
        }

        // If full MM data added, and DD data available, add it to make MMDD.
        if (textboxInputValue.length === 2 && localInputValueArray.length > 2) {
          textboxInputValue += localInputValueArray[2]
        }

        // If full MMDD data added, and YYYY data available, add it to make MMDDYYYY
        if (textboxInputValue.length === 4 && localInputValueArray.length > 0) {
          textboxInputValue += localInputValueArray[0]
        }

        return textboxInputValue
      },
      textboxValueUpdated(newValue) {
        // MMDDYYYY, convert to YYYY-MM-DD to publish updated value.
        const newInputValue = newValue.inputValue
        let newYearString = ''
        let newYearInt = null
        let newMonthString = ''
        let newMonthInt = null
        let newDateString = ''
        let newDateInt = null

        if (newInputValue) {
          if (newInputValue.length > 7) {
            newYearInt = parseInt(newInputValue.substring(4, 8))
            newYearString = newYearInt + ''
          } else if (newInputValue.length > 4) {
            newYearString = newInputValue.substring(4)
          }

          if (newInputValue.length > 1) {
            newMonthInt = parseInt(newInputValue.substring(0, 2))
            newMonthString = newMonthInt + ''
            newMonthString = newMonthString.length === 2 ? newMonthString : '0' + newMonthString
          } else if (newInputValue.length > 0) {
            newMonthString = newInputValue.substring(0)
          }

          if (newInputValue.length > 3) {
            newDateInt = parseInt(newInputValue.substring(2, 4))
            newDateString = newDateInt + ''
            newDateString = newDateString.length === 2 ? newDateString : '0' + newDateInt
          } else if (newInputValue.length > 2) {
            newDateString = newInputValue.substring(2)
          }
        }

        if (newValue.isValid && newValue.inputValue !== '') {
          const newSelectedDate = new Date()
          newSelectedDate.setDate(1)
          newSelectedDate.setFullYear(newYearInt)
          newSelectedDate.setMonth(newMonthInt - 1)
          newSelectedDate.setDate(newDateInt)
          this.calendarSelectedDate = newSelectedDate

          const newFocusDate = new Date()
          newFocusDate.setDate(1)
          newFocusDate.setFullYear(newYearInt)
          newFocusDate.setMonth(newMonthInt - 1)
          this.calendarFocusedDate = newFocusDate
        } else {
          this.calendarSelectedDate = null

          const newFocusDate = new Date()
          newFocusDate.setDate(1)
          this.calendarFocusedDate = newFocusDate
        }

        let newModelValue = newYearString + '-' + newMonthString + '-' + newDateString
        newModelValue = newModelValue === '--' ? '' : newModelValue

        this.localInputValue = newModelValue

        this.emitUpdatedModel()
      },
      focusPreviousMonth() {
        this.monthTransition = 'ac-calendar-slide-right'
        const newFocusDate = new Date()
        newFocusDate.setDate(1)
        newFocusDate.setFullYear(this.calendarFocusedDate.getFullYear())
        newFocusDate.setMonth(this.calendarFocusedDate.getMonth() - 1)

        this.calendarFocusedDate = newFocusDate
      },
      focusNextMonth() {
        this.monthTransition = 'ac-calendar-slide-left'
        const newFocusDate = new Date()
        newFocusDate.setDate(1)
        newFocusDate.setFullYear(this.calendarFocusedDate.getFullYear())
        newFocusDate.setMonth(this.calendarFocusedDate.getMonth() + 1)

        this.calendarFocusedDate = newFocusDate
      },
      clickedDay(day) {
        // Day was clicked, create MMDDYYYY from it and place in textbox
        const newSelectedDate = new Date()
        newSelectedDate.setDate(1)
        newSelectedDate.setFullYear(this.calendarFocusedDate.getFullYear())
        newSelectedDate.setMonth(this.calendarFocusedDate.getMonth() + day.monthDifference)
        newSelectedDate.setDate(day.date)

        let displayMonth = newSelectedDate.getMonth() + 1 + ''
        displayMonth = displayMonth.length === 2 ? displayMonth : '0' + displayMonth

        let displayDate = newSelectedDate.getDate() + ''
        displayDate = displayDate.length === 2 ? displayDate : '0' + displayDate

        this.textboxValue.inputValue = displayMonth + displayDate + newSelectedDate.getFullYear()

        this.calendarOpen = false
      },
      clickedCalendarUnderlay(event) {
        if (event.target === this.$refs.calendarUnderlay) {
          this.calendarOpen = !this.calendarOpen

          if (!this.calendarOpen) {
            this.textboxValue.showError = true
          }
        }
      },
      clickedCalendarIcon() {
        this.calendarOpen = !this.calendarOpen

        if (!this.calendarOpen) {
          this.textboxValue.showError = true
        }
      },
      clickedWindow(event) {
        let target = event.target

        if (target === undefined || target === null) {
          this.calendarOpen = false
        } else {
          let isContainedInCalendar = false
          if (target === this.$refs.calendarUnderlay || target === this.$refs.calendarIcon) {
            isContainedInCalendar = true
          } else {
            while (target.parentNode) {
              target = target.parentNode
              if (target === this.$refs.calendarUnderlay || target === this.$refs.calendarIcon) {
                isContainedInCalendar = true
              }
            }
          }

          if (!isContainedInCalendar) {
            this.calendarOpen = false
            this.textboxValue.showError = true
          }
        }
      },
      updateScrollPosition: function() {
        var rootNode = this.$refs.rootNode
        var rootNodeRect = rootNode.getBoundingClientRect()
        var bodyRect = document.getElementsByTagName('HTML')[0].getBoundingClientRect()

        this.rootNodeTop = parseInt(rootNodeRect.top)
        this.rootNodeRight = parseInt(rootNodeRect.right)
        this.bodyTop = parseInt(bodyRect.top)
        this.bodyRight = parseInt(bodyRect.right)
      },
      validateTextboxInputValue(value) {
        return validators.validateDateString(value, 'MMDDYYYY', this.maxDate, this.minDate)
      },
    },
  }
</script>

<template>
  <div ref="rootNode" :class="$style['root-node']">
    <div ref="calendarIcon" :class="$style.calendar__button">
      <button-icon tabindex="-1" @click="clickedCalendarIcon">
        <i class="acuity-icon acuity-icon-calendar" />
      </button-icon>
    </div>

    <transition name="ac-calendar-fade">
      <div
        v-show="calendarOpen"
        :key="'calendar__container'"
        ref="calendarUnderlay"
        :class="$style.calendar__container"
        tabindex="0"
        @click="clickedCalendarUnderlay"
      >
        <div :class="[$style.calendar, { [$style['calendar--above']]: placeCalendarAboveInput }]" :style="calendarComputedStyleProperties">
          <div :class="$style.calendar__header">
            <span :class="$style.calendar__header__button__container">
              <button-icon v-show="!disablePreviousMonth" :class="$style.calendar__header__button" tabindex="-1" @click="focusPreviousMonth">
                <i class="acuity-icon acuity-icon-chevron-left" />
              </button-icon>
            </span>
            <span :class="$style.calendar__header__title">
              <transition :name="monthTransition" mode="out-in">
                <span :key="focusedMonthDisplay + '__title'">{{ focusedMonthDisplay }} {{ focusedYearDisplay }}</span>
              </transition>
            </span>
            <span :class="$style.calendar__header__button__container">
              <button-icon v-show="!disableNextMonth" :class="$style.calendar__header__button" tabindex="-1" @click="focusNextMonth">
                <i class="acuity-icon acuity-icon-chevron-right" />
              </button-icon>
            </span>
          </div>

          <transition :name="monthTransition" mode="out-in">
            <div :key="focusedMonthDisplay + '__body'" :class="$style.calendar__body">
              <div
                v-for="(dayHeading, index) in ['S', 'M', 'T', 'W', 'T', 'F', 'S']"
                :key="'column-heading__' + dayHeading + '__' + index"
                :class="$style.calendar__day__heading"
              >
                {{ dayHeading }}
              </div>
              <div
                v-for="day in focusedDays"
                :key="'month__' + focusedMonthDisplay + '__day__' + day.text + '__monthoffset__' + day.monthDifference"
                :class="[
                  $style.calendar__day,
                  { [$style['calendar__day--blurred']]: !day.isCurrentMonth },
                  { [$style['calendar__day--selected']]: day.isSelected },
                  { [$style['calendar__day--disabled']]: day.isDisabled },
                  { [$style['calendar__day--today']]: day.isToday },
                ]"
                @click="clickedDay(day)"
              >
                {{ day.text }}
              </div>
            </div>
          </transition>
        </div>
      </div>
    </transition>

    <base-input-textbox
      v-model="textboxValue"
      :format="'**/**/****'"
      :placeholder="'MM/DD/YYYY'"
      :inputmode="'numeric'"
      :required="required"
      :validator="validateTextboxInputValue"
      @update="textboxValueUpdated"
    ></base-input-textbox>
  </div>
</template>

<style>
  .ac-calendar-fade-enter,
  .ac-calendar-fade-leave-to {
    opacity: 0;
  }

  .ac-calendar-fade-enter-active,
  .ac-calendar-fade-leave-active {
    transition: opacity 0.25s ease;
  }

  .ac-calendar-slide-left-enter {
    opacity: 0;
    transform: translate(40px);
  }

  .ac-calendar-slide-left-leave-to {
    opacity: 0;
    transform: translate(-40px);
  }

  .ac-calendar-slide-right-enter {
    opacity: 0;
    transform: translate(-40px);
  }

  .ac-calendar-slide-right-leave-to {
    opacity: 0;
    transform: translate(40px);
  }

  .ac-calendar-slide-left-enter-active {
    display: inline-block;
    transition: all 0.2s ease-out;
  }

  .ac-calendar-slide-left-leave-active {
    display: inline-block;
    transition: all 0.2s ease;
  }

  .ac-calendar-slide-right-enter-active {
    display: inline-block;
    transition: all 0.2s ease-out;
  }

  .ac-calendar-slide-right-leave-active {
    display: inline-block;
    transition: all 0.2s ease;
  }
</style>

<style lang="less" module>
  @import (reference) '../design/index.less';

  @calendar__day__size: 40px;

  .root-node {
    position: relative;
  }

  .calendar__container {
    z-index: calc(@default-z-index__modal - 1);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    @media only screen and (min-width: @min-width--medium) {
      pointer-events: none;
      background-color: transparent;
    }

    @media only screen and (max-width: @max-width--small) {
      cursor: pointer;
      background-color: rgba(
        @color-defaults__underlay--modal-rgb,
        @color-defaults__underlay--modal-rgb,
        @color-defaults__underlay--modal-rgb,
        @color-defaults__underlay--modal-a
      );
    }
  }

  .calendar__button {
    position: absolute;
    top: calc(@form-item__height / 2);
    right: @spacing--3;
    transform: translate(0, -50%);
  }

  .calendar {
    position: absolute;
    z-index: calc(@default-z-index__modal + 1);

    width: calc(@calendar__day__size * 7);
    border: solid 1px @color-defaults__border;
    border-collapse: separate;
    border-radius: @default-border__radius;
    background-color: @color-defaults__section--2;
    text-align: center;
    padding: 0;
    overflow: hidden;
    box-shadow: 3px 3px 6px 3px rgba(200, 200, 200, 0.5);
    pointer-events: all !important;

    @media only screen and (min-width: @min-width--medium) {
      transform: translate(0px, @form-item__height);
      &--above {
        transform: translate(0%, -100%);
      }
    }

    @media only screen and (max-width: @max-width--small) {
      top: 50% !important;
      left: 50% !important;
      bottom: inherit !important;
      right: inherit !important;
      transform: translate(-50%, -50%);
    }
  }

  .calendar__header {
    height: 25px;
    padding-top: @spacing--1;
    padding-bottom: @spacing--1;

    .flex();
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .calendar__header__button__container {
      flex-grow: 0;
      width: @calendar__day__size;
    }

    .calendar__header__button {
      font-weight: @font-weight--regular;
      font-size: @font-size__body;
      line-height: 0px;
      width: 100%;
    }

    .calendar__header__title {
      font-size: @font-size__body;
      font-weight: @font-weight--bold;
      color: @color-defaults__text--heading;
      flex-grow: 1;
      .prevent-highlighting();
    }
  }

  .calendar__body {
    .flex();
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    .calendar__day__heading {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: calc(100% / 7);
      height: @calendar__day__size;
      box-sizing: border-box;
      color: @color-defaults__text--heading;
      font-weight: @font-weight--regular;
      font-size: @font-size__body;
      .prevent-highlighting();
    }

    .calendar__day {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: calc(100% / 7);
      height: @calendar__day__size;
      box-sizing: border-box;
      color: @color-defaults__text--body;
      font-weight: @font-weight--regular;
      font-size: @font-size__body;
      border-top: solid 1px @color-defaults__border;
      border-right: solid 1px @color-defaults__border;
      cursor: pointer;
      .prevent-highlighting();

      &:hover {
        background-color: darken(@color-defaults__section--2, @color-alteration-step-size--2);
      }

      &:active {
        background-color: darken(@color-defaults__section--2, @color-alteration-step-size--2 + @color-alteration-step-size--2);
      }

      &--blurred {
        color: @color-defaults__text--placeholder;
      }

      &--today {
        font-weight: @font-weight--bold;
      }

      &--selected {
        background-color: @color-defaults__selected;
        color: @color-defaults__text--light;
        cursor: inherit;
        pointer-events: none;

        &:hover,
        &:active {
          background-color: @color-defaults__selected;
          color: @color-defaults__text--light;
        }
      }

      &--disabled {
        background-color: @color-defaults__disabled;
        color: @color-defaults__text--placeholder;
        cursor: inherit;
        pointer-events: none;

        &:hover,
        &:active {
          background-color: @color-defaults__disabled;
          color: @color-defaults__text--placeholder;
        }
      }
    }

    .calendar__day:nth-child(7n) {
      border-right: none;
    }
  }
</style>
