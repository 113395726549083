<script>
  export default {
    components: {},
    props: {
      /** URL destination of the anchor tag that will be generated. */
      href: {
        required: true,
        type: String,
        default: '',
      },
      /** Font color (0-3). */
      color: {
        required: false,
        type: Number,
        default: 0,
      },
    },
    data() {
      return {}
    },
    computed: {
      linkClassObject: function() {
        return {
          [this.$style.link]: true,
          [this.$style['link--action']]: this.color === 0,
          [this.$style['link--text']]: this.color === 1,
          [this.$style['link--heading']]: this.color === 2,
          [this.$style['link--subheading']]: this.color === 3,
        }
      },
    },
    methods: {},
  }
</script>

<template>
  <a
    :class="linkClassObject"
    :href="href"
    v-bind="{
      ...$attrs, //Expand out attributes incase we want to override anything
    }"
    v-on="$listeners"
  >
    <!-- @slot Slot for link text content. -->
    <slot />
  </a>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &--action {
      color: @color-defaults__text--link;
      &:hover {
        color: lighten(@color-defaults__text--link, @color-alteration-step-size--2);
        text-decoration: underline;
        cursor: pointer;
      }

      &:focus {
        color: lighten(@color-defaults__text--link, @color-alteration-step-size--2);
        text-decoration: underline;
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--link, @color-alteration-step-size--2);
        text-decoration: underline;
      }
    }

    &--text {
      color: @color-defaults__text--body;
      &:hover {
        color: lighten(@color-defaults__text--body, @color-alteration-step-size--2);
        text-decoration: underline;
        cursor: pointer;
      }

      &:focus {
        color: lighten(@color-defaults__text--body, @color-alteration-step-size--2);
        text-decoration: underline;
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--body, @color-alteration-step-size--2);
        text-decoration: underline;
      }
    }

    &--heading {
      color: @color-defaults__text--heading;
      &:hover {
        color: lighten(@color-defaults__text--heading, @color-alteration-step-size--2);
        text-decoration: underline;
        cursor: pointer;
      }

      &:focus {
        color: lighten(@color-defaults__text--heading, @color-alteration-step-size--2);
        text-decoration: underline;
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--heading, @color-alteration-step-size--2);
        text-decoration: underline;
      }
    }

    &--subheading {
      color: @color-defaults__text--subheading;
      &:hover {
        color: lighten(@color-defaults__text--subheading, @color-alteration-step-size--2);
        text-decoration: underline;
        cursor: pointer;
      }

      &:focus {
        color: lighten(@color-defaults__text--subheading, @color-alteration-step-size--2);
        text-decoration: underline;
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--subheading, @color-alteration-step-size--2);
        text-decoration: underline;
      }
    }
  }
</style>
