const _isEmpty = function(value) {
  let isEmpty = true

  if (typeof value === 'string') {
    isEmpty = value.trim().length === 0
  } else if (Array.isArray(value)) {
    isEmpty = value.length === 0
  } else if (value !== null && value !== undefined) {
    if (typeof value === 'object') {
      if (value instanceof Date) {
        isEmpty = false
      } else {
        isEmpty = Object.keys(value).length === 0
      }
    } else {
      isEmpty = (value + '').trim().length === 0
    }
  }

  return isEmpty
}

const _validateRequired = function(value) {
  return _isEmpty(value) ? 'Required' : ''
}

const _validateName = function(name) {
  if (name === '') {
    return ''
  } else if (typeof name !== 'string') {
    return 'Invalid value'
  }

  const pattern = new RegExp(/^[a-zA-Z-' ]+$/)
  const isValidName = pattern.test(name)
  return isValidName ? '' : 'Invalid value'
}

const _validatePhone = function(phone) {
  if (phone === '') {
    return ''
  } else if (typeof phone !== 'string') {
    return 'Invalid phone number'
  }

  const pattern = new RegExp(/^[2-9]\d{2}[2-9]\d{2}\d{4}$/)
  const isValidPhone = pattern.test(phone)
  return isValidPhone ? '' : 'Invalid phone number'
}

const _validateEmail = function(email) {
  if (email === '') {
    return ''
  } else if (typeof email !== 'string') {
    return 'Invalid email'
  }

  const pattern = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
  const isValidEmail = pattern.test(email)
  return isValidEmail ? '' : 'Invalid email'
}

const _validatePolicy = function(policy) {
  if (policy === '') {
    return ''
  } else if (typeof policy !== 'string') {
    return 'Invalid policy number'
  }

  const pattern = new RegExp(/(^[A-Z0-9]{6}$)|(^[A-Z0-9]{6}[-]{1}[0-9]{1}$)/)
  const isValidPolicy = pattern.test(policy)
  return isValidPolicy ? '' : 'Invalid policy number'
}

const _validateDateString = function(dateString, dateStringFormat, maxDate, minDate) {
  // Check arguments for type and content.
  if (dateString === '') {
    return ''
  } else if (typeof dateString !== 'string') {
    return 'Invalid date'
  }

  // Default format is YYYY-MM-DD
  if (_isEmpty(dateStringFormat)) {
    dateStringFormat = 'YYYY-MM-DD'
  } else if (typeof dateStringFormat !== 'string') {
    return 'Invalid date'
  } else if (dateStringFormat.indexOf('YYYY') === -1 || dateStringFormat.indexOf('MM') === -1 || dateStringFormat.indexOf('DD') === -1) {
    return 'Invalid date'
  }

  // Invalid if format length is not equal to value length.
  if (dateString.length !== dateStringFormat.length) {
    return 'Invalid date'
  }

  // Locate year, month, day.
  const yearString = dateString.substr(dateStringFormat.indexOf('YYYY'), 4)
  const monthString = dateString.substr(dateStringFormat.indexOf('MM'), 2)
  const dayString = dateString.substr(dateStringFormat.indexOf('DD'), 2)

  // Validate year
  const isValidYear = !isNaN(yearString) && parseInt(yearString) >= 1000 && parseInt(yearString) <= 9999

  // Validate month
  const isValidMonth = !isNaN(monthString) && parseInt(monthString) >= 1 && parseInt(monthString) <= 12

  // Validate day
  const daysInChosenMonth = isValidMonth && isValidYear ? new Date(parseInt(yearString), parseInt(monthString), 0).getDate() : 31
  const isValidDay = !isNaN(dayString) && parseInt(dayString) >= 1 && parseInt(dayString) <= daysInChosenMonth

  let errorMessage = ''
  errorMessage = errorMessage.length > 0 ? errorMessage : isValidYear ? '' : 'Invalid year'
  errorMessage = errorMessage.length > 0 ? errorMessage : isValidMonth ? '' : 'Invalid month'
  errorMessage = errorMessage.length > 0 ? errorMessage : isValidDay ? '' : 'Invalid day'

  // Validate max date
  let isPastMaxDate = false
  let pastMaxDateErrorMessage = ''
  if (maxDate && maxDate.getDate && maxDate.getFullYear && maxDate.getMonth && errorMessage === '') {
    isPastMaxDate = parseInt(yearString) > maxDate.getFullYear()

    if (!isPastMaxDate && parseInt(yearString) === maxDate.getFullYear()) {
      isPastMaxDate = parseInt(monthString) > maxDate.getMonth() + 1

      if (!isPastMaxDate && parseInt(monthString) === maxDate.getMonth() + 1) {
        isPastMaxDate = parseInt(dayString) > maxDate.getDate()
      }
    }

    if (isPastMaxDate) {
      const maxMonthString = maxDate.getMonth() < 9 ? '0' + (maxDate.getMonth() + 1) : '' + (maxDate.getMonth() + 1)
      const maxDayString = maxDate.getDate() < 10 ? '0' + maxDate.getDate() : '' + maxDate.getDate()
      pastMaxDateErrorMessage = 'Must be prior to ' + maxMonthString + '/' + maxDayString + '/' + maxDate.getFullYear()
    }
  }

  // Validate min date
  let isBeforeMinDate = false
  let beforeMinDateErrorMessage = ''
  if (minDate && minDate.getDate && minDate.getFullYear && minDate.getMonth && errorMessage === '') {
    isBeforeMinDate = parseInt(yearString) < minDate.getFullYear()

    if (!isBeforeMinDate && parseInt(yearString) === minDate.getFullYear()) {
      isBeforeMinDate = parseInt(monthString) < minDate.getMonth() + 1

      if (!isBeforeMinDate && parseInt(monthString) === minDate.getMonth() + 1) {
        isBeforeMinDate = parseInt(dayString) < minDate.getDate()
      }
    }

    if (isBeforeMinDate) {
      const minMonthString = minDate.getMonth() < 9 ? '0' + (minDate.getMonth() + 1) : '' + (minDate.getMonth() + 1)
      const minDayString = minDate.getDate() < 10 ? '0' + minDate.getDate() : '' + minDate.getDate()
      beforeMinDateErrorMessage = 'Must be after ' + minMonthString + '/' + minDayString + '/' + minDate.getFullYear()
    }
  }

  errorMessage = errorMessage.length > 0 ? errorMessage : isPastMaxDate ? pastMaxDateErrorMessage : ''
  errorMessage = errorMessage.length > 0 ? errorMessage : isBeforeMinDate ? beforeMinDateErrorMessage : ''

  return errorMessage
}

const _validateStringLength = function(value, minLength, maxLength) {
  let errorMessage = ''
  if (value.length < minLength) {
    errorMessage = `Must be at least ${minLength} characters long`
  }
  if (value.length > maxLength) {
    errorMessage = `Must be less than ${maxLength} characters long`
  }
  return errorMessage
}

export default {
  validateRequired: _validateRequired,
  validateName: _validateName,
  validatePhone: _validatePhone,
  validateEmail: _validateEmail,
  validatePolicy: _validatePolicy,
  validateDateString: _validateDateString,
  validateStringLength: _validateStringLength,
}
