<script>
  import BaseInputTextbox from './base-input-textbox.vue'

  export default {
    components: { BaseInputTextbox },
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div>
    <base-input-textbox
      v-bind="{
        ...$attrs, //Expand out attributes incase we want to override anything
        placeholder: '0000',
        maxLength: 4,
        minLength: 4,
        type: 'number',
        inputmode: 'numeric',
      }"
      :class="$style['input']"
      v-on="$listeners"
    ></base-input-textbox>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .input {
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
</style>
