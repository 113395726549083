<script>
  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="$style.important">
    <i :class="'acuity-icon acuity-icon-important-information ' + $style.important__icon" />
    <span :class="$style.important__text">
      <div :class="$style.important__text__content">
        <!-- @slot Slot container for tile contents. -->
        <slot />
      </div>
    </span>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .important {
    .flex();
    width: 100%;
    padding: @spacing--2 @spacing--2 0px @spacing--2;
    align-items: center;
    box-sizing: border-box;
  }

  .important__icon {
    display: inline-flex;
    flex-shrink: 0;
    flex-grow: 0;

    padding: 0px @spacing--1 0px 0px;

    color: @color-defaults__text--body;
    font-size: @font-size__heading--3;
  }

  .important__text {
    display: inline-flex;
    flex-shrink: 1;
    flex-grow: 1;

    text-align: left;

    color: @color-defaults__text--body;
    font-size: @font-size__body--4;
    font-family: @font-family;
    font-weight: @font-weight--regular;
  }

  .important__text__content {
    display: block;
  }
</style>
