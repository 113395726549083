<script>
  import BaseContainerTooltip from './base-container-tooltip.vue'

  export default {
    components: {
      BaseContainerTooltip,
    },
    props: {
      /** Displays the tooltip. */
      visible: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        rootNodeTop: 0,
        rootNodeRight: 0,
        rootNodeLeft: 0,
        bodyRight: 0,
        slotObserver: null,
      }
    },
    computed: {
      tooltipComputedStyleProperties() {
        const rightEdge = +this.bodyRight - +this.rootNodeRight
        const leftEdge = +this.bodyRight - +this.rootNodeLeft
        const newRight = rightEdge + leftEdge > 0 ? (rightEdge + leftEdge) / 2 : 0
        return {
          right: +newRight + 'px',
          top: +this.rootNodeTop + 'px',
        }
      },
    },
    watch: {
      visible(newValue) {
        if (newValue === true) {
          window.addEventListener('scroll', this.updateScrollPosition, { capture: false, passive: true })
          window.addEventListener('resize', this.updateScrollPosition, { capture: false, passive: true })

          this.observer = new MutationObserver(this.updateScrollPosition.bind(this))
          this.observer.observe(this.$refs.tooltip, { attributes: false, childList: true, characterData: true, subtree: true })

          this.updateScrollPosition()
        } else {
          window.removeEventListener('scroll', this.updateScrollPosition, { capture: false, passive: true })
          window.removeEventListener('resize', this.updateScrollPosition, { capture: false, passive: true })

          if (this.observer && this.observer.disconnect) {
            this.observer.disconnect()
          }
        }
      },
    },
    mounted() {
      document.getElementsByTagName('BODY')[0].appendChild(this.$refs.tooltip)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.updateScrollPosition)
      window.removeEventListener('resize', this.updateScrollPosition)

      if (this.observer && this.observer.disconnect) {
        this.observer.disconnect()
      }

      document.getElementsByTagName('BODY')[0].removeChild(this.$refs.tooltip)
    },
    beforeUpdate() {
      if (this.visible) {
        this.updateScrollPosition()
      }
    },
    methods: {
      updateScrollPosition: function() {
        if (this.visible) {
          var rootNode = this.$refs.wrapper
          var rootNodeRect = rootNode.getBoundingClientRect()
          var bodyRect = document.getElementsByTagName('HTML')[0].getBoundingClientRect()

          this.rootNodeTop = parseInt(rootNodeRect.top)
          this.rootNodeRight = parseInt(rootNodeRect.right)
          this.rootNodeLeft = parseInt(rootNodeRect.left)
          this.bodyRight = parseInt(bodyRect.right)
        }
      },
    },
  }
</script>

<template>
  <div ref="wrapper" :class="$style.tooltip__wrapper">
    <div
      ref="tooltip"
      :class="[$style.tooltip, { [$style['tooltip--hidden']]: !visible }]"
      :style="tooltipComputedStyleProperties"
      @mouseenter.stop="() => false"
    >
      <base-container-tooltip>
        <!-- @slot Slot container for tooltip contents. -->
        <slot></slot>
      </base-container-tooltip>
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .tooltip__wrapper {
    height: 0px;
    width: 100%;
    pointer-events: none;
  }

  .tooltip {
    position: fixed;
    top: 0px;
    right: 0px;
    pointer-events: none;
    opacity: 1;
    max-height: 500px;
    transform-origin: top;
    transform: scaleY(1) scaleX(1) translateX(50%);
    transition: opacity 0.3s ease, transform 0.2s ease 0.1s, max-height 0.2s ease 0.1s;

    .flex();
    justify-content: center;
  }

  .tooltip--hidden {
    opacity: 0;
    transform: scaleY(0.3) scaleX(1) translateX(50%);
    max-height: 0px;
  }
</style>
