<script>
  export default {
    components: {},
    props: {
      /** Background color indicator. Default is 0 (primary). 1 (secondary). 2 (tertiary). */
      background: {
        required: false,
        type: Number,
        default: 0,
      },
      /** Hides top border. */
      hideTopRuler: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="[{ [$style['page-section__background--2']]: background === 1 }, { [$style['page-section__background--1']]: background !== 1 }]">
    <div :class="[$style['page-section'], { [$style['page-section--top-seperator']]: !hideTopRuler }]">
      <div :class="$style['page-section__content']">
        <!-- @slot Slot container for section content. -->
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .page-section {
    position: relative;
    max-width: 100%;
    overflow: hidden;
    color: @color-defaults__text--body;
    font-weight: @font-weight--regular;
    font-family: @font-family;
    font-size: @font-size__body;

    &--top-seperator {
      &:before {
        content: ' ';
        display: block;
        background-color: @color-defaults__border;
        height: 2px;
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
      }
    }
  }

  .page-section__background {
    &--1 {
      background-color: @color-defaults__section--1;
    }
    &--2 {
      background-color: @color-defaults__section--2;
    }
  }

  .page-section__content {
    .grid-row__content();
  }
</style>
