<script>
  import ButtonIcon from './button-icon.vue'

  export default {
    components: {
      ButtonIcon,
    },
    props: {
      /** Displays the modal. */
      visible: {
        required: false,
        type: Boolean,
        default: false,
      },
      /** Disables default methods of closing the modal (X button and underlay clicks). */
      disableSystemClose: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        scrollTopOnOpen: 0,
      }
    },
    computed: {},
    watch: {
      visible(newVisible) {
        if (newVisible === false) {
          this.$refs['modal-container'].scrollTop = 0

          document.documentElement.scrollTop = document.body.scrollTop = this.scrollTopOnOpen
          document.getElementsByTagName('BODY')[0].classList.remove('ac-modal-fullscreen-body-noscroll')
        } else {
          this.scrollTopOnOpen = document.documentElement.scrollTop || document.body.scrollTop
          document.getElementsByTagName('BODY')[0].classList.add('ac-modal-fullscreen-body-noscroll')
        }
      },
    },
    methods: {
      emitClose: function() {
        /**
         * Close request event.
         * @type {Event}
         */
        this.$emit('close')
      },
      xButtonClicked: function() {
        if (!this.disableSystemClose) {
          this.emitClose()
        }
      },
      underlayClicked: function(event) {
        if (!this.disableSystemClose) {
          this.emitClose()
        }
      },
    },
  }
</script>

<template>
  <div v-show="visible">
    <transition name="ac-modal-fullscreen-underlay-fade" mode="out-in">
      <div
        v-if="visible"
        :key="'modal-fullscreen-underlay'"
        ref="underlay"
        :class="[$style['modal-fullscreen__underlay'], { [$style['modal-fullscreen__underlay--disabled']]: disableSystemClose }]"
        @click.self="underlayClicked"
      ></div>
    </transition>

    <transition name="ac-modal-fullscreen-slide-up" mode="out-in">
      <div v-show="visible" :key="'modal-fullscreen'" :class="$style['modal-fullscreen__container-wrapper']" @click.self="underlayClicked">
        <div :class="$style['modal-fullscreen__close-button-container']">
          <button-icon v-if="!disableSystemClose" :color="5" :class="$style['modal-fullscreen__close-button']" @click="xButtonClicked">
            <i :class="'acuity-icon acuity-icon-times ' + $style['modal-fullscreen__close-button__icon']"></i>
          </button-icon>
        </div>

        <div ref="modal-container" :class="$style['modal-fullscreen__container']" @click.self="underlayClicked">
          <div :class="$style['modal-fullscreen']">
            <!-- @slot Slot container for modal-fullscreen content. -->
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style>
  .ac-modal-fullscreen-body-noscroll {
    position: fixed;
    overflow: hidden;
  }

  .ac-modal-fullscreen-underlay-fade-enter,
  .ac-modal-fullscreen-underlay-fade-leave-to {
    opacity: 0;
  }

  .ac-modal-fullscreen-underlay-fade-enter-active,
  .ac-modal-fullscreen-underlay-fade-leave-active {
    transition: opacity 0.25s ease;
  }

  .ac-modal-fullscreen-slide-up-enter,
  .ac-modal-fullscreen-slide-up-leave-to {
    opacity: 0;
    transform: translate(-50%, 100vh) !important;
  }

  .ac-modal-fullscreen-slide-up-enter-to,
  .ac-modal-fullscreen-slide-up-leave {
    opacity: 1;
    transform: translateY(-50%, 0vh) !important;
  }

  .ac-modal-fullscreen-slide-up-enter-active {
    transition: opacity 0.25s linear, transform 0.25s ease-out 0.2s;
  }

  .ac-modal-fullscreen-slide-up-leave-active {
    transition: opacity 0.15s linear 0.1s, transform 0.25s ease-in;
  }
</style>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .modal-fullscreen__container-wrapper {
    z-index: @default-z-index__modal;
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    cursor: pointer;
    &--disabled {
      cursor: default;
    }
  }

  .modal-fullscreen__container {
    width: 100%;
    max-height: 100%;
    background-color: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    overflow-y: auto;

    cursor: pointer;
    &--disabled {
      cursor: default;
    }
  }

  .modal-fullscreen {
    display: inline-block;
    text-align: left;
    box-sizing: border-box;
    background-color: @color-defaults__section--1;

    margin: @spacing--3 calc(@spacing--2 + @font-size__heading--6) @spacing--3 calc(@spacing--2 + @font-size__heading--6);
    width: calc(100% ~'-' @spacing--2 ~'-' @spacing--2 ~'-' @font-size__heading--6 ~'-' @font-size__heading--6);
    max-width: @max-width--content;
    min-height: calc(100% ~'-' @spacing--3 ~'-' @spacing--3);

    cursor: default;

    padding: @spacing--5 @spacing--6;
    @media only screen and (max-width: @max-width--small) {
      padding: @spacing--4 @spacing--2;
    }
  }

  .modal-fullscreen__close-button-container {
    position: relative;
    display: inline-flex;
    flex-grow: 0;
    flex-shrink: 1;
    background-color: transparent;

    height: 1px;
    width: calc(100% ~'-' @spacing--2 ~'-' @spacing--2 ~'-' @font-size__heading--6 ~'-' @font-size__heading--6);
    max-width: @max-width--content;
  }

  .modal-fullscreen__close-button {
    position: absolute;
    right: -@spacing--1;
    top: calc(@spacing--3 + @spacing--1);
    transform: translateX(100%);
  }

  .modal-fullscreen__close-button__icon {
    font-size: @font-size__heading--6;
  }

  .modal-fullscreen__underlay {
    background-color: rgba(
      @color-defaults__underlay--modal-rgb,
      @color-defaults__underlay--modal-rgb,
      @color-defaults__underlay--modal-rgb,
      @color-defaults__underlay--modal-a
    );

    z-index: calc(@default-z-index__modal - 1);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    cursor: pointer;
    &--disabled {
      cursor: default;
    }
  }
</style>
