<script>
  import Vue from 'vue'
  import BaseInputCheckbox from './base-input-checkbox.vue'
  import ButtonIcon from './button-icon.vue'
  import ButtonLink from './button-link.vue'
  import ButtonAction from './button-action.vue'
  import InputSelect from './input-select.vue'
  import InputTextbox from './input-textbox.vue'
  import ButtonMenuAction from './button-menu-action.vue'
  import MenuActions from './menu-actions.vue'

  export default {
    /* TODO: Fix bulk actions for multiple tables on one page. */

    // New Story
    /* TODO: Lazy loading. */
    /* TODO: Entire table loading */
    components: { BaseInputCheckbox, ButtonIcon, ButtonLink, ButtonAction, InputSelect, InputTextbox, ButtonMenuAction, MenuActions },
    props: {
      /** List of column objects containing properties: [id (reference to corresponding row data), title, sortable (boolean or id reference to row data to sort on), editable, expanding (grow quicker than other columns)] */
      columns: {
        type: Array,
        required: true,
      },
      /** List of row data elements that are used to provide content of table cells. Reserved properties [loading (used to mark a row as loading)] */
      rows: {
        type: Array,
        required: false,
        default: () => [],
      },
      /** Object defining the title and sortable status of the table on mobile size screens. */
      mobileColumn: {
        type: Object,
        required: true,
        default: () => ({
          title: '',
          sortable: false,
        }),
      },
      /** Property to define the starting point for rows per page selection. Valid values include 5 & 10. */
      rowsPerPage: {
        type: Number,
        required: false,
        default: 10,
        validator(providedRowsPerPage) {
          return [5, 10].indexOf(providedRowsPerPage) > -1
        },
      },
      /** Actions that a user can trigger within the context of a single row data. List of action objects that contain a title and id property (strings). */
      rowActions: {
        type: Array,
        required: false,
        default: () => [],
        validator(providedRowActions) {
          return Array.isArray(providedRowActions)
        },
      },
      /** Actions that a user can trigger within the context of multiple row data elements. List of action objects that contain a title and id property (strings). */
      bulkActions: {
        type: Array,
        required: false,
        default: () => [],
        validator(providedRowActions) {
          return Array.isArray(providedRowActions)
        },
      },
      /** Title text for the overall table. */
      title: {
        type: String,
        required: false,
        default: null,
      },
      /** Title text for an action that a user can trigger on the table as a whole. Commonly for adding a new item to the table. */
      tableActionText: {
        type: String,
        required: false,
        default: null,
      },
      /** List of strings that should be provided when utilizing custom slots for your columns. Strings define which row data properties search text will execute on. When not provided, searches will execute on column ids. */
      searchFields: {
        type: Array,
        required: false,
        default: () => [],
      },
    },
    data() {
      return {
        bulkActionInputName: Math.floor(Math.random() * Date.now()) + '',
        selectingMultiple: false,
        pagingInfo: {
          currentPage: 0,
          rowsPerPage: this.rowsPerPage,
        },
        rowsPerPageModel: {
          inputValue: this.rowsPerPage + '',
        },
        searchIsOpen: false,
        searchQuery: '',
        searchQueryModel: {
          inputValue: '',
        },
        internalRows: this.generateNewInternalRows(this.rows),
        rowsSelected: 0,
        selectAllModel: {
          inputValue: [],
        },
        selectedRowModels: [],
        activeEditCell: {},
        activeCellEditModel: {
          inputValue: '',
        },
        bulkActionSelectionModel: {
          inputValue: '',
        },
      }
    },
    computed: {
      filteredRows() {
        const searchFields = []
        if (this.searchFields.length > 0) {
          for (let index = 0; index < this.searchFields.length; index++) {
            searchFields.push(this.searchFields[index])
          }
        } else {
          for (let index = 0; index < this.columns.length; index++) {
            searchFields.push(this.columns[index].id)
          }
        }
        const searchFieldsString = searchFields + ''

        const filteredRows = this.internalRows.filter((internalRow) => {
          let keepRow = false

          if (this.searchQuery.length > 0) {
            if (internalRow['__search-query'] !== this.searchQuery || internalRow['__search-fields'] !== searchFieldsString) {
              this.updateRowSearchResultsData(internalRow, searchFields, this.searchQuery)
            }
            keepRow = internalRow['__search-match']
          } else {
            keepRow = true
          }

          return keepRow
        })

        return filteredRows
      },
      visibleRows() {
        const minIndex = this.pagingInfo.rowsPerPage * this.pagingInfo.currentPage
        const maxIndex = minIndex + this.pagingInfo.rowsPerPage

        return this.filteredRows.slice(minIndex, maxIndex)
      },
      rowsAreLoading() {
        const loadingRow = this.internalRows.find((row) => row.loading)
        return loadingRow !== undefined
      },
      pageCount() {
        return Math.ceil(this.filteredRows.length / this.pagingInfo.rowsPerPage)
      },
      rowsContainActions() {
        return this.rowActions.length > 0
      },
      columnsContainActions() {
        const editableColumn = this.columns.find((column) => column.editable)
        return editableColumn !== undefined
      },
      rowsOrColumnsContainActions() {
        return this.rowsContainActions || this.columnsContainActions
      },
      rowsCanBeSelected() {
        return this.bulkActions.length > 0
      },
      bulkActionsOptions() {
        return this.bulkActions.map((bulkAction) => {
          return {
            value: bulkAction.id,
            text: bulkAction.title,
          }
        })
      },
    },
    watch: {
      // TODO: See if we can remove this deep watch since deep watches are expensive.
      rows: {
        deep: true,
        handler(newRows) {
          this.internalRows = this.generateNewInternalRows(newRows)
        },
      },
      visibleRows() {
        this.cancelCellEdit()
      },
    },
    mounted() {
      // TODO: Look for a more clever idea to manage the checkbox models.
      for (let index = 0; index < 50; index++) {
        this.selectedRowModels.push({
          inputValue: [],
        })
      }
    },
    methods: {
      generateNewInternalRows(newRows) {
        this.deselectAllRows()

        if (this.searchQueryModel && this.searchQueryModel.inputValue) {
          this.searchQueryModel.inputValue = ''
          this.searchQuery = ''
        }

        const internalRows = []

        newRows.forEach((newRow, index) => {
          const internalRow = JSON.parse(JSON.stringify(newRow))
          internalRow.__id = index
          internalRows.push(internalRow)
        })

        return internalRows
      },
      checkForSearchMatch(data) {
        return (data + '').toLowerCase().indexOf(this.searchQuery.toLowerCase()) > -1
      },
      updateRowSearchResultsData(rowData, searchFields, searchQuery) {
        rowData['__search-query'] = searchQuery
        rowData['__search-fields'] = searchFields + ''
        rowData['__search-match'] = false

        const searchQueryLowercase = searchQuery.toLowerCase()
        for (let index = 0; index < searchFields.length; index++) {
          const searchField = searchFields[index]
          let workingData = rowData[searchField] + ''
          const searchResults = []

          while (workingData.toLowerCase().indexOf(searchQueryLowercase) > -1) {
            const matchIndex = workingData.toLowerCase().indexOf(searchQueryLowercase)
            if (matchIndex > 0) {
              searchResults.push({
                data: workingData.substring(0, matchIndex),
                highlight: false,
              })
            }

            searchResults.push({
              data: workingData.substr(matchIndex, searchQueryLowercase.length),
              highlight: this.searchFields.length === 0,
            })

            workingData = workingData.slice(matchIndex + searchQueryLowercase.length)

            if (workingData.toLowerCase().indexOf(searchQueryLowercase) === -1 && workingData.length > 0) {
              searchResults.push({
                data: workingData,
                highlight: false,
              })
            }
          }

          if (searchResults.length > 0) {
            rowData['__' + searchField] = searchResults
            rowData['__search-match'] = true
          } else {
            rowData['__' + searchField] = null
          }
        }
      },
      allRowsSelectionChange() {
        if (this.selectAllModel.inputValue.indexOf('checked') === -1) {
          this.deselectAllRows()
        } else {
          this.selectAllRows()
        }
      },
      rowSelectionChange(index) {
        if (this.rowsSelected === 0) {
          this.bulkActionSelectionModel.inputValue = ''
        }

        if (this.selectedRowModels[index].inputValue.indexOf('checked') === -1) {
          this.rowsSelected--
          this.selectAllModel.inputValue = []
        } else if (this.selectedRowModels[index].inputValue.indexOf('checked') > -1) {
          this.rowsSelected++
        }

        if (this.rowsSelected === 0) {
          this.bulkActionSelectionModel.inputValue = ''
        }
      },
      sortMobileColumn() {
        let columnToSortBy = {}

        if (typeof this.mobileColumn.sortable === 'string') {
          columnToSortBy = this.mobileColumn
        } else if (this.mobileColumn.sortable) {
          for (let index = this.columns.length - 1; index >= 0; index--) {
            if (this.columns[index].sortable) {
              columnToSortBy = this.columns[index]
            }
          }
        }

        this.sortColumn(columnToSortBy)
      },
      sortColumn(column) {
        if (column.sortable) {
          column.sort = column.sort === 'ascending' ? 'descending' : 'ascending'

          this.internalRows.sort((thisRow, thatRow) => {
            let result = 0
            const thisRowValue = typeof column.sortable === 'string' ? thisRow[column.sortable] : thisRow[column.id]
            const thatRowValue = typeof column.sortable === 'string' ? thatRow[column.sortable] : thatRow[column.id]

            if (typeof thisRowValue === 'string') {
              if (thisRowValue < thatRowValue) {
                result = column.sort === 'ascending' ? -1 : 1
              } else if (thatRowValue < thisRowValue) {
                result = column.sort === 'ascending' ? 1 : -1
              }
            } else if (typeof thisRowValue === 'number') {
              if (thisRowValue < thatRowValue) {
                result = column.sort === 'ascending' ? -1 : 1
              } else if (thatRowValue < thisRowValue) {
                result = column.sort === 'ascending' ? 1 : -1
              }
            } else if (typeof thisRowValue === 'object' && thisRowValue instanceof Date) {
              if (column.sort === 'ascending') {
                result = thisRowValue - thatRowValue
              } else {
                result = thatRowValue - thisRowValue
              }
            }

            return result
          })

          if (this.pagingInfo.currentPage !== 0) {
            this.pagingInfo.currentPage = 0
          }
        }
      },
      rowsPerPageModelUpdated() {
        if (+this.rowsPerPageModel.inputValue !== this.pagingInfo.rowsPerPage) {
          this.pagingInfo.rowsPerPage = +this.rowsPerPageModel.inputValue
        }
      },
      pageBack() {
        if (this.pagingInfo.currentPage > 0) {
          this.pagingInfo.currentPage--
        }
      },
      pageForward() {
        if (this.pagingInfo.currentPage < this.pageCount - 1) {
          this.pagingInfo.currentPage++
        }
      },
      pageTo(destination) {
        if (destination >= 0 && destination < this.pageCount) {
          this.pagingInfo.currentPage = destination
        }
      },
      editCell(rowIndex, columnIndex) {
        const fieldKey = typeof this.columns[columnIndex].editable === 'string' ? this.columns[columnIndex].editable : this.columns[columnIndex].id

        this.activeCellEditModel.inputValue = this.rows[rowIndex][fieldKey]

        this.activeEditCell = {
          rowIndex: rowIndex,
          columnIndex: columnIndex,
        }
      },
      saveCellEdit() {
        const columnIndex = this.activeEditCell.columnIndex
        const column = this.columns[columnIndex]
        const fieldKey = typeof column.editable === 'string' ? column.editable : column.id

        const newRow = JSON.parse(JSON.stringify(this.rows[this.activeEditCell.rowIndex]))
        newRow[fieldKey] = this.activeCellEditModel.inputValue

        /**
         * Edit row event, passes row pointer followed by an updated version of the row object.
         * @type {Event}
         */
        this.$emit('rowEdit', this.rows[this.activeEditCell.rowIndex], newRow)

        this.cancelCellEdit()
      },
      cancelCellEdit() {
        this.activeCellEditModel.inputValue = ''
        this.activeEditCell = {}
      },
      selectAllRows() {
        for (let index = 0; index < this.visibleRows.length; index++) {
          this.selectedRowModels[index].inputValue = ['checked']
        }

        this.rowsSelected = this.visibleRows.length
      },
      deselectAllRows() {
        if (this.selectedRowModels) {
          this.selectAllModel.inputValue = []

          for (let index = 0; index < this.visibleRows.length; index++) {
            this.selectedRowModels[index].inputValue = []
          }

          this.rowsSelected = 0

          this.bulkActionSelectionModel.inputValue = ''
        }
      },
      searchQueryModelUpdated() {
        const promise = new Promise((resolve, reject) => {
          const searchQueryBuffer = this.searchQueryModel.inputValue
          setTimeout(() => {
            if (searchQueryBuffer === this.searchQueryModel.inputValue) {
              if (this.pagingInfo.currentPage !== 0) {
                this.pagingInfo.currentPage = 0
              }

              this.searchQuery = searchQueryBuffer
            }

            resolve()
          }, 500)
        })

        return promise
      },
      startSearch() {
        this.searchIsOpen = true
        Vue.nextTick(() => {
          this.$refs.searchContainer.getElementsByTagName('input')[0].focus()
        })
      },
      clearSearch() {
        this.searchQueryModel.inputValue = ''
        this.searchQuery = ''
        this.searchIsOpen = false
      },
      emitGlobalTableAction() {
        /**
         * Table action event.
         * @type {Event}
         */
        this.$emit('tableAction')
      },
      emitRowAction(rowActionId, row) {
        /**
         * Row action event, passes array of row pointers followed by the action id.
         * @type {Event}
         */
        this.$emit('rowAction', [this.rows[row.__id]], rowActionId)
      },
      emitBulkAction() {
        const selectedRows = []

        this.selectedRowModels.forEach((selectedRowModel, index) => {
          if (selectedRowModel.inputValue.indexOf('checked') > -1) {
            selectedRows.push(this.rows[this.visibleRows[index].__id])
          }
        })

        /**
         * Row action event, passes array of row pointers followed by the action id.
         * @type {Event}
         */
        this.$emit('rowAction', selectedRows, this.bulkActionSelectionModel.inputValue)

        this.deselectAllRows()
      },
    },
  }
</script>

<template>
  <table :class="$style['table']">
    <thead :class="$style['table__head']">
      <tr :class="[$style['table__row'], $style['table__row--action-bar'], { [$style['table__row--action-bar--active']]: rowsSelected }]">
        <th v-show="rowsSelected" :class="[$style['table__cell'], $style['table__cell--selected-count']]">{{ rowsSelected }} selected</th>
        <th v-show="rowsSelected" :class="[$style['table__cell'], $style['table__cell--bulk-action-select']]">
          <input-select v-model="bulkActionSelectionModel" :options="bulkActionsOptions" :placeholder="'Select action'" @change="emitBulkAction"></input-select>
        </th>
        <th v-show="!rowsSelected && title && title.length > 0" :class="[$style['table__cell'], $style['table__cell--title']]">
          {{ title }}
        </th>
        <th v-if="!rowsSelected" :class="[$style['table__cell'], $style['table__cell--global-actions']]">
          <div ref="searchContainer" style="position: relative; top: 3px;">
            <div :class="[$style['table__search-input'], { [$style['table__search-input--hidden']]: !searchIsOpen }]">
              <input-textbox v-model="searchQueryModel" :placeholder="'Search'" @input="searchQueryModelUpdated"></input-textbox>
            </div>
            <button-icon v-show="!searchIsOpen" :class="$style['table__search-input-open']" @click="startSearch"
              ><i class="acuity-icon acuity-icon-search"></i
            ></button-icon>
            <button-icon v-show="searchIsOpen" :class="$style['table__search-input-close']" @click="clearSearch"
              ><i class="acuity-icon acuity-icon-times"></i
            ></button-icon>
          </div>
          <div v-if="tableActionText">
            <button-action :class="[$style['table__add-item-button'], $style['table__add-item-button--desktop-only']]" @click="emitGlobalTableAction">{{
              tableActionText
            }}</button-action>
            <button-action :class="[$style['table__add-item-button'], $style['table__add-item-button--mobile-only']]" @click="emitGlobalTableAction"
              ><i class="acuity-icon acuity-icon-plus"></i
            ></button-action>
          </div>
        </th>
        <th v-show="rowsSelected" :class="[$style['table__cell'], $style['table__cell--single-action'], $style['table__cell--cancel']]">
          <button-icon :color="5" @click="deselectAllRows">
            <i class="acuity-icon acuity-icon-times"></i>
          </button-icon>
        </th>
      </tr>
      <tr :class="[$style['table__row'], { [$style['table__row--disabled']]: rowsSelected }]">
        <th v-if="rowsAreLoading && rowsCanBeSelected" :class="[$style['table__cell'], $style['table__cell--single-action']]">
          <div :class="$style.table__status__spinner"></div>
          <div :class="$style.table__status__ring"></div>
        </th>
        <th v-if="rowsCanBeSelected && !rowsAreLoading" :class="[$style['table__cell'], $style['table__cell--single-action']]">
          <base-input-checkbox
            v-model="selectAllModel"
            :options="[{ value: 'checked', text: '' }]"
            :class="$style['table__checkbox']"
            :name="'row-selection-checkbox-all__' + bulkActionInputName"
            @change="allRowsSelectionChange"
          ></base-input-checkbox>
        </th>
        <th
          v-for="column in columns"
          :key="'column-heading__' + column.id"
          :class="[
            $style['table__cell'],
            $style['table__cell--heading'],
            { [$style['table__cell--expanding']]: column.expanding },
            { [$style['table__cell--active']]: column.sortable },
            $style['table__cell--mobile-hide'],
          ]"
          @click="sortColumn(column)"
        >
          <span>{{ column.title }}</span>
          <button-icon v-if="column.sortable" :class="$style['table__sort-icon']">
            <span v-if="column.sort === 'ascending'"><i class="acuity-icon acuity-icon-sort-ascending"></i></span>
            <span v-else-if="column.sort === 'descending'"><i class="acuity-icon acuity-icon-sort-descending"></i></span>
            <span v-else><i class="acuity-icon acuity-icon-sort"></i></span>
          </button-icon>
        </th>
        <th
          :class="[
            $style['table__cell'],
            $style['table__cell--heading'],
            $style['table__cell--expanding'],
            { [$style['table__cell--active']]: mobileColumn.sortable },
            $style['table__cell--mobile-only'],
          ]"
          @click="sortMobileColumn"
        >
          <span>{{ mobileColumn.title }}</span>
          <button-icon v-if="mobileColumn.sortable" :class="$style['table__sort-icon']">
            <span v-if="mobileColumn.sort === 'ascending'"><i class="acuity-icon acuity-icon-sort-ascending"></i></span>
            <span v-else-if="mobileColumn.sort === 'descending'"><i class="acuity-icon acuity-icon-sort-descending"></i></span>
            <span v-else><i class="acuity-icon acuity-icon-sort"></i></span>
          </button-icon>
        </th>
        <th v-if="rowsContainActions" :class="[$style['table__cell'], [$style['table__cell--single-action']]]">&nbsp;</th>
      </tr>
    </thead>

    <transition-group name="ac-table-row" tag="tbody" :class="$style['table__body']" mode="out-in">
      <tr v-if="visibleRows.length === 0" :key="'row__no-results'" :class="[$style['table__row'], $style['table__row--full-width-centered']]">
        <td :class="[$style['table__cell'], $style['table__cell--no-results']]">
          <div>
            <span>No results</span><span v-show="searchQuery.length > 0">&nbsp;for '{{ searchQuery }}'</span>
          </div>
          <div v-show="searchQuery.length > 0">
            <button-link @click="clearSearch">Clear search</button-link>
          </div>
        </td>
      </tr>
      <tr
        v-for="(row, rowIndex) in visibleRows"
        :key="'row__' + row.__id"
        :class="[
          $style['table__row'],
          { [$style['table__row--hidden-actions']]: rowsOrColumnsContainActions },
          { [$style['table__row--disabled']]: rowsSelected || row.loading },
          { [$style['table__row--loading']]: row.loading },
        ]"
      >
        <td
          v-if="row.loading"
          :key="'row-loading-indicator__' + row.loading + '__' + row.__id"
          :class="[$style['table__cell'], [$style['table__cell--single-action']]]"
        >
          <div :class="$style.table__status__spinner"></div>
          <div :class="$style.table__status__ring"></div>
        </td>
        <td v-if="rowsCanBeSelected && !row.loading" :class="[$style['table__cell'], [$style['table__cell--single-action']]]">
          <base-input-checkbox
            v-model="selectedRowModels[rowIndex]"
            :options="[{ value: 'checked', text: '' }]"
            :name="'row-selection-checkbox-' + row.__id + '__' + bulkActionInputName"
            @change="rowSelectionChange(rowIndex)"
          ></base-input-checkbox>
        </td>
        <td
          v-for="(column, columnIndex) in columns"
          :key="'row-cell__' + row.__id + '_' + column.id"
          :class="[$style['table__cell'], $style['table__cell--mobile-hide'], { [$style['table__cell--expanding']]: column.expanding }]"
        >
          <div v-if="activeEditCell.rowIndex === row.__id && activeEditCell.columnIndex === columnIndex" :class="$style['table__cell__textbox']">
            <input-textbox v-model="activeCellEditModel" @keyup.enter="saveCellEdit"></input-textbox>
          </div>
          <div v-else>
            <!-- @slot Slots named after column.id values to define custom column templates. -->
            <slot :name="column.id" :row="row">
              <span v-if="searchQuery.length > 0 && row['__' + column.id]">
                <span
                  v-for="(textData, textDataIndex) in row['__' + column.id]"
                  :key="'row-cell__' + row.__id + '_' + column.id + '__' + textDataIndex"
                  :class="[$style['text'], { [$style['text--highlight']]: textData.highlight }]"
                  >{{ textData.data }}</span
                >
              </span>
              <span v-else>
                {{ row[column.id] }}
              </span>
            </slot>
          </div>
          <div v-if="column.editable" :class="$style['table__cell__edit-action']">
            <span v-if="activeEditCell.rowIndex === row.__id && activeEditCell.columnIndex === columnIndex">
              <span :class="$style['table__cell__save-edit-action']">
                <button-icon @click="saveCellEdit"><i class="acuity-icon acuity-icon-checkmark"></i></button-icon>
              </span>
              <span :class="$style['table__cell__save-edit-separator']">
                |
              </span>
              <span :class="$style['table__cell__cancel-edit-action']">
                <button-icon @click="cancelCellEdit"><i class="acuity-icon acuity-icon-times"></i></button-icon>
              </span>
            </span>
            <span v-else>
              <button-icon @click="editCell(row.__id, columnIndex)"><i class="acuity-icon acuity-icon-edit"></i></button-icon>
            </span>
          </div>
        </td>
        <td :class="[$style['table__cell'], $style['table__cell--mobile-only'], $style['table__cell--expanding']]">
          <div>
            <!-- @slot Slot container for defining a custom mobile template. -->
            <slot name="mobile" :row="row">
              <div v-for="column in columns" :key="'row-cell-mobile-content__' + row.__id + '_' + column.id">
                <span v-if="searchQuery.length > 0 && row['__' + column.id]">
                  <span
                    v-for="(textData, textDataIndex) in row['__' + column.id]"
                    :key="'row-cell-mobile-content__' + row.__id + '_' + column.id + '__' + textDataIndex"
                    :class="[$style['text'], { [$style['text--highlight']]: textData.highlight }]"
                    >{{ textData.data }}</span
                  >
                </span>
                <span v-else>
                  {{ row[column.id] }}
                </span>
              </div>
            </slot>
          </div>
        </td>
        <td v-if="rowsContainActions" :class="[$style['table__cell'], [$style['table__cell--single-action']]]">
          <menu-actions>
            <button-menu-action v-for="rowAction in rowActions" :key="rowAction.id" @click="emitRowAction(rowAction.id, row)">
              {{ rowAction.title }}
            </button-menu-action>
          </menu-actions>
        </td>
      </tr>
    </transition-group>

    <tfoot :class="$style['table__foot']">
      <tr :class="$style['table__row']">
        <td :class="[$style['table__cell--paging'], { [$style['table__cell--disabled']]: rowsSelected }]">
          <div :class="$style['left-spacing']">
            <span>Show&nbsp;&nbsp;</span>
          </div>
          <div>
            <span>
              <input-select
                v-model="rowsPerPageModel"
                :options="['5', '10', '25', '50']"
                :required="true"
                :placeholder="'Show'"
                @update="rowsPerPageModelUpdated"
              ></input-select>
            </span>
          </div>
          <div :class="$style['left-spacing']">
            <span v-show="pageCount > 1 && visibleRows.length > 4"
              >&nbsp;{{ filteredRows.indexOf(visibleRows[0]) + 1 }}-{{ filteredRows.indexOf(visibleRows[visibleRows.length - 1]) + 1 }} of
              {{ filteredRows.length }}
            </span>
          </div>
          <div :class="$style['left-spacing']">
            <span v-show="pageCount > 1">
              <span>
                <button-icon :class="$style['table__paging-icon']" @click="pageBack">
                  <i class="acuity-icon acuity-icon-chevron-left"></i>
                </button-icon>
              </span>
              <span>
                &nbsp;&nbsp;
              </span>
              <span>
                <button-icon :class="$style['table__paging-icon']" @click="pageForward">
                  <i class="acuity-icon acuity-icon-chevron-right"></i>
                </button-icon>
              </span>
            </span>
          </div>
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<style>
  .ac-table-row-leave-to,
  .ac-table-row-enter {
    max-height: 0px !important;
    border-width: 0px !important;
    color: transparent !important;
  }

  .ac-table-row-enter-to,
  .ac-table-row-leave {
    max-height: 500px;
  }

  .ac-table-row-enter-active {
    overflow: hidden !important;
    transition: transform 0.4s ease 0s, color 0.4s ease-out 0.4s, max-height 0s linear 0.4s, border-width 0s linear 0.4s;
  }

  .ac-table-row-move {
    overflow: hidden !important;
  }

  .ac-table-row-leave-active {
    overflow: hidden !important;
    transition: transform 0.4s ease 0s, color 0.4s ease-in 0s, max-height 0s linear 0.4s, border-width 0s linear 0.4s;
  }
</style>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .table {
    margin: 0px;
    padding: 0px;
    display: block;
    border: none;
    box-sizing: border-box;

    .grid-row();

    border-collapse: collapse;
    text-align: left;

    font-size: @font-size__body;
    font-weight: @font-weight--regular;
    color: @color-defaults__text--body;
  }

  .table__status__ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: calc(@spacing--1 + @font-size__body--1 + 8px);
    height: calc(@spacing--1 + @font-size__body--1 + 8px);
    border-radius: 50%;
    box-sizing: border-box;
    border: 4px solid @color-defaults__section--2;
    background-color: transparent;
  }

  .table__status__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: calc(@spacing--1 + @font-size__body--1);
    height: calc(@spacing--1 + @font-size__body--1);
    border-radius: 50%;

    &:before {
      position: absolute;
      top: -2px;
      left: -2px;

      width: calc(100% + 4px);
      height: calc(100% + 4px);

      border: 10px solid @color-defaults__text--placeholder;
      border-top: 10px solid @color-defaults__action;
      box-sizing: border-box;
      content: '';
      border-radius: 50%;

      animation: table-status-rotating 1s linear infinite;
    }

    &:after {
      position: absolute;
      top: 4px;
      left: 4px;

      width: calc(100% - 8px);
      height: calc(100% - 8px);

      box-sizing: border-box;
      content: '';
      border-radius: 50%;

      background-color: @color-defaults__section--2;
    }
  }

  .table__head {
    display: block;
    overflow: visible;
    width: 100%;

    .table__row:nth-child(even) {
      background-color: @color-defaults__section--2;

      .table__status__ring {
        border: 4px solid @color-defaults__section--2;
      }
    }

    .table__row:nth-child(odd) {
      background-color: @color-defaults__section--1;

      .table__status__ring {
        border: 4px solid @color-defaults__section--1;
      }
    }

    border-top: 1px solid @color-defaults__border;

    .table__row {
      border-bottom: 1px solid @color-defaults__border;
      transition: background-color 0.25s linear;
    }

    .table__row--action-bar {
      min-height: 65px;
    }

    .table__row--action-bar--active {
      background-color: @color-defaults__selected !important;
    }

    .table__cell--selected-count {
      position: relative;
      color: @color-defaults__text--light;
      flex-grow: 0;
      flex-shrink: 0;
      white-space: nowrap;
      width: inherit;

      &:after {
        width: 1px;
        height: 70%;
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        content: ' ';
        display: block;
        background-color: @color-defaults__text--light;
      }
    }

    .table__cell--bulk-action-select {
      select {
        height: calc(@font-size__body--4 + @spacing--2 + @spacing--2);
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: @spacing--2;
        padding-right: @spacing--5;
        font-size: @font-size__body--4;
      }
    }

    .table__cell--title {
      flex-grow: 0;
      flex-shrink: 0;
      width: inherit;

      font-size: @font-size__heading--4;
      font-weight: @font-weight--medium;
      color: @color-defaults__text--heading;
    }

    .table__cell--global-actions {
      justify-content: flex-end;
      position: relative;

      > div {
        margin-left: @spacing--3;
      }

      @media only screen and (max-width: @max-width--small) {
        > div {
          margin-left: @spacing--2;
        }
      }

      button {
        position: relative;
      }
    }

    .table__search-input {
      position: absolute;
      right: -8px;
      top: 50%;
      transform: translateY(-50%);

      input {
        transition: all 300ms ease;
        height: calc(@font-size__body--4 + @spacing--2 + @spacing--2);
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: @spacing--2;
        padding-right: @spacing--3;
        font-size: @font-size__body--4;
        width: 200px;
        max-width: 200px;

        @media only screen and (min-width: @min-width--medium) {
          width: 250px;
          max-width: 250px;
        }
      }

      &--hidden {
        input {
          max-width: 0px;
          padding: 0px;
          margin: 0px;
          border-width: 0px;
          opacity: 0;
        }
      }
    }

    .table__search-input-close {
      font-size: @font-size__heading--5;
      line-height: @font-size__heading--5;
    }

    .table__search-input-open {
      font-size: @font-size__heading--3;
      line-height: @font-size__heading--3;
    }

    .table__add-item-button {
      font-weight: @font-weight--semibold;
      font-size: @font-size__body--4;
      height: calc(@font-size__body--4 + @spacing--2 + @spacing--2);
      border-radius: calc((@font-size__body--4 + @spacing--2 + @spacing--2) / 2);
      line-height: calc(@font-size__body--4 + @spacing--2 + @spacing--2 - 2px);
      padding-left: calc((@font-size__body--4 + @spacing--2 + @spacing--2) / 2);
      padding-right: calc((@font-size__body--4 + @spacing--2 + @spacing--2) / 2);
      min-width: 0px;

      @media only screen and (max-width: @max-width--small) {
        align-items: flex-start;
        justify-content: left;
        flex-direction: column;
        text-align: left;
      }

      &--mobile-only {
        @media only screen and (min-width: @min-width--medium) {
          display: none;
          pointer-events: none;
        }
      }

      &--desktop-only {
        @media only screen and (max-width: @max-width--small) {
          display: none;
          pointer-events: none;
        }
      }
    }

    .table__cell--cancel {
      button {
        font-size: @font-size__body--2;
        margin-right: @spacing--2;
      }
    }

    th {
      font-size: @font-size__body;
      font-weight: @font-weight--regular;
      color: @color-defaults__text--body;
    }

    th.table__cell--heading {
      font-size: @font-size__body;
      font-weight: @font-weight--bold;
      color: @color-defaults__text--heading;
    }
  }

  .table__body {
    display: block;
    overflow: visible;
    width: 100%;

    .table__row:nth-child(even) {
      background-color: @color-defaults__section--2;

      .table__status__ring {
        border: 4px solid @color-defaults__section--2;
      }
    }

    .table__row:nth-child(odd) {
      background-color: @color-defaults__section--1;

      .table__status__ring {
        border: 4px solid @color-defaults__section--1;
      }
    }

    .table__row {
      border-style: solid;
      border-width: 1px;
      border-color: transparent;
      border-bottom-color: @color-defaults__border;

      .table__cell--mobile-only {
        @media only screen and (max-width: @max-width--small) {
          align-items: flex-start;
          justify-content: left;
          flex-direction: column;
          text-align: left;
        }
      }
    }
  }

  .table__row {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    overflow: visible;
    box-sizing: border-box;

    &--full-width-centered {
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;

      div {
        flex-basis: 100%;
        flex-shrink: 0;
        flex-grow: 1;
        margin: @spacing--1;
      }
    }

    .table__cell {
      border-right: solid 1px transparent;
    }

    .table__cell:last-child {
      border-right: solid 1px transparent;
    }

    .table__cell--active {
      border: 1px solid transparent;
    }

    &:not(.table__row--disabled) {
      .table__cell--active {
        .prevent-highlighting();

        &:hover {
          cursor: pointer;
          border: 1px solid @color-defaults__border;
        }
      }
    }

    &.table__row--disabled {
      .table__cell--active {
        pointer-events: none;

        button {
          pointer-events: none;
          opacity: 0;
        }
      }
    }

    &.table__row--loading {
      pointer-events: none;
      color: transparent;
      text-shadow: 0 0 5px rgba(50, 50, 50, 0.5);

      button {
        pointer-events: none;
      }
    }
  }

  .table__cell__save-edit-separator {
    display: inline-block;
    opacity: 0;
    transform: translateX(60%);
    transition: all 0.2s ease;

    padding-left: @spacing--1;
    padding-right: @spacing--1;
    font-size: @font-size__body--1;
  }

  .table__row--hidden-actions {
    .prevent-highlighting();

    i {
      font-size: @font-size__body--3;
      opacity: 0;
      transform: translateX(60%);
      transition: all 0.2s ease;
      display: inline-block;
    }

    &:not(.table__row--disabled) {
      &:hover {
        border-color: @color-defaults__border--dark;

        i {
          transform: scaleY(1);
          opacity: 1;
          transform: translateX(0%);
        }

        .table__cell__save-edit-separator {
          transform: scaleY(1);
          opacity: 1;
          transform: translateX(0%);
        }

        .table__cell {
          border-right: solid 1px @color-defaults__border--dark;
        }

        .table__cell:last-child {
          border-right: solid 1px transparent;
        }
      }

      @media only screen and (max-width: @max-width--small) {
        i {
          transform: scaleY(1);
          opacity: 1;
          transform: translateX(0%);
        }
      }
    }
  }

  .table__cell {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    padding: @spacing--2;
    width: 0px;
    flex-grow: 1;
    flex-shrink: 1;
    box-sizing: border-box;

    &.table__cell--expanding {
      flex-grow: 3;
    }

    &.table__cell--single-action {
      flex-grow: 0;
      flex-shrink: 0;
      width: calc(@spacing--2 * 2.75);
      padding: 0px;
      align-items: center;
      justify-content: center;
    }

    .table__cell__textbox {
      width: calc(100% - @spacing--5 - @spacing--5);
      max-width: 450px;

      input {
        width: 100%;
        height: calc(@font-size__body--4 + @spacing--2 + @spacing--2);
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: @spacing--2;
        padding-right: @spacing--3;
        font-size: @font-size__body--4;
      }
    }

    .table__cell__edit-action {
      position: absolute;
      right: @spacing--2;
      top: 50%;
      transform: translateY(-50%);
    }

    &.table__cell--mobile-hide {
      @media only screen and (max-width: @max-width--small) {
        display: none;
        pointer-events: none;
      }
    }

    &.table__cell--mobile-only {
      @media only screen and (min-width: @min-width--medium) {
        display: none;
        pointer-events: none;
      }
    }

    &--no-results {
      padding-top: calc(@spacing--5 * 2);
      padding-bottom: calc(@spacing--5 * 2);
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-wrap: wrap;
      flex-basis: 100%;
      flex-shrink: 0;
      flex-grow: 1;
    }
  }

  .table__cell__save-edit-action {
    i {
      font-size: @font-size__body--2;
    }
  }

  .table__foot {
    display: block;
    overflow: visible;
    width: 100%;
    background-color: @color-defaults__section--2;

    .table__row {
      border-bottom: 1px solid @color-defaults__border;
    }

    .table__cell--paging {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      padding: @spacing--2;
      width: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      box-sizing: border-box;
      border: 1px solid transparent;
      border-bottom: 1px solid @color-defaults__border;
      transition: opacity 0.25s linear;

      text-align: right;

      .table__page-link {
        padding: @spacing--1;
        font-size: @font-size__body--4;
        font-weight: @font-weight--semibold;
      }

      .table__paging-icon {
        padding: @spacing--1;
        font-size: @font-size__body--5;
      }

      select {
        height: calc(@font-size__body--4 + @spacing--2 + @spacing--2);
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: @spacing--2;
        padding-right: @spacing--3;
        font-size: @font-size__body--4;
      }

      &.table__cell--disabled {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  .table__sort-icon {
    margin-left: @spacing--1;
    font-size: @font-size__body--3;
  }

  .left-spacing {
    padding-left: @spacing--2;
  }

  .text {
    margin: 0px;
    padding: 0px;

    &--highlight {
      background-color: @color-defaults__text--highlight;
    }
  }

  @keyframes table-status-rotating {
    0% {
      transform: rotate(405deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }

  @-webkit-keyframes table-status-rotating {
    0% {
      transform: rotate(405deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }
</style>
