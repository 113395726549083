<script>
  import Vue from 'vue'
  import BaseContainerNotification from './base-container-notification.vue'
  import ButtonIcon from './button-icon.vue'

  export default {
    components: {
      BaseContainerNotification,
      ButtonIcon,
    },
    props: {
      /** Accent color indicator. 0 (default). 1 (error). 2 (success). */
      accent: {
        required: false,
        type: Number,
        default: 0,
      },
      /** Notification title. */
      title: {
        type: String,
        required: true,
      },
      /** Notification duration in MS. */
      duration: {
        type: Number,
        default: 5000,
      },
    },
    data() {
      return {
        active: false,
        visible: false,
      }
    },
    computed: {
      barDurationStyle() {
        return {
          transition: 'width ' + this.duration / 1000 + 's linear 0s',
        }
      },
    },
    mounted() {
      this.active = false
      this.visible = false

      Vue.nextTick(function() {
        setTimeout(this.show.bind(this))
        setTimeout(this.triggerClose.bind(this), this.duration + 100)
      }, this)
    },
    methods: {
      show() {
        this.visible = true

        Vue.nextTick(function() {
          setTimeout(() => (this.active = true), 100)
        }, this)
      },
      triggerClose: function() {
        this.visible = false

        setTimeout(this.emitDestroy.bind(this), 800)
      },
      emitDestroy() {
        /**
         * Close attempt event.
         * @type {Event}
         */
        this.$emit('destroy')
      },
    },
  }
</script>

<template>
  <base-container-notification :class="[$style.notification, { [$style['notification--show']]: visible }]">
    <div :class="$style.notification__contents">
      <div
        :class="[$style.notification__icon, { [$style['notification__icon--error']]: accent === 1 }, { [$style['notification__icon--success']]: accent === 2 }]"
      >
        <!-- @slot Slot container for holding an icon element. -->
        <slot name="icon" />
      </div>
      <div :class="$style.notification__details">
        <div :class="$style.notification__title">
          {{ title }}
        </div>
        <div :class="$style.notification__description">
          <!-- @slot Slot container for notification description. -->
          <slot name="description" />
        </div>
      </div>
      <div :class="$style.notification__close__container">
        <button-icon :class="$style.notification__close" @click="triggerClose">
          <i class="acuity-icon acuity-icon-times" />
        </button-icon>
      </div>
    </div>
    <div
      :class="[
        $style.notification__progress,
        { [$style['notification__progress--active']]: active },
        { [$style['notification__progress--error']]: accent === 1 },
        { [$style['notification__progress--success']]: accent === 2 },
      ]"
      :style="barDurationStyle"
    ></div>
  </base-container-notification>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .notification {
    opacity: 0;
    transform-origin: top;
    transform: scaleY(0) scaleX(0.96);
    transition: opacity 0.8s ease-out 0s, transform 0.4s ease 0.2s;

    &--show {
      opacity: 1;
      transform: scaleY(1) scaleX(1);
    }
  }

  .notification__contents {
    .flex();
    position: relative;
    align-items: center;
    font-family: @font-family;
  }

  .notification__icon {
    font-size: @font-size__heading--1;
    font-weight: @font-weight--regular;
    color: @color-defaults__text--heading;
    line-height: 0px;
    padding-right: @spacing--2;

    &--error {
      color: @color-defaults__text--error;
    }

    &--success {
      color: @color-defaults__text--success;
    }
  }

  .notification__details {
    padding-right: @spacing--2;

    .notification__title {
      font-size: @font-size__heading--4;
      font-weight: @font-weight--bold;
      color: @color-defaults__text--heading;
    }

    .notification__description {
      color: @color-defaults__text--body;
      font-weight: @font-weight--regular;
      font-size: @font-size__body;
    }
  }

  .notification__close__container {
    position: absolute;
    line-height: 0px;
    top: 0px;
    right: 0px;

    .notification__close {
      font-size: @font-size__body--5;
    }
  }

  .notification__progress {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: @spacing--1;
    background-color: @color-defaults__action;

    &--active {
      width: 0px;
    }

    &--error {
      background-color: @color-defaults__text--error;
    }

    &--success {
      background-color: @color-defaults__text--success;
    }
  }
</style>
