<script>
  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="$style.error">
    <!-- @slot Slot container for tile contents. -->
    <slot />
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .error {
    color: @color-defaults__text--error;
    font-size: @font-size__body--4;
    font-family: @font-family;
    font-weight: @font-weight--regular;
    text-align: left;
    display: inline;
  }
</style>
