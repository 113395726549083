<script>
  import BaseButtonIconLogo from './base-button-icon-logo.vue'

  export default {
    components: {
      BaseButtonIconLogo,
    },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-button-icon-logo :color="'3a5898'" :href="'https://www.facebook.com/AcuityInsuranceCompany'">
    <i class="acuity-icon acuity-icon-facebook-square"></i>
  </base-button-icon-logo>
</template>

<style lang="less" module></style>
