var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:_vm.$style['expandable__title-container']},[_c('button-link',_vm._b({class:_vm.$style.expandable__title,on:{"click":_vm.titleButtonClicked}},'button-link',{
        color: _vm.color,
      },false),[_vm._t("title")],2),_c('button-icon',_vm._b({class:_vm.$style['expandable__indicator-container'],on:{"click":_vm.indicatorButtonClicked}},'button-icon',{
        color: _vm.iconButtonColor,
      },false),[_c('span',{class:_vm.$style.expandable__indicator},[_c('i',{class:['acuity-icon acuity-icon-minus', ( _obj = {}, _obj[_vm.$style.rotate] = _vm.expanded, _obj )]}),_c('i',{class:['acuity-icon acuity-icon-minus', ( _obj$1 = {}, _obj$1[_vm.$style.rotate] = _vm.expanded, _obj$1 )]})])])],1),_c('base-container-collapsable',_vm._b({},'base-container-collapsable',{
      collapsed: _vm.contentExpanded,
    },false),[_c('div',{class:_vm.$style.expandable__content},[_vm._t("content")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }