<script>
  /* TODO: Write unit tests */

  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="$style.menu">
    <!-- @slot Slot container for base-button-menu-action components only. -->
    <slot />
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .menu {
    .prevent-highlighting();
    display: inline-block;
    position: relative;
    margin: 0px 0px 0px 0px;
    padding: @spacing--2 0px;
    width: 175px;

    border-radius: @default-border__radius;
    box-shadow: 0 3px 6px darken(@color-defaults__border--dark, 20%);
    background-color: @color-defaults__section--2;

    text-align: left;
    font-family: @font-family;
    font-size: @font-size__body;
    font-weight: @font-weight--regular;
    color: @color-defaults__text--body;
  }
</style>
