<script>
  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="$style.tooltip">
    <div :class="$style.tooltip__pointer"></div>
    <!-- @slot Slot container for tooltip contents. -->
    <slot />
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .tooltip {
    .prevent-highlighting();
    display: inline-block;
    position: relative;
    margin: 10px 0px 0px 0px;
    padding: @spacing--1 @spacing--1 @spacing--1 @spacing--1;
    min-width: 60px;

    border-radius: @default-border__radius;
    box-shadow: 0 3px 6px darken(@color-defaults__border--dark, 20%);
    background-color: @color-defaults__section--2;

    text-align: center;
    font-family: @font-family;
    font-size: @font-size__body;
    font-weight: @font-weight--regular;
    color: @color-defaults__text--body;

    .tooltip__pointer {
      pointer-events: none;
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);

      width: 40px;
      height: 24px;
      overflow: hidden;
      box-shadow: 0 3px 6px -17px darken(@color-defaults__border--dark, 20%);

      &:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        background: @color-defaults__section--2;
        transform: rotate(45deg);
        top: 18px;
        left: 10px;
        box-shadow: -1px 3px 6px -2px darken(@color-defaults__border--dark, 20%);
      }
    }
  }
</style>
