<script>
  import BaseContainerPromotional from './base-container-promo.vue'
  import ButtonAction from './button-action.vue'
  import ButtonActionConversion from './button-action-conversion.vue'

  export default {
    components: {
      BaseContainerPromotional,
      ButtonAction,
      ButtonActionConversion,
    },
    props: {
      /** Level of importance indicator. 0 conversion promotion. 1 standard promotion. */
      promotionalLevel: {
        required: false,
        type: Number,
        default: 1,
      },
      /** Title of the promotional. */
      title: {
        required: false,
        type: String,
        default: null,
      },
      /** Text content of the primary action button. */
      buttonText: {
        required: false,
        type: String,
        default: null,
      },
      /** Icon class name. Example: acuity-icon-person. */
      icon: {
        required: false,
        type: String,
        default: null,
      },
      /** Allows icon to remain visible on mobile size screens. */
      displayIconOnMobile: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {
      promoIconClassObject: function() {
        return {
          'acuity-icon': true,
          [this.$style.promo__icon]: true,
          [this.$style['promo__icon--white']]: this.promotionalLevel === 0,
          [this.icon]: typeof this.icon === 'string' && this.icon.length > 0,
        }
      },
      basePromotionalBackgroundValue: function() {
        let backgroundIndicator = 1
        if (this.promotionalLevel === 0) {
          backgroundIndicator = 0
        }
        return backgroundIndicator
      },
    },
    methods: {
      emitButtonClick: function(event) {
        /**
         * Event that is triggered on the primary action click.
         * @type {Event}
         */
        this.$emit('buttonClick')
      },
    },
  }
</script>

<template>
  <base-container-promotional
    v-bind="{
      inline: true,
      background: basePromotionalBackgroundValue,
    }"
  >
    <div :class="$style['promo']">
      <div :class="$style.promo__content">
        <div v-if="icon" :class="[$style.promo__icon__container, { [$style['promo__icon__container--m-hide']]: !displayIconOnMobile }]">
          <i :class="promoIconClassObject"></i>
        </div>

        <div :class="$style.promo__text__container">
          <div v-if="title" :class="[$style.promo__title, { [$style['promo__title--white']]: promotionalLevel === 0 }]">
            {{ title }}
          </div>
          <!-- @slot Slot container for content describing this promotion. -->
          <slot />
        </div>
      </div>

      <div v-if="buttonText" :class="$style.promo__button__container">
        <div v-if="promotionalLevel === 0">
          <button-action-conversion @click="emitButtonClick">
            {{ buttonText }}
          </button-action-conversion>
        </div>
        <div v-if="promotionalLevel !== 0">
          <button-action @click="emitButtonClick">
            {{ buttonText }}
          </button-action>
        </div>
      </div>
    </div>
  </base-container-promotional>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .promo {
    text-align: left;
    display: inline-flex;
    vertical-align: center;
    align-content: center;
    align-items: center;
    width: 100%;

    @media only screen and (max-width: @max-width--small) {
      display: inline-flex;
      flex-wrap: wrap;
    }
  }

  .promo__content {
    flex-grow: 1;
    display: inline-flex;
    vertical-align: center;
    align-content: center;
    align-items: center;
  }

  .promo__icon {
    font-size: calc(@font-size__body ~'*' 4);
    color: @color-defaults__text--heading;

    padding-right: @spacing--2;

    &--white {
      color: @color-defaults__text--light;
    }
  }

  .promo__icon__container {
    flex-grow: 0;

    &--m-hide {
      @media only screen and (max-width: @max-width--small) {
        display: none;
      }
    }
  }

  .promo__title {
    font-size: @font-size__heading--3;
    font-weight: @font-weight--bold;
    color: @color-defaults__text--heading;

    &--white {
      color: @color-defaults__text--light;
    }
  }

  .promo__text__container {
    flex-grow: 1;
  }

  .promo__button__container {
    flex-grow: 0;
    padding-left: @spacing--2;

    @media only screen and (max-width: @max-width--small) {
      padding-top: @spacing--2;
      padding-right: @spacing--2;
      flex-grow: 1;
      flex-basis: 100%;
      text-align: center;
    }
  }
</style>
