<script>
  /* TODO: Write unit tests */

  export default {
    components: {},
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {
      emitClick: function(event) {
        if (!this.busy) {
          /**
           * Passthrough click event.
           * @type {Event}
           */
          this.$emit('click')
        }
      },
    },
  }
</script>

<template>
  <button type="button" :class="[$style.button]" @click="emitClick">
    <span>
      <!-- @slot Slot container for action text. -->
      <slot />
    </span>
  </button>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .button {
    .prevent-highlighting();
    text-decoration: none !important;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    font-size: @font-size__body--4;
    font-weight: @font-weight--regular;

    outline: 0;
    display: inline-block;
    box-shadow: none;
    text-shadow: none;
    text-align: left;

    border-radius: 0px;
    padding-top: @spacing--2;
    padding-bottom: @spacing--2;
    padding-left: @spacing--2;
    padding-right: @spacing--2;
    width: 100%;

    color: @color-defaults__text--body;
    border: none;
    background-color: @color-defaults__section--2;
    background: @color-defaults__section--2;

    &:hover,
    &:focus {
      background-color: darken(@color-defaults__section--2, @color-alteration-step-size--1);
      background: darken(@color-defaults__section--2, @color-alteration-step-size--1);
      cursor: pointer;
    }

    &:active {
      background-color: darken(@color-defaults__section--2, @color-alteration-step-size--2);
      background: darken(@color-defaults__section--2, @color-alteration-step-size--2);
      cursor: pointer;
    }

    &:disabled {
      background-color: @color-defaults__disabled !important;
      background: @color-defaults__disabled !important;
      color: @color-defaults__text--placeholder;
      border-color: @color-defaults__disabled;
      cursor: auto;
    }
  }
</style>
