<script>
  export default {
    components: {},
    props: {
      /** Background color indicator. 0 (default). 1 (main conversion). 2 (back/cancel). */
      background: {
        required: false,
        type: Number,
        default: 0,
      },
      /** Busy flag. Set to yes when something the user is waiting on needs to finish before this action can be taken. */
      busy: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      emitClick: function(event) {
        if (!this.busy) {
          /**
           * Passthrough click event.
           * @type {Event}
           */
          this.$emit('click')
        }
      },
    },
  }
</script>

<template>
  <button
    type="button"
    :class="[
      $style.button,
      { [$style['button--conversion']]: background === 1 },
      { [$style['button--back']]: background === 2 },
      { [$style['button--busy']]: busy === true },
    ]"
    @click="emitClick"
  >
    <span v-show="!busy">
      <!-- @slot Slot container for action text. -->
      <slot />
    </span>
    <span v-show="busy">
      <div :class="$style.button__status__container">
        <div
          :class="[
            $style.button__status__spinner,
            { [$style['button__status__spinner--conversion']]: background === 1 },
            { [$style['button__status__spinner--back']]: background === 2 },
          ]"
        ></div>
        <div
          :class="[
            $style.button__status__ring,
            { [$style['button__status__ring--conversion']]: background === 1 },
            { [$style['button__status__ring--back']]: background === 2 },
          ]"
        ></div>
      </div>
    </span>
  </button>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .button {
    .prevent-highlighting();
    text-decoration: none !important;
    overflow: hidden;
    position: relative;
    white-space: nowrap;

    font-size: @font-size__body--4;
    font-weight: @font-weight--semibold;

    outline: 0;
    display: inline-block;
    box-shadow: none;
    text-shadow: none;

    border-radius: calc(@spacing--6 ~'/' 2);
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: calc(@spacing--4 ~'+' @spacing--1);
    padding-right: calc(@spacing--4 ~'+' @spacing--1);
    min-width: calc(150px ~'-' @spacing--4 ~'-' @spacing--1);

    height: @spacing--6;
    line-height: @spacing--6 - @form-item__border--width - @form-item__border--width;

    color: @color-defaults__text--light;
    border: solid @form-item__border--width @color-defaults__action;
    background-color: @color-defaults__action;
    background: @color-defaults__action;

    &:hover,
    &:focus {
      border: solid @form-item__border--width lighten(@color-defaults__action, @color-alteration-step-size--2);
      background-color: lighten(@color-defaults__action, @color-alteration-step-size--2);
      background: lighten(@color-defaults__action, @color-alteration-step-size--2);
      cursor: pointer;
    }

    &:active {
      border: solid @form-item__border--width darken(@color-defaults__action, @color-alteration-step-size--2);
      background-color: darken(@color-defaults__action, @color-alteration-step-size--2);
      background: darken(@color-defaults__action, @color-alteration-step-size--2);
      cursor: pointer;
    }

    &:disabled {
      background-color: @color-defaults__disabled !important;
      background: @color-defaults__disabled !important;
      color: @color-defaults__text--placeholder;
      border-color: @color-defaults__disabled;
      cursor: auto;
    }

    &--conversion {
      color: @color-defaults__text--light;
      border: solid @form-item__border--width @color-defaults__action--conversion;
      background-color: @color-defaults__action--conversion;
      background: @color-defaults__action--conversion;

      &:hover,
      &:focus {
        border: solid @form-item__border--width lighten(@color-defaults__action--conversion, @color-alteration-step-size--2);
        background-color: lighten(@color-defaults__action--conversion, @color-alteration-step-size--2);
        background: lighten(@color-defaults__action--conversion, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:active {
        border: solid @form-item__border--width darken(@color-defaults__action--conversion, @color-alteration-step-size--2);
        background-color: darken(@color-defaults__action--conversion, @color-alteration-step-size--2);
        background: darken(@color-defaults__action--conversion, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:disabled {
        background-color: @color-defaults__disabled !important;
        background: @color-defaults__disabled !important;
        color: @color-defaults__text--placeholder;
        border-color: @color-defaults__disabled;
        cursor: auto;
      }
    }

    &--back {
      color: @color-defaults__text--light;
      border: solid @form-item__border--width @color-defaults__action--back;
      background-color: @color-defaults__action--back;
      background: @color-defaults__action--back;

      &:hover,
      &:focus {
        border: solid @form-item__border--width lighten(@color-defaults__action--back, @color-alteration-step-size--2);
        background-color: lighten(@color-defaults__action--back, @color-alteration-step-size--2);
        background: lighten(@color-defaults__action--back, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:active {
        border: solid @form-item__border--width darken(@color-defaults__action--back, @color-alteration-step-size--2);
        background-color: darken(@color-defaults__action--back, @color-alteration-step-size--2);
        background: darken(@color-defaults__action--back, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:disabled {
        background-color: @color-defaults__disabled !important;
        background: @color-defaults__disabled !important;
        color: @color-defaults__text--placeholder;
        border-color: @color-defaults__disabled;
        cursor: auto;
      }
    }
  }

  .button--busy {
    cursor: auto;
    pointer-events: none;

    background-color: lighten(@color-defaults__action, 40%);
    background: lighten(@color-defaults__action, 40%);

    &.button--conversion {
      background-color: lighten(@color-defaults__action--conversion, 40%);
      background: lighten(@color-defaults__action--conversion, 40%);
    }

    &.button--back {
      background-color: lighten(@color-defaults__action--back, 40%);
      background: lighten(@color-defaults__action--back, 40%);
    }
  }

  .button__status__container {
    display: block;
    position: absolute;
    width: calc(@spacing--1 + @font-size__body--1);
    height: calc(@spacing--1 + @font-size__body--1);
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .button__status__spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: calc(100%);
    height: calc(100%);
    border-radius: 50%;

    &:before {
      position: absolute;
      top: -2px;
      left: -2px;

      width: calc(100% + 4px);
      height: calc(100% + 4px);

      border: 10px solid transparent;
      border-top: 10px solid @color-defaults__action;
      box-sizing: border-box;
      content: '';
      border-radius: 50%;

      animation: button-status-rotating 1s linear infinite;
    }

    &.button__status__spinner--conversion {
      &:before {
        border-top: 10px solid @color-defaults__action--conversion;
      }
    }

    &.button__status__spinner--back {
      &:before {
        border-top: 10px solid @color-defaults__action--back;
      }
    }

    &:after {
      position: absolute;
      top: 4px;
      left: 4px;

      width: calc(100% - 8px);
      height: calc(100% - 8px);

      box-sizing: border-box;
      content: '';
      border-radius: 50%;

      background-color: lighten(@color-defaults__action, 40%);
    }

    &.button__status__spinner--conversion {
      &:after {
        background-color: lighten(@color-defaults__action--conversion, 40%);
      }
    }

    &.button__status__spinner--back {
      &:after {
        background-color: lighten(@color-defaults__action--back, 40%);
      }
    }
  }

  .button__status__ring {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-radius: 50%;
    box-sizing: border-box;
    border: 4px solid lighten(@color-defaults__action, 40%);
    background-color: transparent;

    &.button__status__ring--conversion {
      border: 4px solid lighten(@color-defaults__action--conversion, 40%);
    }

    &.button__status__ring--back {
      border: 4px solid lighten(@color-defaults__action--back, 40%);
    }
  }

  @keyframes button-status-rotating {
    0% {
      transform: rotate(405deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }

  @-webkit-keyframes button-status-rotating {
    0% {
      transform: rotate(405deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }
</style>
