<script>
  export default {
    components: {},
    props: {
      /** Disables hover shadow. */
      disabled: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        forceActive: false,
      }
    },
    computed: {},
    methods: {
      emitClick: function() {
        if (!this.disabled) {
          /**
           * Click event that emits when the use clicks the tile while it is active.
           * @type {Event}
           */
          this.$emit('click')
        }
      },
    },
  }
</script>

<template>
  <div
    :class="[{ [$style['shadow']]: !disabled }, { [$style['shadow--active']]: !disabled && forceActive }]"
    @click="emitClick"
    @keyup.space.prevent="emitClick"
    @keyup.enter.prevent="emitClick"
    @keydown.space="forceActive = true"
    @keydown.enter="forceActive = true"
    @keyup="forceActive = false"
    @keydown.space.prevent=""
    @keydown.enter.prevent=""
  >
    <!-- @slot Slot container for your active content. Content must have a root node of type div. -->
    <slot />
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .shadow {
    > div {
      box-shadow: 0 3px 6px darken(@color-defaults__border--dark, 20%);

      &:hover,
      &:focus {
        transform: scale(1.025);
        box-shadow: 0 3px 10px darken(@color-defaults__border--dark, 30%);
      }

      &:active {
        transform: scale(0.975);
      }
    }
  }

  .shadow--active {
    transform: scale(0.975);
  }
</style>
