<script>
  export default {
    components: {},
    props: {
      /** Background color indicator. 0 (primary). 1 (secondary). */
      background: {
        required: false,
        type: Number,
        default: 1,
      },
      /** Indicator that this promotional is being used inline. */
      inline: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {
      promotionalClassObject: function() {
        return {
          [this.$style.promotional]: true,
          [this.$style['promotional__background--2']]: this.background === 1 || this.background < 0 || this.background > 1,
          [this.$style['promotional__background--1']]: this.background === 0,
          [this.$style['promotional--inline']]: this.inline,
          [this.$style['promotional--section']]: !this.inline,
        }
      },
    },
    methods: {},
  }
</script>

<template>
  <div :class="promotionalClassObject">
    <div :class="$style['promotional__content']">
      <!-- @slot Slot for containing all content for this container. -->
      <slot />
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
  .promotional {
    padding: @spacing--4;
    border-radius: @default-border__radius;
    margin: @spacing--4 @spacing--2 0px @spacing--2;

    &--inline {
      margin: @spacing--4 @spacing--2 0px @spacing--2;
    }

    @media only screen and (max-width: @max-width--small) {
      margin: 0px;
      border-radius: 0px;
      padding: @spacing--4 @spacing--2;

      &--inline {
        border-radius: @default-border__radius;
        margin: @spacing--4 @spacing--2 0px @spacing--2;
      }
    }

    &--section {
      .promotional__content {
        .grid-row__content();
      }
    }
  }

  .promotional__background {
    &--1 {
      background-color: @color-defaults__promo--1;
      color: @color-defaults__text--light;
    }
    &--2 {
      background-color: @color-defaults__promo--2;
      color: @color-defaults__text--body;
    }
  }
</style>
