<script>
  import validators from './validators/validators.js'

  export default {
    // Disable default inherit of attributes so we control where it gets bound
    inheritAttrs: false,
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** v-model value property. */
      value: {
        type: Object,
        default() {
          return {
            inputValue: '',
            isValid: true,
            error: '',
            showError: false,
          }
        },
      },
      /** Type of input charsets the user can utilize. Helps prevent unwanted keystrokes. */
      inputmode: {
        type: String,
        default: 'text',
        // Only allow types that essentially just render text boxes.
        validator(value) {
          return ['text', 'numeric', 'decimal', 'email', 'tel', 'search', 'url'].includes(value)
        },
      },
      /** Type of native input to use. */
      type: {
        type: String,
        default: 'text',
        // Only allow types that essentially just render text boxes.
        validator(value) {
          return ['text', 'number', 'email', 'tel', 'password', 'search', 'url'].includes(value)
        },
      },
      /** Set to true if value entry is required. */
      required: {
        type: Boolean,
        default: false,
      },
      /** Set to true to cause all inputted text to auto capitalize. */
      uppercase: {
        type: Boolean,
        default: false,
      },
      /** Name value for input group items. */
      name: {
        type: String,
        default: null,
      },
      /** Place holder text for when the input is void of a value. */
      placeholder: {
        type: String,
        default: '',
      },
      /** Label for type of prefill that should happen on this field. */
      autocomplete: {
        type: String,
        default: 'none',
      },
      /** Max number of characters this input will accept. */
      maxLength: {
        type: Number,
        default: 200, // Default data table column max size.
      },
      /** Minumum number of characters this input will accept */
      minLength: {
        type: Number,
        default: 0
      },
      /** String of format for input, asterisk signifies wild card character. */
      format: {
        type: String,
        default: null,
      },
      /** Function that accepts model.inputValue and returns a string of length zero if valid, or an error message string if invalid. */
      validator: {
        type: Function,
        default: () => '',
      },
    },
    data() {
      return {
        localInputValue: this.sanitizeValue(this.value.inputValue),
        localShowError: !!this.value.showError,
        displayValue: this.formatValue(this.value.inputValue),
        defaultName: Math.floor(Math.random() * Date.now()) + '',
      }
    },
    computed: {
      nativeInputValue: {
        get() {
          return this.displayValue
        },
        set(value) {
          this.displayValue = value // Set once to the raw HTML provided value, just incase formatting causes this.displayValue to not alter.
          this.displayValue = this.formatValue(value)
          this.localInputValue = this.sanitizeValue(value)
          this.emitUpdatedModel()
        },
      },
      hasBeenInteractedWith: {
        get() {
          return this.localShowError
        },
        set(value) {
          this.localShowError = value
          this.emitUpdatedModel()
        },
      },
      error() {
        let error = this.required ? validators.validateRequired(this.localInputValue) : ''
        if (error.length === 0) {
          if (this.localInputValue.length > 0) {
            error = validators.validateStringLength(this.localInputValue, this.minLength, this.maxLength)
          }
          if (error.length === 0) {
            error = this.validator(this.localInputValue)
          }
        }
        return error
      },
      nativeInputName() {
        return this.name ? this.name : this.defaultName
      },
    },
    watch: {
      value: {
        deep: true,
        handler() {
          if (this.localInputValue !== this.value.inputValue) {
            this.nativeInputValue = this.value.inputValue
          }

          if (this.localShowError !== this.value.showError && this.value.showError !== undefined) {
            this.hasBeenInteractedWith = this.value.showError
          }
        },
      },
      error: {
        handler() {
          this.emitUpdatedModel()
        },
      },
    },
    mounted() {
      this.emitUpdatedModel()
    },
    methods: {
      emitUpdatedModel() {
        /**
         * Event to trigger model syncing.
         * @type {Event}
         */
        this.$emit('update', {
          inputValue: this.localInputValue,
          isValid: this.error.length === 0,
          error: this.error,
          showError: this.localShowError,
        })
      },
      stripInvalidChars(value) {
        value = typeof value === 'string' ? value : ''
        if (['numeric', 'tel'].indexOf(this.inputmode) >= 0) {
          const validChars = '0123456789'
          value = value
            .split('')
            .filter((character) => validChars.indexOf(character) >= 0)
            .join('')
        }

        if (this.format) {
          const invalidChars = this.format
            .split('')
            .filter((character) => character !== '*')
            .join('')
          value = value
            .split('')
            .filter((character) => invalidChars.indexOf(character) === -1)
            .join('')
        }

        if (value.length > this.maxLength) {
          value = value.substring(0, this.maxLength)
        }

        return value
      },
      sanitizeValue(value) {
        value = typeof value === 'string' ? value.trim() : ''
        value = this.stripInvalidChars(value)
        value = this.uppercase ? value.toUpperCase() : value

        const formatString = this.format ? this.format : ''
        return value
          .split('')
          .filter((character) => character === '*' || formatString.indexOf(character) === -1)
          .join('')
      },
      formatValue(value) {
        value = typeof value === 'string' ? value : ''
        let formattedValue = ''
        value = this.stripInvalidChars(value)
        value = this.uppercase ? value.toUpperCase() : value

        if (this.format) {
          let nextInputtedCharIndex = 0
          for (let formatIndex = 0; formatIndex < this.format.length; formatIndex++) {
            if (nextInputtedCharIndex < value.length) {
              const formatChar = this.format.charAt(formatIndex)
              if (formatChar === '*') {
                formattedValue += value.charAt(nextInputtedCharIndex)
                nextInputtedCharIndex++
              } else {
                formattedValue += formatChar
              }
            } else {
              break
            }
          }
        } else {
          formattedValue = value
        }

        return formattedValue
      },
    },
  }
</script>

<template>
  <input
    :type="type"
    :class="$style.input"
    :value="nativeInputValue"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :inputmode="inputmode"
    :name="nativeInputName"
    v-bind="{
      ...$attrs, //Expand out attributes incase we want to override anything
    }"
    v-on="$listeners"
    @input="(e) => (nativeInputValue = e.target.value)"
    @blur="hasBeenInteractedWith = true"
  />
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .input {
    .input-browser-overrides();

    width: 100%;
    box-sizing: border-box;
    height: calc(@form-item__font--size ~'+' 2px);
    border: none;

    padding: 0px;
    margin: 0px;

    color: @color-defaults__text--body;
    background: @form-item__background-color;
    background-color: @form-item__background-color;

    font-size: @form-item__font--size;
    font-weight: @form-item__font--weight;
    font-family: @form-item__font--family;
  }
</style>
