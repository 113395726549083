<script>
  import InputTextboxZip from './input-textbox-zip.vue'
  import BaseFormField from './base-form-field.vue'
  import BaseFormFieldLabel from './base-form-field-label.vue'

  export default {
    components: { InputTextboxZip, BaseFormField, BaseFormFieldLabel },
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** Set to true if a value is required to be entered by the user. */
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-form-field>
    <template :slot="'label'">
      <base-form-field-label :required="required">
        <!-- @slot Slot container for containing label text. -->
        <slot name="label">Zip code</slot>
      </base-form-field-label>
    </template>
    <template :slot="'input'">
      <input-textbox-zip
        v-bind="{
          ...$attrs,
          required: required,
        }"
        v-on="$listeners"
      ></input-textbox-zip>
    </template>
  </base-form-field>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
</style>
