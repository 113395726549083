<script>
  import InputSelect from './input-select.vue'
  import BaseFormField from './base-form-field.vue'
  import BaseFormFieldLabel from './base-form-field-label.vue'

  export default {
    components: { InputSelect, BaseFormField, BaseFormFieldLabel },
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** Set to true if a value is required to be entered by the user. */
      required: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-form-field>
    <template :slot="'label'">
      <base-form-field-label :required="required">
        <!-- @slot Slot container for containing label text. -->
        <slot name="label"></slot>
      </base-form-field-label>
    </template>
    <template :slot="'input'">
      <div :class="$style['form-field__row']">
        <div :class="$style['form-field__column']">
          <input-select
            v-bind="{
              ...$attrs,
              required: required,
            }"
            v-on="$listeners"
          ></input-select>
        </div>
      </div>
    </template>
  </base-form-field>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .form-field__row {
    .grid-row();
  }

  .form-field__column {
    .grid-row__column--6();
  }
</style>
