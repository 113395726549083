const _states = [
  { code: 'AL', name: 'Alabama', personal: false, commercial: false },
  { code: 'AK', name: 'Alaska', personal: false, commercial: false },
  { code: 'AZ', name: 'Arizona', personal: true, commercial: true },
  { code: 'AR', name: 'Arkansas', personal: false, commercial: false },
  { code: 'CA', name: 'California', personal: false, commercial: false },
  { code: 'CO', name: 'Colorado', personal: true, commercial: true },
  { code: 'CT', name: 'Connecticut', personal: false, commercial: false },
  { code: 'DE', name: 'Delaware', personal: false, commercial: false },
  { code: 'DC', name: 'District of Columbia', personal: false, commercial: false },
  { code: 'FL', name: 'Florida', personal: false, commercial: false },
  { code: 'GA', name: 'Georgia', personal: true, commercial: false },
  { code: 'HI', name: 'Hawaii', personal: false, commercial: false },
  { code: 'ID', name: 'Idaho', personal: true, commercial: true },
  { code: 'IL', name: 'Illinois', personal: true, commercial: true },
  { code: 'IN', name: 'Indiana', personal: true, commercial: true },
  { code: 'IA', name: 'Iowa', personal: true, commercial: true },
  { code: 'KS', name: 'Kansas', personal: true, commercial: true },
  { code: 'KY', name: 'Kentucky', personal: false, commercial: true },
  { code: 'LA', name: 'Louisiana', personal: false, commercial: false },
  { code: 'ME', name: 'Maine', personal: true, commercial: true },
  { code: 'MD', name: 'Maryland', personal: false, commercial: false },
  { code: 'MA', name: 'Massachusetts', personal: false, commercial: false },
  { code: 'MI', name: 'Michigan', personal: false, commercial: true },
  { code: 'MN', name: 'Minnesota', personal: true, commercial: true },
  { code: 'MS', name: 'Mississippi', personal: false, commercial: false },
  { code: 'MO', name: 'Missouri', personal: true, commercial: true },
  { code: 'MT', name: 'Montana', personal: true, commercial: true },
  { code: 'NE', name: 'Nebraska', personal: false, commercial: true },
  { code: 'NV', name: 'Nevada', personal: true, commercial: true },
  { code: 'NH', name: 'New Hampshire', personal: false, commercial: true },
  { code: 'NJ', name: 'New Jersey', personal: false, commercial: false },
  { code: 'NM', name: 'New Mexico', personal: true, commercial: true },
  { code: 'NY', name: 'New York', personal: false, commercial: false },
  { code: 'NC', name: 'North Carolina', personal: false, commercial: false },
  { code: 'ND', name: 'North Dakota', personal: true, commercial: true },
  { code: 'OH', name: 'Ohio', personal: true, commercial: true },
  { code: 'OK', name: 'Oklahoma', personal: false, commercial: false },
  { code: 'OR', name: 'Oregon', personal: true, commercial: true },
  { code: 'PA', name: 'Pennsylvania', personal: true, commercial: true },
  { code: 'RI', name: 'Rhode Island', personal: false, commercial: false },
  { code: 'SC', name: 'South Carolina', personal: false, commercial: false },
  { code: 'SD', name: 'South Dakota', personal: true, commercial: true },
  { code: 'TN', name: 'Tennessee', personal: true, commercial: true },
  { code: 'TX', name: 'Texas', personal: true, commercial: true },
  { code: 'UT', name: 'Utah', personal: true, commercial: true },
  { code: 'VT', name: 'Vermont', personal: true, commercial: true },
  { code: 'VA', name: 'Virginia', personal: true, commercial: true },
  { code: 'WA', name: 'Washington', personal: false, commercial: false },
  { code: 'WV', name: 'West Virginia', personal: false, commercial: false },
  { code: 'WI', name: 'Wisconsin', personal: true, commercial: true },
  { code: 'WY', name: 'Wyoming', personal: true, commercial: true },
]

export default {
  stateCodes: _states.map((state) => state.code),
  states: _states,
}
