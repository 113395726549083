<script>
  import ButtonIcon from './button-icon.vue'

  export default {
    components: {
      ButtonIcon,
    },
    props: {
      /** Displays the modal. */
      visible: {
        required: false,
        type: Boolean,
        default: false,
      },
      /** Disables default methods of closing the modal (X button and underlay clicks). */
      disableSystemClose: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      emitClose: function() {
        /**
         * Close request event.
         * @type {Event}
         */
        this.$emit('close')
      },
      xButtonClicked: function() {
        if (!this.disableSystemClose) {
          this.emitClose()
        }
      },
      underlayClicked: function(event) {
        if (!this.disableSystemClose) {
          this.emitClose()
        }
      },
    },
  }
</script>

<template>
  <div v-show="visible">
    <transition name="ac-modal-underlay-fade" mode="out-in">
      <div
        v-if="visible"
        :key="'modal-underlay'"
        ref="underlay"
        :class="[$style['modal__underlay'], { [$style['modal__underlay--disabled']]: disableSystemClose }]"
        @click.self="underlayClicked"
      ></div>
    </transition>

    <transition name="ac-modal-slide-up" mode="out-in">
      <div v-show="visible" :key="'modal'" :class="$style['modal']">
        <button-icon v-if="!disableSystemClose" :class="$style['modal__close-button']" @click="xButtonClicked">
          <i :class="'acuity-icon acuity-icon-times ' + $style['modal__close-button__icon']"></i>
        </button-icon>
        <!-- @slot Slot container for modal content. -->
        <slot />
      </div>
    </transition>
  </div>
</template>

<style>
  .ac-modal-underlay-fade-enter,
  .ac-modal-underlay-fade-leave-to {
    opacity: 0;
  }

  .ac-modal-underlay-fade-enter-active,
  .ac-modal-underlay-fade-leave-active {
    transition: opacity 0.25s ease;
  }

  .ac-modal-slide-up-enter,
  .ac-modal-slide-up-leave-to {
    opacity: 0;
    transform: translate(-50%, 100vh) !important;
  }

  .ac-modal-slide-up-enter-to,
  .ac-modal-slide-up-leave {
    opacity: 1;
    transform: translate(-50%, -50%) !important;
  }

  .ac-modal-slide-up-enter-active {
    transition: opacity 0.25s linear, transform 0.25s ease-out 0.2s;
  }

  .ac-modal-slide-up-leave-active {
    transition: opacity 0.15s linear 0.1s, transform 0.25s ease-in;
  }
</style>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .modal {
    z-index: @default-z-index__modal;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;

    background-color: @color-defaults__promo--2;
    border-radius: @default-border__radius;

    padding: @spacing--4 @spacing--4;
    @media only screen and (max-width: @max-width--small) {
      padding: @spacing--4 @spacing--2;
      background-color: @color-defaults__section--2;
    }

    max-height: calc(100% ~'-' @spacing--2);
    max-width: calc(100% ~'-' @spacing--2);
    @media only screen and (max-width: @max-width--small) {
      width: calc(100% ~'-' @spacing--2 ~'-' @spacing--2);
    }
  }

  .modal__close-button {
    position: absolute;
    right: calc(@spacing--4 ~'/' 2);
    top: calc(@spacing--4 ~'/' 2);
    @media only screen and (max-width: @max-width--small) {
      right: calc(@spacing--4 ~'-' @spacing--1 ~'/' 2);
      top: calc(@spacing--4 ~'-' @spacing--1 ~'/' 2);
    }
  }

  .modal__close-button__icon {
    font-size: @font-size__heading--6;
  }

  .modal__underlay {
    background-color: rgba(
      @color-defaults__underlay--modal-rgb,
      @color-defaults__underlay--modal-rgb,
      @color-defaults__underlay--modal-rgb,
      @color-defaults__underlay--modal-a
    );

    z-index: calc(@default-z-index__modal - 1);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    cursor: pointer;
    &--disabled {
      cursor: default;
    }
  }
</style>
