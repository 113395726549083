<script>
  export default {
    components: {},
    props: {
      /** Set to true if a value is required to be entered by the user for the corresponding form field. */
      required: {
        type: Boolean,
        default: false,
      },
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div :class="[$style.label, { [$style['label--no-pading']]: !required }]">
    <span v-if="required" :class="$style.label__asterisk">*</span>
    <span>
      <!-- @slot Slot container for tile contents. -->
      <slot />
    </span>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .label {
    display: block;
    position: relative;

    width: 100%;
    padding: 0px 0px 0px 10px;

    text-align: left;

    color: @color-defaults__text--heading;
    font-size: @font-size__body;
    font-family: @font-family;
    font-weight: @font-weight--bold;
  }

  .label--no-pading {
    padding: 0px 0px 0px 0px;
  }

  .label__asterisk {
    display: block;
    position: absolute;
    top: 2px;
    left: 0px;

    color: @color-defaults__text--error;
    font-size: @font-size__body;
    font-family: @font-family;
    font-weight: @font-weight--bold;
  }
</style>
