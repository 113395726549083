<script>
  import BaseContainerTileSquare from './base-container-tile-square.vue'
  import BaseActiveAreaHoverShadow from './base-active-area-hover-shadow.vue'
  import ButtonLink from './button-link.vue'
  import Modal from './modal.vue'

  export default {
    components: {
      BaseContainerTileSquare,
      BaseActiveAreaHoverShadow,
      ButtonLink,
      Modal,
    },
    props: {
      /** Icon class name. Example: acuity-icon-person */
      icon: {
        required: false,
        type: String,
        default: null,
      },
      /** Toggle on to hide icon on mobile size screens. */
      hideIconOnMobile: {
        required: false,
        type: Boolean,
        default: false,
      },
      /** Title of the resource the tile is representing. */
      title: {
        required: true,
        type: String,
        default: null,
      },
      /** Creates a link with the entered text to provide a focal point for action. */
      actionText: {
        required: false,
        type: String,
        default: null,
      },
      /** Disables the card so no click events can be emitted. */
      disabled: {
        required: false,
        type: Boolean,
        default: false,
      },
      /** Description text explaining this resource for mobile users. */
      mobileHelpText: {
        required: false,
        type: String,
        default: null,
      },
    },
    data() {
      return {
        learnMoreModalVisible: false,
      }
    },
    computed: {
      showActionText: function() {
        return typeof this.actionText === 'string' && this.actionText.length > 0 && !this.disabled
      },
      iconImage: function() {
        return typeof this.icon === 'string' && this.icon.length > 0 && this.icon.indexOf('acuity-') !== 0
      },
      tileDisabled: function() {
        return (typeof this.actionText === 'string' && this.actionText.length > 0) || this.disabled
      },
      tileTabIndex: function() {
        return this.tileDisabled ? -1 : 0
      },
      tileContentClassObject: function() {
        return {
          [this.$style['resource-tile__content']]: true,
          [this.$style['resource-tile__content--active']]: !this.tileDisabled,
          [this.$style['resource-tile__content--center-content']]: !this.icon,
        }
      },
      tileBodyClassObject: function() {
        return {
          [this.$style['resource-tile__body']]: true,
          [this.$style['resource-tile__body--small']]: this.showActionText && this.icon,
          [this.$style['resource-tile__body--medium']]: !this.showActionText && this.icon,
          [this.$style['resource-tile__body--large']]: this.showActionText && !this.icon,
          [this.$style['resource-tile__body--x-large']]: !this.showActionText && !this.icon,
        }
      },
    },
    methods: {
      emitClick: function() {
        /**
         * Click event that emits when the use clicks the tile while it is active.
         * @type {Event}
         */
        this.$emit('click')
      },
      onLearnMoreContainerClick: function(event) {
        if (event !== undefined && typeof event.preventDefault === 'function') {
          event.preventDefault()
          event.stopPropagation()
        }
      },
      onLearnMoreClick: function() {
        this.learnMoreModalVisible = true
      },
    },
  }
</script>

<template>
  <div :class="$style['resource-tile__wrapper']">
    <base-active-area-hover-shadow
      v-bind="{
        disabled: tileDisabled,
      }"
      @click="emitClick"
    >
      <base-container-tile-square :class="$style['resource-tile']" :tabindex="tileTabIndex">
        <div :class="tileContentClassObject">
          <div v-if="icon" :class="[$style['resource-tile__icon'], { [$style['resource-tile__icon--m-hide']]: hideIconOnMobile }]">
            <img v-if="iconImage" :src="icon" />
            <i v-else :class="['acuity-icon', icon]"></i>
          </div>
          <div :class="tileBodyClassObject">
            <div v-if="title" :class="$style['resource-tile__body--title']">
              {{ title }}
            </div>
            <div :class="$style['resource-tile__body--text']">
              <!-- @slot Slot container for holding description text. -->
              <slot />
            </div>
          </div>
          <div v-if="!showActionText && !disabled && mobileHelpText" :class="$style['resource-tile__help-link-container']" @click="onLearnMoreContainerClick">
            <button-link :class="$style['resource-tile__help-link']" @click="onLearnMoreClick">Learn more</button-link>
          </div>
          <div v-if="showActionText" :class="$style['resource-tile__action-link']">
            <button-link @click="emitClick">{{ actionText }}</button-link>
          </div>
        </div>
      </base-container-tile-square>
    </base-active-area-hover-shadow>

    <modal :title="title" :visible="learnMoreModalVisible" @close="learnMoreModalVisible = false">
      <template v-slot:content>{{ mobileHelpText }}</template>
    </modal>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  @mobile-tile-content-min-height: 50px;
  @mobile-tile-min-height: calc(@mobile-tile-content-min-height + @spacing--2 + @spacing--2);
  @icon-font-size: calc(@font-size__heading--2 ~'*' 2.25);
  @icon-font-size--m: calc(@font-size__heading--3 ~'*' 2);

  .resource-tile {
    outline: none;
    font-size: @font-size__body;
  }

  .resource-tile__wrapper {
    margin-left: @spacing--2;
    margin-right: @spacing--2;
    margin-top: calc(@spacing--2 * 2);

    display: inline-flex;
    vertical-align: bottom;

    @media only screen and (max-width: @max-width--small) {
      display: block;
      box-sizing: border-box;
    }
  }

  .resource-tile__content {
    min-height: @mobile-tile-min-height;
    width: 100%;
    height: 100%;
    outline: none;
    box-sizing: border-box;

    padding: calc(@spacing--2 ~'+' @spacing--1 ~'+' @spacing--1) @spacing--2;
    @media only screen and (max-width: @max-width--small) {
      padding: @spacing--2;
    }

    text-align: center;

    &--active {
      @media only screen and (max-width: @max-width--small) {
        text-align: left;
      }

      cursor: pointer;
      .prevent-highlighting();

      @media only screen and (max-width: @max-width--small) {
        .flex();
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .resource-tile__icon {
        @media only screen and (max-width: @max-width--small) {
          margin: 0px;
          width: @icon-font-size--m;
          display: flex;
          flex-shrink: 0;
        }

        img {
          @media only screen and (max-width: @max-width--small) {
            max-height: @icon-font-size--m;
            max-width: @icon-font-size--m;
            min-height: 0px;
            min-width: 0px;
          }
        }
      }

      .resource-tile__body--text {
        @media only screen and (max-width: @max-width--small) {
          display: none;
        }
      }

      .resource-tile__body--title {
        @media only screen and (max-width: @max-width--small) {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }
    }

    @media only screen and (min-width: @min-width--medium) {
      &--center-content {
        .flex();
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .resource-tile__icon {
    position: relative;
    text-align: center;
    color: @color-defaults__text--heading;

    overflow: hidden;
    margin: 0px auto;
    height: @icon-font-size;
    width: calc(100% ~'-' @spacing--2 ~'-' @spacing--2);
    @media only screen and (max-width: @max-width--small) {
      max-height: @icon-font-size--m;
    }

    font-size: @icon-font-size;
    line-height: @icon-font-size;
    @media only screen and (max-width: @max-width--small) {
      font-size: @icon-font-size--m;
      line-height: @icon-font-size--m;
    }

    img {
      position: absolute;
      max-height: @icon-font-size;
      min-height: @icon-font-size;
      min-width: @icon-font-size;
      @media only screen and (max-width: @max-width--small) {
        max-height: @icon-font-size--m;
        min-height: @icon-font-size--m;
        min-width: @icon-font-size--m;
      }
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--m-hide {
      @media only screen and (max-width: @max-width--small) {
        display: none;
      }
    }
  }

  .resource-tile__body {
    overflow: hidden;

    @media only screen and (min-width: @min-width--medium) {
      &--x-large {
        max-height: calc(100%); // no link or icon
      }

      &--large {
        max-height: calc(100% ~'-' @font-size__body ~'-' @spacing--2); // minus action link height and padding
      }

      &--medium {
        max-height: calc(100% ~'-' @icon-font-size); // minus icon height
      }

      &--small {
        max-height: calc(100% ~'-' @icon-font-size ~'-' @font-size__body ~'-' @spacing--2); // minus action link height/padding and icon height
      }
    }
  }

  .resource-tile__body--title {
    color: @color-defaults__text--heading;
    font-size: @font-size__heading--4;
    font-weight: @font-weight--bold;
    margin-top: @spacing--1;
    margin-bottom: @spacing--1;
    overflow: hidden;

    @media only screen and (min-width: @min-width--medium) {
      max-height: calc(@font-size__heading--3 ~'*' 2);
    }

    @media only screen and (max-width: @max-width--small) {
      font-size: @font-size__heading--4;
      padding-right: @spacing--2;
      padding-left: @spacing--2;
      max-height: inherit;
      overflow: auto;
    }
  }

  .resource-tile__body--text {
    color: @color-defaults__text--body;
  }

  .resource-tile__help-link-container {
    margin-left: auto;
    box-sizing: border-box;
    display: flex;
    align-self: stretch;
    position: relative;
    flex-shrink: 0;
    white-space: nowrap !important;

    &:after {
      content: ' ';
      position: absolute;
      height: calc(100% ~'+' @spacing--2 ~'+' @spacing--2);
      width: 2px;
      background-color: @color-defaults__border;
      top: 50%;
      left: calc(0px);
      transform: translateY(-50%);
    }

    @media only screen and (min-width: @min-width--medium) {
      display: none;
    }
  }

  .resource-tile__help-link {
    box-sizing: border-box;
    display: block;
    padding-right: @spacing--1;
    padding-left: calc(@spacing--2 ~'+' @spacing--1);
    padding-top: calc(@mobile-tile-content-min-height ~'/' 2 ~'-' @font-size__body ~'/' 2);
    padding-bottom: calc(@mobile-tile-content-min-height ~'/' 2 ~'-' @font-size__body ~'/' 2);
  }

  .resource-tile__action-link {
    padding-top: @spacing--2;
  }
</style>
