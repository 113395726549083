<script>
  import BaseModalFullscreen from './base-modal-fullscreen.vue'
  import FormHeading from './form-heading.vue'
  import FormActions from './form-actions.vue'

  export default {
    components: {
      BaseModalFullscreen,
      FormHeading,
      FormActions,
    },
    props: {
      /** Displays the modal. */
      visible: {
        required: false,
        type: Boolean,
        default: false,
      },
      /** Modal title value. */
      title: {
        required: true,
        type: String,
        default: 'Form title',
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      submitButtonClicked: function() {
        /**
         * Form submit event.
         * @type {Event}
         */
        this.$emit('submit')
      },
      emitClose: function() {
        /**
         * Close attempt event.
         * @type {Event}
         */
        this.$emit('close')
      },
    },
  }
</script>

<template>
  <base-modal-fullscreen
    v-bind="{
      visible: visible,
    }"
    @close="emitClose"
  >
    <div :class="$style['page-section__form']">
      <form novalidate="novalidate" onsubmit="return false;">
        <form-heading :title="title"></form-heading>
        <div :class="$style['page-section__content']">
          <!-- @slot Slot container for form input content. -->
          <slot />
        </div>
        <form-actions
          :back-button-text="'Cancel'"
          :submit-button-text="'Submit'"
          @backButtonClick="emitClose"
          @submitButtonClick="submitButtonClicked"
        ></form-actions>
      </form>
    </div>
  </base-modal-fullscreen>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .page-section__form {
    .grid-row__column--8();
  }
</style>
