<script>
  import Vue from 'vue'
  import validators from './validators/validators.js'
  import BaseTextError from './base-text-error.vue'

  export default {
    components: {
      BaseTextError,
    },
    // Disable default inherit of attributes so we control where it gets bound
    inheritAttrs: false,
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** v-model value property. */
      value: {
        type: Object,
        default() {
          return {
            inputValue: [],
            isValid: true,
            error: '',
            showError: false,
          }
        },
      },
      /** Set to true if value entry is required. */
      required: {
        type: Boolean,
        default: false,
      },
      /** Name value for input group items. */
      name: {
        type: String,
        default: null,
      },
      /** List of objects containing a value and a text property to define select options. */
      options: {
        type: Array,
        required: true,
        default: () => [],
        validator(providedOptions) {
          let valid = true
          if (Array.isArray(providedOptions)) {
            for (let i = 0; i < providedOptions.length; i++) {
              const option = providedOptions[i]
              const stringOption = typeof option === 'string'
              const objectOption = option && typeof option.value === 'string' && typeof option.text === 'string'
              valid = !valid ? false : stringOption || objectOption
            }
          } else {
            valid = false
          }
          return valid
        },
      },
    },
    data() {
      return {
        localInputValue: [],
        localShowError: !!this.value.showError,
        displayValue: [],
        defaultName: Math.floor(Math.random() * Date.now()) + '',
        nativeInputFocused: false,
      }
    },
    computed: {
      nativeInputValue: {
        get() {
          return this.displayValue
        },
        set(value) {
          if (Array.isArray(value)) {
            this.displayValue = JSON.parse(JSON.stringify(value))
            this.localInputValue = JSON.parse(JSON.stringify(value))
          } else {
            this.displayValue = []
            this.localInputValue = []
          }

          this.emitUpdatedModel()
        },
      },
      hasBeenInteractedWith: {
        get() {
          return this.localShowError
        },
        set(value) {
          this.localShowError = value
          this.emitUpdatedModel()
        },
      },
      error() {
        return this.required ? validators.validateRequired(this.localInputValue) : ''
      },
      displayError() {
        return this.localShowError ? this.error : ''
      },
      nativeInputName() {
        return this.name ? this.name : this.defaultName
      },
      parsedOptions() {
        return this.options.map((option) => (typeof option === 'string' ? { value: option, text: option } : option))
      },
      isFocused() {
        return this.nativeInputFocused
      },
    },
    watch: {
      value: {
        deep: true,
        handler() {
          if (this.localInputValue !== this.value.inputValue) {
            this.nativeInputValue = this.value.inputValue
          }

          if (this.localShowError !== this.value.showError && this.value.showError !== undefined) {
            this.hasBeenInteractedWith = this.value.showError
          }
        },
      },
      error: {
        handler() {
          this.emitUpdatedModel()
        },
      },
    },
    mounted() {
      this.nativeInputValue = this.value.inputValue
    },
    methods: {
      emitUpdatedModel() {
        /**
         * Event to trigger model syncing.
         * @type {Event}
         */
        this.$emit('update', {
          inputValue: this.localInputValue,
          isValid: this.error.length === 0,
          error: this.error,
          showError: this.localShowError,
        })
      },
      getValueForValue(value) {
        const match = this.parsedOptions ? this.parsedOptions.find((option) => option.value === value) : undefined
        return match ? match.value : ''
      },
      focused() {
        this.nativeInputFocused = true
      },
      blurred() {
        this.nativeInputFocused = false

        Vue.nextTick(() => {
          setTimeout(() => {
            Vue.nextTick(() => {
              if (!this.nativeInputFocused) {
                this.hasBeenInteractedWith = true
              }
            })
          }, 200)
        })
      },
      revertFocusToLabel(value) {
        document.getElementById(this.nativeInputName + '__' + value + '__ac__checkbox__label').focus()
      },
      toggleValue(toggledValue) {
        const newNativeInputValue = this.nativeInputValue.map((value) => value)
        toggledValue = this.getValueForValue(toggledValue)

        if (toggledValue.length > 0) {
          if (newNativeInputValue.indexOf(toggledValue) === -1) {
            newNativeInputValue.push(toggledValue)
          } else {
            newNativeInputValue.splice(newNativeInputValue.indexOf(toggledValue), 1)
          }
          this.nativeInputValue = newNativeInputValue
        }
      },
      labelTextSpanClicked(value) {
        this.revertFocusToLabel(value)
        this.toggleValue(value)
      },
    },
  }
</script>

<template>
  <div>
    <div :class="$style.control">
      <div v-for="option in parsedOptions" :key="option.value" :class="$style.control__checkbox">
        <input
          :id="nativeInputName + '__' + option.value + '__ac__checkbox'"
          v-model="nativeInputValue"
          :value="option.value"
          :type="'checkbox'"
          :name="nativeInputName"
          :tabindex="-1"
          :class="[$style.control__checkbox__input, { [$style['control__checkbox__input--error']]: displayError }, 'acuity-icon']"
          v-bind="{
            ...$attrs, //Expand out attributes incase we want to override anything
          }"
          v-on="$listeners"
          @focus="revertFocusToLabel(option.value)"
        />
        <label
          :id="nativeInputName + '__' + option.value + '__ac__checkbox__label'"
          :for="nativeInputName + '__' + option.value + '__ac__checkbox'"
          :tabindex="0"
          :class="[[$style.control__checkbox__label], 'acuity-icon']"
          @keydown.space.prevent="focused"
          @keydown.enter.prevent="focused"
          @keyup.space.prevent="toggleValue(option.value)"
          @keyup.enter.prevent="toggleValue(option.value)"
          @blur="blurred"
          @focus="focused"
        >
        </label>
        <span v-show="option.text && option.text.length > 0" :class="$style.control__checkbox__label__text" @click="labelTextSpanClicked(option.value)">
          {{ option.text }}
        </span>
      </div>
    </div>

    <base-text-error v-if="displayError">
      {{ displayError }}
    </base-text-error>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  @checkbox__height: @form-item__height * 0.8;

  .control {
    width: 100%;
    box-sizing: border-box;
    display: block;
    padding: 0px;
    margin: 0px;
    position: relative;
  }

  .control__checkbox {
    .flex();
    padding: 0px;
    margin: 0px;
    justify-content: start;
    align-items: center;
  }

  .control__checkbox__label {
    position: relative;
    display: inline-flex;
    flex-shrink: 0;
    box-sizing: border-box;
    width: calc(@checkbox__height / 2);
    height: calc(@checkbox__height / 2);
    background: @color-defaults__section--2;
    border: calc(@default-border__width * 2) solid @color-defaults__border;
    border-radius: 3px;
    outline: none;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      border-color: @color-defaults__action;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__action, 40%);
    }

    &:before {
      content: ' ';
      position: absolute;
      box-sizing: border-box;
      width: calc(100% + @default-border__width + @default-border__width);
      height: calc(100% + @default-border__width + @default-border__width);
      top: -@default-border__width;
      left: -@default-border__width;
      background: @color-defaults__section--2;
      border: 0px solid transparent;
      border-radius: 3px;
    }
  }

  .control__checkbox__label__text {
    .prevent-highlighting();
    display: inline-flex;
    padding: calc(@spacing--1 + @spacing--1) 0px calc(@spacing--1 + @spacing--1) @spacing--2;
    &:hover {
      cursor: pointer;
    }
  }

  .control__checkbox__input {
    .input-browser-overrides();
    display: inline-flex;
    width: 0px;
    flex-grow: 0;
    flex-shrink: 10;
    border: 0px transparent;

    &:checked {
      + label {
        &:before {
          background: @color-defaults__selected;
        }

        &:after {
          font-size: @font-size__body--5;
          font-weight: @font-weight--regular;
          color: @color-defaults__text--body;

          content: '\e618';
          position: absolute;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          background: transparent;
          border: 0px solid transparent;
          color: @color-defaults__text--light;
        }
      }
    }

    &--error {
      + label {
        border-color: @color-defaults__error;

        &:focus {
          border-color: @color-defaults__error;
          box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__error, 40%);
        }
      }
    }
  }
</style>
