<script>
  import validators from './validators/validators.js'
  import fileUploadAPI from './api/FileUploadAPI.js'
  import BaseTextError from './base-text-error.vue'
  import ButtonLink from './button-link.vue'

  export default {
    components: {
      BaseTextError,
      ButtonLink,
    },
    // Disable default inherit of attributes so we control where it gets bound
    inheritAttrs: false,
    // Change the v-model event name to `update` to avoid changing
    // the behavior of the native `input` event.
    // https://vuejs.org/v2/guide/components-custom-events.html#Customizing-Component-v-model
    model: {
      event: 'update',
    },
    props: {
      /** v-model value property. */
      value: {
        type: Object,
        default() {
          return {
            inputValue: [],
            isValid: true,
            isBusy: false,
            error: '',
            showError: false,
          }
        },
      },
      /** Set to true if value entry is required. */
      required: {
        type: Boolean,
        default: false,
      },
      /** List of valid file extensions in lowercase type. */
      validFileExtensions: {
        type: Array,
        default: () => ['jpg', 'jpeg', 'jpe', 'png', 'bmp', 'tif', 'tiff', 'gif', 'pdf', 'doc', 'docx', 'rtf', 'txt'],
      },
      /** Max size of any individual attachment in MBs. */
      maxFileSizeInMB: {
        type: Number,
        default: 15,
        // Must be between 0MB (exclusive) and 15MB (inclusive).
        validator(value) {
          return value !== null && value !== undefined && value > 0 && value <= 15
        },
      },
      /** Max cummulative size of attachments in MBs. */
      maxSizeInMB: {
        type: Number,
        default: 150,
        // Must be between 0MB (exclusive) and 150MB (inclusive).
        validator(value) {
          return value !== null && value !== undefined && value > 0 && value <= 150
        },
      },
      /** Max number of files that will be accepted. */
      maxFileCount: {
        type: Number,
        default: 10,
      },
      /** Name value for input items. */
      name: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        localInputValue: [],
        localShowError: this.value.showError,
        defaultID: Math.floor(Math.random() * Date.now()) + '',
        defaultName: Math.floor(Math.random() * Date.now()) + '',
        isDragging: false,
        isHovering: false,
      }
    },
    computed: {
      hasBeenInteractedWith: {
        get() {
          return this.localShowError
        },
        set(value) {
          this.localShowError = value
          this.emitUpdatedModel()
        },
      },
      error() {
        let error = ''

        // Failed uploads check.
        if (this.localInputValue) {
          for (let index = 0; index < this.localInputValue.length; index++) {
            if (this.localInputValue[index].uploadFailed) {
              error = 'Upload failed. Retry or remove failed item.'
            }
          }
        }

        // Max upload size check.
        if (this.localInputValue) {
          let totalSize = 0
          for (let index = 0; index < this.localInputValue.length; index++) {
            totalSize = totalSize + this.localInputValue[index].size / 1024 / 1024
          }
          if (this.maxSizeInMB < totalSize) {
            if (this.maxSizeInMB > 1) {
              error = 'Max upload size of ' + this.maxSizeInMB + ' MB exceeded.'
            } else {
              error = 'Max upload size of ' + this.maxSizeInMB * 1024 + ' KB exceeded.'
            }
          }
        }

        // File count check.
        if (this.localInputValue) {
          if (this.localInputValue.length > this.maxFileCount) {
            error = 'Max file count of ' + this.maxFileCount + ' exceeded.'
          }
        }

        // Required check.
        if (this.required) {
          const requiredMessage = validators.validateRequired(this.localInputValue)
          error = requiredMessage.length > 0 ? requiredMessage : error
        }

        return error
      },
      busy() {
        let busy = false

        if (this.localInputValue != null && Array.isArray(this.localInputValue)) {
          this.localInputValue.forEach(function(file) {
            if (file.uploading) {
              busy = true
            }
          })
        }

        return busy
      },
      displayError() {
        return this.localShowError ? this.error : ''
      },
      nativeInputName() {
        return this.name ? this.name : this.defaultName
      },
      nativeInputID() {
        return this.defaultID
      },
      isMobile() {
        const userAgent = navigator.userAgent
        return (
          userAgent.match(/Android/i) != null ||
          userAgent.match(/BlackBerry/i) != null ||
          userAgent.match(/iPhone/i) != null ||
          userAgent.match(/Opera Mini/i) != null ||
          userAgent.match(/IEMobile/i) != null
        )
      },
      dragAndDropCapable() {
        var div = document.createElement('div')
        return ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
      },
      fileareaClassObject() {
        return {
          [this.$style.filearea]: true,
          [this.$style['filearea--error']]: this.displayError,
          [this.$style['filearea--hoverable']]: this.isDragging,
          [this.$style['filearea--active-hover']]: this.isDragging && this.isHovering,
        }
      },
    },
    watch: {
      error: {
        handler() {
          this.emitUpdatedModel()
        },
      },
    },
    mounted() {
      window.addEventListener('dragover', this.dragover.bind(this), { capture: false, passive: false })
      window.addEventListener('dragenter', this.dragenter.bind(this), { capture: false, passive: true })

      window.addEventListener('dragleave', this.dragleave.bind(this), { capture: false, passive: true })
      window.addEventListener('dragend', this.dragend.bind(this), { capture: false, passive: false })
      window.addEventListener('drop', this.drop.bind(this), { capture: false, passive: false })

      this.localInputValue = this.value.inputValue
      this.localShowError = this.value.showError
    },
    destroy() {
      window.removeEventListener('dragover', this.dragover.bind(this), { capture: false, passive: false })
      window.removeEventListener('dragenter', this.dragenter.bind(this), { capture: false, passive: true })

      window.removeEventListener('dragleave', this.dragleave.bind(this), { capture: false, passive: true })
      window.removeEventListener('dragend', this.dragend.bind(this), { capture: false, passive: false })
      window.removeEventListener('drop', this.drop.bind(this), { capture: false, passive: false })
    },
    methods: {
      emitUpdatedModel() {
        /**
         * Event to trigger model syncing.
         * @type {Event}
         */
        this.$emit('update', {
          inputValue: this.localInputValue,
          isValid: this.error ? this.error.length === 0 : true,
          isBusy: this.busy,
          error: this.error,
          showError: this.localShowError,
        })
      },
      browseFiles() {
        document.getElementById(this.nativeInputID).click()
      },
      filesSelected() {
        if (document.getElementById(this.nativeInputID).value) {
          this.addFiles(document.getElementById(this.nativeInputID).files, true)
          document.getElementById(this.nativeInputID).value = ''
        }
      },
      addFiles(files, focus) {
        this.hasBeenInteractedWith = true

        for (let i = 0; i < files.length; i++) {
          const file = files[i]

          var fileSizeText = ''
          if (file.size > 1024 * 1024) {
            fileSizeText = (file.size / 1024 / 1024).toFixed(2) + ' MB'
          } else {
            fileSizeText = (file.size / 1024).toFixed(2) + ' KB'
          }

          file.displayDescription = file.name + '  (' + fileSizeText + ')'

          this.validateFile(file)

          if (!file.id && !file.uploading) {
            file.id = null
            file.internalName = null
            file.canBeRetried = false
            file.uploadFailed = false
            file.uploading = false

            if (!file.uploadErrorMessage) {
              this.uploadFile(file)
            } else {
              file.uploadFailed = true
            }
          }

          this.localInputValue.push(file)
        }

        if (focus === true) {
          this.$refs.filearea.focus()
        }

        this.emitUpdatedModel()
      },
      removeFile(file) {
        this.localInputValue.splice(this.localInputValue.indexOf(file), 1)
        this.$refs.filearea.focus()
        this.emitUpdatedModel()

        for (let index = 0; index < this.localInputValue.length; index++) {
          if (this.localInputValue[index].uploadFailed && !this.localInputValue[index].canBeRetried) {
            this.validateFile(this.localInputValue[index])
            if (!this.localInputValue[index].uploadErrorMessage) {
              this.retryFileUpload(this.localInputValue[index])
            }
          }
        }
      },
      dragenter(e) {
        this.isDragging = true

        if (this.isContainedInFileInput(e.target)) {
          this.isHovering = true
        } else {
          this.isHovering = false
        }
      },
      dragleave(e) {
        this.isDragging = false

        if (this.isContainedInFileInput(e.target)) {
          this.isHovering = false
        }
      },
      dragover(e) {
        this.isDragging = true

        if (this.isContainedInFileInput(e.target)) {
          this.isHovering = true
          e.dataTransfer.effectAllowed = 'all'
          e.stopPropagation()
        } else {
          this.isHovering = false
          e.dataTransfer.effectAllowed = 'none'
          e.dataTransfer.dropEffect = 'none'
        }

        e.preventDefault()
      },
      dragend(e) {
        this.isDragging = false
        this.isHovering = false
      },
      drop(e) {
        this.isDragging = false
        this.isHovering = false

        if (this.isContainedInFileInput(e.target)) {
          if (e.dataTransfer && e.dataTransfer.files) {
            this.addFiles(e.dataTransfer.files, true)
            e.stopPropagation()
          }
        }

        e.preventDefault()
      },
      validateFile(file) {
        const validFileExtension =
          this.validFileExtensions.indexOf(
            file.name
              .split('.')
              .slice(-1)[0]
              .toLowerCase()
          ) !== -1

        const fileExceedsMaxFileSize = file.size / 1024 / 1024 > this.maxFileSizeInMB

        const maxIndex = this.localInputValue.indexOf(file) > -1 ? this.localInputValue.indexOf(file) + 1 : this.localInputValue.length
        let totalSize = 0
        for (let index = 0; index < maxIndex; index++) {
          totalSize = totalSize + this.localInputValue[index].size / 1024 / 1024
        }

        if (this.localInputValue.indexOf(file) === -1) {
          totalSize = totalSize + file.size / 1024 / 1024
        }

        let fileError = null
        if (!validFileExtension) {
          fileError = 'Unsupported file type.'
        } else if (fileExceedsMaxFileSize) {
          if (this.maxFileSizeInMB > 1) {
            fileError = 'Max file size of ' + this.maxFileSizeInMB + ' MB exceeded.'
          } else {
            fileError = 'Max file size of ' + this.maxFileSizeInMB * 1024 + ' KB exceeded.'
          }
        } else if (this.maxSizeInMB < totalSize) {
          if (this.maxSizeInMB > 1) {
            fileError = 'Max upload size of ' + this.maxSizeInMB + ' MB exceeded.'
          } else {
            fileError = 'Max upload size of ' + this.maxSizeInMB * 1024 + ' KB exceeded.'
          }
        } else if (this.localInputValue.indexOf(file) === -1 && this.localInputValue.length >= this.maxFileCount) {
          fileError = 'Max file count of ' + this.maxFileCount + ' exceeded.'
        } else if (this.localInputValue.indexOf(file) > -1 && this.localInputValue.indexOf(file) > this.maxFileCount - 1) {
          fileError = 'Max file count of ' + this.maxFileCount + ' exceeded.'
        }

        file.uploadErrorMessage = fileError
      },
      retryFileUpload(file) {
        this.uploadFile(file)

        this.$set(this.localInputValue, this.localInputValue.indexOf(file), file)
        this.emitUpdatedModel()
      },
      uploadFile(file) {
        if (file.id) {
          return
        }

        file.uploading = true
        file.uploadFailed = false
        file.uploadErrorMessage = null
        file.id = null
        file.internalName = null
        file.canBeRetried = false

        fileUploadAPI
          .postFile(file)
          .then(
            function(response) {
              if (file && this.localInputValue.indexOf(file) > -1) {
                file.id = response.parsed.files[0].id
                file.internalName = response.parsed.files[0].name
              }
            }.bind(this)
          )
          .failure(
            function(response) {
              if (file && this.localInputValue.indexOf(file) > -1) {
                file.uploadFailed = true
                file.uploadErrorMessage = response && response.status === 503 ? 'Upload rejected. Unable to read file data.' : 'Upload failed'
                file.canBeRetried = true
              }
            }.bind(this)
          )
          .finally(
            function() {
              if (file && this.localInputValue.indexOf(file) > -1) {
                file.uploading = false
                this.$set(this.localInputValue, this.localInputValue.indexOf(file), file)
                this.emitUpdatedModel()
              }
            }.bind(this)
          )
      },
      isContainedInFileInput(target) {
        while (target) {
          if (target === this.$refs.filearea) {
            return true
          }

          target = target.parentElement
        }

        return false
      },
    },
  }
</script>

<template>
  <div>
    <div
      ref="filearea"
      :class="fileareaClassObject"
      tabindex="0"
      @blur="hasBeenInteractedWith = true"
      @keyup.space.prevent="browseFiles"
      @keyup.enter.prevent="browseFiles"
      @keydown.space.prevent=""
      @keydown.enter.prevent=""
    >
      <input
        :id="nativeInputID"
        :class="$style.filearea__input"
        :name="nativeInputName"
        :type="'file'"
        multiple
        tabindex="-1"
        v-bind="{
          ...$attrs, //Expand out attributes incase we want to override anything
        }"
        v-on="$listeners"
        @change="filesSelected"
      />

      <div :class="$style.filearea__label__container">
        <span :class="$style['filearea__label']">
          <span :class="[$style['filearea__label--disabled'], $style['filearea__label--icon']]"><i :class="'acuity-icon acuity-icon-upload'"></i></span>
          <br />
          <span :class="$style['filearea__label--disabled']">Drag more files here or </span>
          <label tabindex="-1" :for="nativeInputID">
            <button-link tabindex="-1" @click="browseFiles">browse</button-link>
          </label>
          <br />
          <span v-if="maxFileSizeInMB && maxFileSizeInMB >= 1" :class="[$style['filearea__label--sub'], $style['filearea__label--disabled']]">
            No individual file size can exceed {{ maxFileSizeInMB }} MB
          </span>
          <span v-if="maxFileSizeInMB && maxFileSizeInMB < 1" :class="[$style['filearea__label--sub'], $style['filearea__label--disabled']]">
            No individual file size can exceed {{ maxFileSizeInMB * 1024 }} KB
          </span>
          <br />
          <span :class="[$style['filearea__label--sub'], { [$style['hide']]: true }, $style['filearea__label--disabled']]">
            Supported files:
            <span v-for="fileExtension in validFileExtensions" :key="fileExtension">
              {{ fileExtension }}
            </span>
          </span>
        </span>
      </div>

      <div v-if="localInputValue && localInputValue.length > 0" :class="$style.filearea__placeholder__container">
        <div
          v-for="(file, index) in localInputValue"
          :key="file.uploading ? index + '-placeholder-' + Math.random() : index + '-placeholder'"
          :class="$style.filearea__placeholder"
        >
          <div v-show="!file.uploading && !file.uploadFailed" :class="$style.filearea__placeholder__status__success">
            <i :class="'acuity-icon acuity-icon-checkmark'"></i>
          </div>
          <div v-show="file.uploadFailed" :class="$style.filearea__placeholder__status__failure">
            <i :class="'acuity-icon acuity-icon-warning'"></i>
          </div>
          <div v-show="file.uploading" :class="$style.filearea__placeholder__status__spinner"></div>
          <div v-show="file.uploading" :class="$style.filearea__placeholder__status__ring"></div>
          <span :class="$style.filearea__placeholder__description">{{ file.displayDescription }}</span>
          <div :class="$style.filearea__placeholder__controls__container">
            <button-link v-if="file.uploadFailed && file.canBeRetried" :class="$style.filearea__placeholder__control" @click="retryFileUpload(file)">
              Retry
            </button-link>
            <button-link :class="$style.filearea__placeholder__control" :color="file.uploadFailed ? 4 : 0" @click="removeFile(file)">
              <span v-show="file.uploading">Cancel</span>
              <span v-show="!file.uploading">Remove</span>
            </button-link>
          </div>
          <div :class="$style.filearea__placeholder__progress">
            <transition name="ac-progress-expand" appear>
              <div v-if="!file.uploading" :key="index + '-placeholder-progress-indicator'" :class="$style.filearea__placeholder__progress__indicator"></div>
            </transition>
            <div v-show="file.uploadFailed" :class="$style.filearea__placeholder__progress__cancelled"></div>
          </div>
          <div v-show="file.uploadErrorMessage" :class="$style.filearea__placeholder__errormessage">{{ file.uploadErrorMessage }}</div>
        </div>
      </div>
    </div>

    <base-text-error v-if="displayError">
      {{ displayError }}
    </base-text-error>
  </div>
</template>

<style>
  .ac-progress-expand-enter,
  .ac-progress-expand-leave-to {
    max-width: 0px;
  }

  .ac-progress-expand-enter-to,
  .ac-progress-expand-leave {
    max-width: 2000px;
  }

  .ac-progress-expand-enter-active {
    transition: max-width 1s ease-in;
  }

  .ac-progress-expand-leave-active {
    transition: none;
  }
</style>

<style lang="less" module>
  @import (reference) '../design/index.less';

  @filearea__height: calc(@font-size__heading--1 * 2 * 0.75 + @spacing--2 + @spacing--2 + @font-size__heading--4 + @font-size__body);

  .filearea {
    .input-browser-overrides();

    width: 100%;
    box-sizing: border-box;
    border: @default-border__width solid @color-defaults__border;
    border-radius: @default-border__radius;

    padding: @spacing--2 @spacing--3;
    margin: 0px;

    color: @color-defaults__text--body;
    background: @form-item__background-color;
    background-color: @form-item__background-color;

    text-align: center;
    font-size: @form-item__font--size;
    font-weight: @form-item__font--weight;
    font-family: @form-item__font--family;

    &:focus {
      border-color: @color-defaults__action;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__action, 40%);
    }
  }

  .filearea--error {
    border-color: @color-defaults__error;
    &:focus {
      border-color: @color-defaults__error;
      box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__error, 40%);
    }
  }

  .filearea--hoverable {
    border: @default-border__width dashed @color-defaults__border;
  }

  .filearea--active-hover {
    border-color: @color-defaults__action;
    box-shadow: 0px 0px 7px 0px fadeout(@color-defaults__action, 40%);
  }

  .filearea__input {
    display: none;
  }

  .filearea__label__container {
    position: relative;
    height: calc(@filearea__height);
  }

  .filearea__label {
    .prevent-highlighting();

    font-size: @font-size__heading--4;
    color: @color-defaults__text--heading;
    font-weight: @font-weight--bold;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    .filearea__label--sub {
      font-size: @font-size__body;
      color: @color-defaults__text--body;
      font-weight: @font-weight--regular;

      @media only screen and (max-width: @max-width--small) {
        font-size: @font-size__body--4;
      }
    }

    @media only screen and (max-width: @max-width--small) {
      font-size: @font-size__body--2;
    }
  }

  .hide {
    display: none;
  }

  .filearea__label--disabled {
    pointer-events: none;
  }

  .filearea__label--icon {
    height: calc(@font-size__heading--1 * 2 * 0.75);
    width: calc(@font-size__heading--1 * 2);
    padding: 0px;
    margin: 0px;

    i {
      font-size: calc(@font-size__heading--1 * 2);
      height: calc(@font-size__heading--1 * 2 * 0.75);
      line-height: calc(@font-size__heading--1 * 2 * 0.75);
      padding: 0px;
      margin: 0px;
    }
  }

  .filearea__placeholder__container {
    .grid-row();
    .prevent-highlighting();
    text-align: left;
    box-sizing: border-box;
    width: 100%;
  }

  .filearea__placeholder {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: relative;
    box-sizing: border-box;
    padding: @spacing--3 @spacing--1 0px calc(@spacing--1 + @spacing--1 + @font-size__body--1 + 4px);

    font-size: @font-size__body--4;
    font-weight: @font-weight--regular;
    font-family: @font-family;

    display: block;
    width: 100%;
  }

  .filearea__placeholder__controls__container {
    position: absolute;
    right: calc(@spacing--1 + 1px);
    top: calc(@spacing--3 + @font-size__body);
    transform: translate(0%, -80%);
    font-size: @font-size__body--5;
    font-weight: @font-weight--regular;
    float: right;
    padding-top: @default-border__width;
  }

  .filearea__placeholder__control {
    margin-left: @spacing--2;
  }

  .filearea__placeholder__status__success {
    position: absolute;
    top: calc(@spacing--3 + @font-size__body + 10px);
    left: calc(calc(@spacing--1 + @font-size__body--1 + 4px) / 2 - 1px);
    transform: translate(-50%, -100%);

    width: calc(@spacing--1 + @font-size__body--1);
    height: calc(@spacing--1 + @font-size__body--1);
    padding: 0px;
    margin: 0px;

    i {
      font-size: calc(@spacing--1 + @font-size__body--1);
      line-height: calc(@spacing--1 + @font-size__body--1);
      padding: 0px;
      margin: 0px;
      color: @color-defaults__success;
    }
  }

  .filearea__placeholder__status__failure {
    position: absolute;
    top: calc(@spacing--3 + @font-size__body + 10px);
    left: calc(calc(@spacing--1 + @font-size__body--1 + 4px) / 2 - 1px);
    transform: translate(-50%, -100%);

    width: calc(@spacing--1 + @font-size__body--1);
    height: calc(@spacing--1 + @font-size__body--1);
    padding: 0px;
    margin: 0px;

    i {
      font-size: calc(@spacing--1 + @font-size__body--1);
      line-height: calc(@spacing--1 + @font-size__body--1);
      padding: 0px;
      margin: 0px;
      color: @color-defaults__error;
    }
  }

  .filearea__placeholder__errormessage {
    font-size: @font-size__body;
    color: @color-defaults__error;
    padding-top: @spacing--1;

    @media only screen and (max-width: @max-width--small) {
      font-size: @font-size__body--4;
    }
  }

  .filearea__placeholder__status__spinner {
    position: absolute;
    top: calc(@spacing--3 + @font-size__body + 10px);
    left: calc(calc(@spacing--1 + @font-size__body--1 + 4px) / 2 - 1px);
    transform: translate(-50%, -100%);

    width: calc(@spacing--1 + @font-size__body--1);
    height: calc(@spacing--1 + @font-size__body--1);
    border-radius: 50%;

    &:before {
      position: absolute;
      top: -2px;
      left: -2px;

      width: calc(100% + 4px);
      height: calc(100% + 4px);

      border: 10px solid @color-defaults__text--placeholder;
      border-top: 10px solid @color-defaults__action;
      box-sizing: border-box;
      content: '';
      border-radius: 50%;

      animation: file-input-status-rotating 1s linear infinite;
    }

    &:after {
      position: absolute;
      top: 4px;
      left: 4px;

      width: calc(100% - 8px);
      height: calc(100% - 8px);

      box-sizing: border-box;
      content: '';
      border-radius: 50%;

      background-color: @color-defaults__section--2;
    }
  }

  .filearea__placeholder__status__ring {
    position: absolute;
    top: calc(@spacing--3 + @font-size__body + 10px - @spacing--1 - @font-size__body--1 - 4px);
    left: calc(calc(@spacing--1 + @font-size__body--1 + 4px) / 2 - 1px);
    transform: translate(-50%, 0%);

    width: calc(@spacing--1 + @font-size__body--1 + 8px);
    height: calc(@spacing--1 + @font-size__body--1 + 8px);
    border-radius: 50%;
    box-sizing: border-box;
    border: 4px solid @color-defaults__section--2;
    background-color: transparent;
  }

  .filearea__placeholder__progress {
    position: relative;
    width: 100%;
    margin-top: 2px;
    height: 4px;
    border-radius: 2px;
    background-color: @color-defaults__text--placeholder;
  }

  .filearea__placeholder__progress__cancelled {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: @color-defaults__text--body;
  }

  .filearea__placeholder__progress__indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    background-color: @color-defaults__action;
    display: block;
  }

  .filearea__placeholder__description {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 100px;
    width: calc(100% - 100px);
    font-size: @font-size__body;

    @media only screen and (max-width: @max-width--small) {
      font-size: @font-size__body--4;
    }
  }

  @keyframes file-input-status-rotating {
    0% {
      transform: rotate(405deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }

  @-webkit-keyframes file-input-status-rotating {
    0% {
      transform: rotate(405deg);
    }
    100% {
      transform: rotate(765deg);
    }
  }
</style>
