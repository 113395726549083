<script>
  import BaseNotification from './base-notification.vue'

  export default {
    components: {
      BaseNotification,
    },
    props: {
      /** Notification title. */
      title: {
        type: String,
        required: true,
      },
    },
    computed: {},
    mounted() {},
    methods: {},
  }
</script>

<template>
  <base-notification :accent="2" :title="title" v-on="$listeners">
    <template v-slot:icon>
      <i class="acuity-icon acuity-icon-checkmark" />
    </template>
    <template v-slot:description>
      <!-- @slot Slot container for notification description. -->
      <slot name="description" />
    </template>
  </base-notification>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
</style>
