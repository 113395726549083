<script>
  const Status = Object.freeze({ clear: 0, success: 1, systemError: 2, userError: 3 })

  export default {
    components: {},
    props: {
      /** Status number that changes what type of status is displayed. clear: 0, success: 1, systemError: 2, userError: 3 */
      status: {
        type: Number,
        default: Status[0],
      },
      /** String that gives a description of the status. */
      description: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        Status,
      }
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div>
    <div v-if="status === 3" :class="$style['global-status__error']">
      <div :class="$style['form-field__row']">
        <div :class="$style['global-status__icon']">
          <i :class="['acuity-icon', 'acuity-icon-info']"></i>
        </div>
        <div :class="$style['global-status__inline']">
          <div :class="$style['global-status__title']"> Please make sure your details are correct </div>
          <div :class="$style['global-status__description']"> {{ description }} </div>
        </div>
      </div>
    </div>
    <div v-if="status === 2" :class="$style['global-status__error']">
      <div :class="$style['form-field__row']">
        <div :class="$style['global-status__icon']">
          <i :class="['acuity-icon', 'acuity-icon-warning']"></i>
        </div>
        <div :class="$style['global-status__inline']">
          <div :class="$style['global-status__title']"> Something went wrong </div>
          <div :class="$style['global-status__description']"> {{ description }} </div>
        </div>
      </div>
    </div>
    <div v-if="status === 1" :class="$style['global-status__success']">
      <div :class="$style['form-field__row']">
        <div :class="$style['global-status__icon']">
          <i :class="['acuity-icon', 'acuity-icon-checkmark-square']"></i>
        </div>
        <div :class="$style['global-status__inline']">
          <div :class="$style['global-status__title']"> Thank you </div>
          <div :class="$style['global-status__description']"> {{ description }} </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .page-section__form__status {
    padding: @spacing--1 @spacing--2 0px @spacing--2;

    &--error {
      color: @color-defaults__text--error;
    }
  }

  .form-field__row {
    .grid-row();
    .flex-container();
  }

  .global-status {
    border-radius: 10px;
    padding-bottom: 10px;
  }

  .global-status__error {
    background-color: rgba(255, 209, 201, 0.25);
    border-radius: 10px;
    padding-bottom: 10px;
    .grid-row__column--6();
  }

  .global-status__success {
    background-color: rgba(119, 194, 80, 0.25);
    border-radius: 10px;
    opacity: 0.75;
    padding-bottom: 10px;
    .grid-row__column--6();
  }

  .global-status__icon {
    opacity: 1;
    font-size: 36px;
    margin-right: 7px;
    margin-left: 10px;
    margin-top: 14px;
    width: 100%;
    flex-grow: 0;
    flex-shrink: 1;
    width: inherit;
  }

  .global-status__inline {
    margin-top: 14px;
    width: 100%;
    flex-grow: 12;
    flex-shrink: 400;
    margin-right: 10px;
  }

  .global-status__title {
    text-align: left;
    letter-spacing: 0.16px;
    opacity: 1;
    font-size: 18px;
    font-weight: @font-weight--bold;
  }

  .global-status__description {
    text-align: left;
    letter-spacing: 0.13px;
    opacity: 1;
    font-size: 14px;
  }

  .flex-container {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    height: 100%;
  }
</style>
