<script>
  import BaseInputFile from './base-input-file.vue'

  export default {
    components: { BaseInputFile },
    props: {},
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <div>
    <base-input-file
      v-bind="{
        ...$attrs, //Expand out attributes incase we want to override anything
      }"
      v-on="$listeners"
    ></base-input-file>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';
</style>
