<script>
  export default {
    components: {},
    props: {
      /** Array[String] that will be used as the step titles. */
      steps: {
        required: true,
        type: Array,
        default: null,
      },
      /** Index of the current active step. */
      activeStepIndex: {
        required: false,
        type: Number,
        default: 0,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      computeStepClassObject: function(stepIndex) {
        return {
          [this.$style['progress-bar__step']]: true,
          [this.$style['progress-bar__step--complete']]: stepIndex < this.activeStepIndex,
          [this.$style['progress-bar__step--active']]: stepIndex === this.activeStepIndex,
          [this.$style['progress-bar__step--last']]: stepIndex === this.steps.length - 1,
        }
      },
    },
  }
</script>

<template>
  <ol :class="$style['progress-bar']">
    <li v-for="(step, index) in steps" :key="step" :class="computeStepClassObject(index)">
      <span>
        {{ index + 1 }}
      </span>
      <span>
        {{ step }}
      </span>
    </li>
  </ol>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  @progress-step-circle-circumference: 32px;
  @progress-bar-line-width: 2px;

  .progress-bar {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    position: relative;

    padding: 0px @spacing--2 0px @spacing--2;
    margin: 0px;
    box-sizing: border-box;
    width: 100%;
  }

  .progress-bar__step {
    list-style-type: none;
    position: relative;
    width: 0px;
    flex-grow: 1;
    box-sizing: border-box;
    min-height: @progress-step-circle-circumference;

    span:nth-of-type(2) {
      display: block;
      margin-top: calc(@progress-step-circle-circumference + @spacing--2);
      margin-left: calc(@spacing--2 / 2);
      margin-right: calc(@spacing--2 / 2);

      font-size: @font-size__body--1;
      font-weight: @font-weight--regular;
      color: @color-defaults__text--body;

      @media only screen and (max-width: @max-width--small) {
        display: none;
      }
    }

    span:first-of-type {
      transition: all 0s;
      .prevent-highlighting();
      font-weight: @font-weight--bold;
      font-size: calc(@progress-step-circle-circumference / 1.75);
      text-align: center;

      position: absolute;
      top: 0px;
      left: 50%;
      margin-left: calc(-@progress-step-circle-circumference / 2);

      width: @progress-step-circle-circumference;
      height: @progress-step-circle-circumference;
      line-height: calc(@progress-step-circle-circumference - 3px);
      border: @progress-bar-line-width solid @color-defaults__border;
      border-radius: 50%;
      box-sizing: border-box;

      color: @color-defaults__border--dark;
      background-color: @color-defaults__border;

      z-index: 1;
    }

    &:before {
      content: ' ';
      position: absolute;
      top: calc(@progress-step-circle-circumference / 2);
      left: 50%;

      height: @progress-bar-line-width;
      width: 100%;
      box-sizing: border-box;

      background-color: @color-defaults__border;
    }

    &:after {
      transition: width 0.5s ease-out 0.3s;
      content: ' ';
      position: absolute;
      top: calc(@progress-step-circle-circumference / 2);
      left: 50%;

      height: @progress-bar-line-width;
      width: 0%;
      box-sizing: border-box;

      background-color: @color-defaults__selected;
    }

    &--complete {
      span:first-of-type {
        border: @progress-bar-line-width solid transparent;
        background-color: transparent;
      }

      span:first-of-type:after {
        content: '\e618';
        .acuity-icon();

        font-size: calc(@progress-step-circle-circumference / 2);
        text-align: center;
        color: @color-defaults__action;

        position: absolute;
        top: -1px;
        left: 50%;
        margin-left: calc(-@progress-step-circle-circumference / 2);

        width: @progress-step-circle-circumference;
        height: @progress-step-circle-circumference;
        line-height: calc(@progress-step-circle-circumference - 2px);
        box-sizing: border-box;

        background-color: @color-defaults__section--2;
        border: @progress-bar-line-width solid @color-defaults__selected;
        border-radius: 50%;
      }

      &:after {
        transition: width 0.5s ease-in;
        width: 100%;
      }
    }

    &--active {
      span:first-of-type {
        transition: all 0.3s ease-out 0.5s;
        background-color: @color-defaults__selected;
        border: @progress-bar-line-width solid @color-defaults__selected;
        color: @color-defaults__text--light;
      }
    }

    &--last {
      &:before {
        width: 0%;
      }
      &:after {
        width: 0%;
      }
    }
  }
</style>
