<script>
  import BasePageSection from './base-page-section.vue'

  export default {
    components: {
      BasePageSection,
    },
    props: {
      /** Background color indicator. 0 (primary). 1 (secondary). */
      background: {
        required: false,
        type: Number,
        default: 0,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <base-page-section
    v-bind="{
      background: background,
    }"
  >
    <div :class="$style['page-section__form']">
      <form novalidate="novalidate" onsubmit="return false;">
        <div :class="$style['page-section__form__content']">
          <!-- @slot Slot container for form structure and item component content. -->
          <slot name="form" />
        </div>
      </form>
    </div>
    <div :class="$style['page-section__promotions']">
      <div :class="$style['page-section__promotions__content']">
        <!-- @slot Slot container for promotional component content. -->
        <slot name="promotions" />
      </div>
    </div>
  </base-page-section>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .page-section__form {
    .grid-row__column--8();
  }
  .page-section__form__content {
    padding-top: @spacing--1;
    padding-bottom: calc(1.5 ~'*' @spacing--4);

    @media only screen and (min-width: @min-width--medium) {
      padding-top: @spacing--4;
    }
  }

  .page-section__promotions {
    .grid-row__column--4();
  }

  .page-section__promotions__content {
    @media only screen and (min-width: @min-width--medium) {
      padding-top: @spacing--4;
      padding-bottom: calc(1.5 ~'*' @spacing--4);
    }
  }
</style>
