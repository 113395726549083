<script>
  import BaseTooltip from './base-tooltip.vue'

  export default {
    components: { BaseTooltip },
    props: {
      /** 11 digit phone number. This will be called or copied on click. */
      phoneNumber: {
        required: false,
        type: String,
        default: null,
        validator: function(value) {
          return value === null || (typeof value === 'string' && value.length === 11)
        },
      },
    },
    data() {
      return {
        hovering: false,
        copiedSuccess: false,
        tooltipText: 'Copy to clipboard',
      }
    },
    computed: {
      phoneInputValue() {
        return this.phoneNumber ? this.phoneNumber : ''
      },
      deviceCanCall() {
        return (
          navigator.userAgent.match(/Android/i) != null ||
          navigator.userAgent.match(/BlackBerry/i) != null ||
          navigator.userAgent.match(/iPhone/i) != null ||
          navigator.userAgent.match(/Opera Mini/i) != null ||
          navigator.userAgent.match(/IEMobile/i) != null
        )
      },
      showCopyTooltip() {
        return this.copiedSuccess || (!this.deviceCanCall && this.hovering && typeof this.phoneNumber === 'string' && this.phoneNumber.length > 0)
      },
    },
    methods: {
      callOrCopy(event) {
        if (!this.deviceCanCall) {
          event.stopPropagation()
          event.preventDefault()

          this.$refs.copyDataInputRef.select()
          this.$refs.copyDataInputRef.setSelectionRange(0, 99999)
          document.execCommand('copy')

          this.$refs.anchorRef.focus()

          this.copiedSuccess = true
          this.tooltipText = 'Copied'

          setTimeout(
            function() {
              this.copiedSuccess = false

              setTimeout(
                function() {
                  if (!this.copiedSuccess) {
                    this.tooltipText = 'Copy to clipboard'
                  }
                }.bind(this),
                300
              )
            }.bind(this),
            1800
          )
        }

        /**
         * Passthrough click event.
         * @type {Event}
         */
        this.$emit('click')
      },
      gainedAttention() {
        this.hovering = true
        this.tooltipText = 'Copy to clipboard'
      },
    },
  }
</script>

<template>
  <div :class="$style['phone-area__container']" @mouseenter="gainedAttention" @mouseleave="hovering = false">
    <div :class="$style['phone-area__hidden-input']">
      <input ref="copyDataInputRef" type="text" :value="phoneInputValue" tabindex="-1" />
    </div>

    <a
      ref="anchorRef"
      :href="'tel:+' + phoneNumber"
      :class="[$style['phone-area'], { [this.$style['phone-area--inactive']]: !phoneNumber }]"
      @click="callOrCopy"
      @keyup.space.prevent="callOrCopy"
      @keyup.enter.prevent="callOrCopy"
      @focus="gainedAttention"
      @blur="hovering = false"
      @keydown.space.prevent=""
      @keydown.enter.prevent=""
    >
      <!-- @slot Slot container for content that will have an active call action. -->
      <slot />
    </a>

    <div :class="$style['copy-tooltip']">
      <base-tooltip :visible="showCopyTooltip">
        {{ tooltipText }}
      </base-tooltip>
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .phone-area {
    cursor: pointer;
    .prevent-highlighting();
    color: inherit;
    text-decoration: none;

    &--inactive {
      cursor: pointer;
      pointer-events: none;
    }
  }

  .phone-area__hidden-input {
    height: 0px;
    width: 0px;
    position: relative;

    input {
      position: absolute;
      left: -10000px;
      top: 0px;
    }
  }

  .phone-area__container {
    display: inline-block;
    position: relative;
  }

  .copy-tooltip {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 100px;
  }
</style>
