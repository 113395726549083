<script>
  export default {
    components: {},
    props: {
      /** Toggled on to collapse and hide content of this container. */
      collapsed: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {},
  }
</script>

<template>
  <transition name="ac-collapse">
    <div v-show="!collapsed" key="collapsable-content">
      <!-- @slot Slot container for content that will show when expanded. -->
      <slot />
    </div>
  </transition>
</template>

<style>
  .ac-collapse-enter,
  .ac-collapse-leave-to {
    max-height: 0px;
    padding: 0px;
    margin: 0px;
    opacity: 0.1;
  }

  .ac-collapse-enter-to,
  .ac-collapse-leave {
    max-height: 1000px;
    overflow: hidden;
    opacity: 1;
  }

  .ac-collapse-enter-active {
    transition: max-height 0.5s ease-in 0s, padding 0.5s ease-out 0s, opacity 0.5s ease-out 0s;
  }

  .ac-collapse-leave-active {
    transition: max-height 0.5s ease-out 0s, padding 0.5s ease-in 0s, opacity 0.5s ease-in 0s;
  }
</style>
