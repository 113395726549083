<script>
  import ButtonAction from './button-action.vue'
  import ButtonActionBack from './button-action-back.vue'

  export default {
    components: {
      ButtonAction,
      ButtonActionBack,
    },
    props: {
      /** Submit button text. */
      submitButtonText: {
        required: true,
        type: String,
        default: 'Submit',
      },
      /** Back button text. */
      backButtonText: {
        required: false,
        type: String,
        default: '',
      },
      /** Busy flag. Set to yes when something the user is waiting on needs to finish before the submit action can be taken. */
      busy: {
        required: false,
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {}
    },
    computed: {},
    methods: {
      emitSubmitButtonClick: function() {
        /**
         * Event signifying the submit button was clicked.
         * @type {Event}
         */
        this.$emit('submitButtonClick')
      },
      emitBackButtonClick: function() {
        /**
         * Event signifying the back button was clicked.
         * @type {Event}
         */
        this.$emit('backButtonClick')
      },
    },
  }
</script>

<template>
  <div :class="$style['form-foot']">
    <div :class="$style['form-foot__content']">
      <hr :class="$style['form-foot__separator']" />
      <div :class="$style['form-foot__primary-actions']">
        <div v-if="submitButtonText" :class="[$style['form-foot__submit-actions'], { [$style['form-foot__submit-actions--single-action']]: !backButtonText }]">
          <button-action :busy="busy" @click="emitSubmitButtonClick">{{ submitButtonText }}</button-action>
        </div>
        <div v-if="backButtonText" :class="[$style['form-foot__back-actions'], { [$style['form-foot__back-actions--single-action']]: !submitButtonText }]">
          <button-action-back @click="emitBackButtonClick">{{ backButtonText }}</button-action-back>
        </div>
      </div>
      <div :class="$style['form-foot__additional-actions']">
        <!-- @slot Slot container for filling with button-action-secondary components. -->
        <slot />
      </div>
    </div>
  </div>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .form-foot {
    .grid-row();
  }

  .form-foot__content {
    .grid-row__column--10();
  }

  .form-foot__separator {
    border: none;
    background: @color-defaults__border;
    height: 2px;
    margin: calc(@spacing--2 * 2) @spacing--2 0px @spacing--2;
  }

  .form-foot__primary-actions {
    .grid-row();
  }

  .form-foot__back-actions {
    .grid-row__column--6();
    .grid-row__pull--6();
    &--single-action {
      .grid-row__pull--0();
    }

    padding-top: @spacing--4;
    padding-left: @spacing--2;
    padding-right: @spacing--2;

    button {
      width: 100%;
    }

    @media only screen and (min-width: @min-width--medium) {
      padding-right: calc(@spacing--2 ~'/' 2);
    }
  }

  .form-foot__submit-actions {
    .grid-row__column--6();
    .grid-row__push--6();
    &--single-action {
      .grid-row__push--0();
    }

    padding-top: @spacing--4;
    padding-right: @spacing--2;
    padding-left: @spacing--2;

    button {
      width: 100%;
    }

    @media only screen and (min-width: @min-width--medium) {
      padding-left: calc(@spacing--2 ~'/' 2);
      &--single-action {
        padding-left: @spacing--2;
      }
    }
  }

  .form-foot__additional-actions {
    .grid-row();
    text-align: center;
    padding-top: @spacing--2;

    button {
      margin: @spacing--2 auto 0px auto !important;
      display: block;
    }
  }
</style>
