<script>
  export default {
    components: {},
    props: {
      /** Color (0-5). */
      color: {
        required: false,
        type: Number,
        default: 0,
      },
    },
    data() {
      return {}
    },
    computed: {
      iconButtonClassObject: function() {
        return {
          [this.$style['icon-button']]: true,
          [this.$style['icon-button--text-color']]: this.color === 1,
          [this.$style['icon-button--heading-color']]: this.color === 2,
          [this.$style['icon-button--link-color']]: this.color === 3,
          [this.$style['icon-button--subheading-color']]: this.color === 4,
          [this.$style['icon-button--text-light-color']]: this.color === 5,
        }
      },
    },
    methods: {
      emitClick: function(event) {
        /**
         * Passthrough click event.
         * @type {Event}
         */
        this.$emit('click')
      },
    },
  }
</script>

<template>
  <button type="button" :class="iconButtonClassObject" @click="emitClick">
    <!-- @slot for containing the desired icon. -->
    <slot />
  </button>
</template>

<style lang="less" module>
  @import (reference) '../design/index.less';

  .icon-button {
    .prevent-highlighting();
    display: inline-block;
    text-decoration: none;
    padding: 0px;
    margin: 0px;
    border: none;
    background-color: transparent;
    outline: none;
    font-style: bold;
    font-weight: @font-weight--bold;
    font-size: @font-size__heading--3;
    line-height: 0px;

    color: @color-defaults__action--icon;
    &:hover {
      color: lighten(@color-defaults__action--icon, @color-alteration-step-size--2);
      cursor: pointer;
    }

    &:focus {
      color: lighten(@color-defaults__action--icon, @color-alteration-step-size--2);
      cursor: pointer;
    }

    &:active {
      color: darken(@color-defaults__action--icon, @color-alteration-step-size--2);
    }

    &--text-color {
      color: @color-defaults__text--body;
      &:hover {
        color: lighten(@color-defaults__text--body, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:focus {
        color: lighten(@color-defaults__text--body, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--body, @color-alteration-step-size--2);
      }
    }

    &--heading-color {
      color: @color-defaults__text--heading;
      &:hover {
        color: lighten(@color-defaults__text--heading, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:focus {
        color: lighten(@color-defaults__text--heading, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--heading, @color-alteration-step-size--2);
      }
    }

    &--subheading-color {
      color: @color-defaults__text--subheading;
      &:hover {
        color: lighten(@color-defaults__text--subheading, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:focus {
        color: lighten(@color-defaults__text--subheading, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--subheading, @color-alteration-step-size--2);
      }
    }

    &--link-color {
      color: @color-defaults__text--link;
      &:hover {
        color: lighten(@color-defaults__text--link, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:focus {
        color: lighten(@color-defaults__text--link, @color-alteration-step-size--2);
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--link, @color-alteration-step-size--2);
      }
    }

    &--text-light-color {
      color: @color-defaults__text--light;
      &:hover {
        color: darken(@color-defaults__text--light, @color-alteration-step-size--1);
        cursor: pointer;
      }

      &:focus {
        color: darken(@color-defaults__text--light, @color-alteration-step-size--1);
        cursor: pointer;
      }

      &:active {
        color: darken(@color-defaults__text--light, @color-alteration-step-size--2);
      }
    }
  }
</style>
